import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native"
import back from '../assets/icons/back.png';
import arrowBack2 from '../assets/icons/arrowBack2.png';
import arrowRight from '../assets/icons/arrowRight.png';
import arrowRight2 from '../assets/icons/arrowRight2.png';
import { useNavigation } from "@react-navigation/native";
import { FONTFAMILY, HeaderHeight, currentPlatform, isWeb } from "../constants/Colors";
import { useAuth } from "../contexts/AuthContext";

const Header = ({ title, titleStyle }) => {

    const { currentTheme, currentThemeColor, lang } = useAuth();
    const navigation = useNavigation();

    const image = () => {
        if (lang === "ar") {
            return currentTheme === 'dark' ? arrowRight2 : arrowRight;
        } else {
            return currentTheme === 'dark' ? arrowBack2 : back;
        }
    }

    const flexDirectionStyle = lang === 'ar' ? 'row-reverse' : 'row';
    const positionRight = lang === 'ar' ? {right : 0} : {left: 0};

    const webDesign = currentPlatform === 'web' ? {top: 0, position: 'absolute'} : {};

    if(isWeb) {
        return <></>
    }
    
    return (
        <View style={{ ...styles.container, flexDirection: flexDirectionStyle, ...positionRight, ...webDesign }}>
            <TouchableOpacity onPress={() => { navigation.goBack() }}>
                <Image source={image()} style={styles.backImage} />
            </TouchableOpacity>
            < Text style={{ ...styles.headerText, ...titleStyle, color: currentThemeColor.headerText }}>{title}</Text>
        </View >
    )
}

const styles = StyleSheet.create({
    container: {
        height: HeaderHeight,
        alignItems: currentPlatform == 'ios' ? 'flex-end' : 'center',
        paddingHorizontal: 20,
        flexDirection: 'row',
        paddingTop: 0
    },
    backImage: {
        height: 40,
        width: 53,
    },
    headerText: {
        fontSize: 30,
        marginHorizontal: 7,
        fontFamily: FONTFAMILY
    }
});
export default Header