import { View, StyleSheet, ImageBackground, Image, Text, SafeAreaView, ScrollView } from 'react-native';
import { currentPlatform, screenHeight, screenWidth } from '../../constants/Colors';
import back2 from '../../assets/background/back2.png';
import back2Dark from '../../assets/background/back2Dark.png';
import panda1 from '../../assets/panda/panda1.png';
import panda2 from '../../assets/panda/panda2.png';
import { mainStyle } from '../../mainstyle';
import CustomButton from '../../components/customButton';
import { useAuth } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { post } from '../../WebService/RequestBuilder';
import globalConstants from '../../config/globalConstants';
import { Fragment } from 'react';
import { WebBackgroundTopColor } from '../../components/WebBackground';

const CheckUserExperienceScreen = ({ navigation }) => {
    const { t } = useTranslation();
    const { currentTheme, currentThemeColor, lang, subUser } = useAuth();

    const handleOnPress = async (value) => {
        try {
            const body = {
                user_info_id: subUser?.id,
                with_experience: value
            };

            await post(globalConstants.user.updateWithExperience, body)

            navigation.navigate(value ? 'SetCourseScreen' : 'AlgorithmsScreen')
        } catch (error) {
            console.log("error in CheckUserExperienceScreen handleOnPress: ", error);
        }
    }

    const pageContent = () => {
        return (
            <Fragment>
                <View style={mainStyle.qmainDiv}>
                    <View style={mainStyle.qDiv1}>
                        <Text style={{ ...mainStyle.qDiv1Style, color: currentThemeColor.primaryTextColor }}>{t('Do_you_have_previous_knowledge_in_programming')}</Text>
                    </View>

                    <View style={mainStyle.qDiv2}>
                        <Text style={{ ...mainStyle.qDiv2Style, color: currentThemeColor.primaryTextColor }}>{t('Are_you_familiar_with_programming')}</Text>
                    </View>

                    <View style={mainStyle.qDiv3}>
                        <View style={{ ...mainStyle.qDiv3Sub, flexDirection: lang == 'ar' ? 'row-reverse' : 'row' }} >
                            <CustomButton btnStyle={{ width: '48%', }} text={t('yes')} onPress={async () => { await handleOnPress(true) }} />
                            <CustomButton btnStyle={{ width: '48%', }} text={t('no')} onPress={async () => { await handleOnPress(false) }} />
                        </View>
                        {/* <CustomButton btnStyle={{ marginTop: 25, }} text='Go To Home Screen' onPress={() => { navigation.navigate('MainStack') }} /> */}
                    </View>

                </View>
            </Fragment>
        )
    }

    return (
        currentPlatform === 'web' ? (
            <WebBackgroundTopColor>
                {pageContent()}

                <View style={{ flex: 1 }}>
                    <Image source={panda1} style={mainStyle.panda1} />
                    <Image source={panda2} style={mainStyle.panda2} />
                </View>
            </WebBackgroundTopColor>
        ) : <View style={mainStyle.mainContainer}>
            <ImageBackground source={currentTheme == 'dark' ? back2Dark : back2} style={{ width: screenWidth, height: screenHeight, }} resizeMode='cover' >
                <ScrollView>
                    {pageContent()}
                </ScrollView>

                <View style={{ flex: 1 }}>
                    <Image source={panda1} style={mainStyle.panda1} />
                    <Image source={panda2} style={mainStyle.panda2} />
                </View>
            </ImageBackground >
        </View >
    );
}

const styles = StyleSheet.create({

})

export default CheckUserExperienceScreen