import React, { Fragment, useCallback, useState } from 'react';
import { View, StyleSheet, ImageBackground, Image, SafeAreaView, ScrollView } from 'react-native';
import { Colors, HeaderHeight, currentPlatform, screenHeight, screenWidth } from '../../constants/Colors';
import back2 from '../../assets/background/back2.png';
import back2Dark from '../../assets/background/back2Dark.png';
import panda1 from '../../assets/panda/panda1.png';
import Input from '../../components/input';
import CustomButton from '../../components/customButton';
import { mainStyle } from '../../mainstyle';
import Header from '../../components/header';
import { useAuth } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import globalConstants from '../../config/globalConstants';
import { post } from '../../WebService/RequestBuilder';
import CustomAlert from '../../components/customAlert';
import panda3 from '../../assets/panda/panda3.png';
import { WebBackgroundTopColor } from '../../components/WebBackground';

const ForgotPasswordScreen = ({ navigation }) => {

    const { t } = useTranslation();
    const { currentTheme, currentThemeColor } = useAuth();
    const [emailAddress, setEmailAddress] = useState('');

    const [alertData, setAlertData] = useState(null);
    const showAlert = useCallback((message, type) => { setAlertData({ message, type }); }, []);
    const hideAlert = useCallback(() => { setAlertData(null); }, []);

    const Submit = () => {
        if (emailAddress) {
            const body = { email: emailAddress };
            post(globalConstants.auth.sendOtpForgotPassword, body).then((res) => {
                navigation.navigate('VerifyOtpScreen', {email: emailAddress})
            }).catch((error) => {
                showAlert(t("Something_Went_Wrong"), 'error');
                console.log('error', error);
            });
        } else {
            showAlert(t("email_must_be_entered"), 'error');
        }
    }

    const pageContent = () => {
        return (
            <Fragment>
                <Header title={t('Forgot_Password')} />
                <Image source={panda3} style={mainStyle.pandaHeader} />
                <View style={styles.inputsContainer}>
                    <Input lable={t('Email_Address')} placeholder={t('Email_Address')} subText={t('Enter_your_Email_to_reset')} onChange={(text) => { setEmailAddress(text); }} keyboardType={'email-address'} />
                    <CustomButton btnStyle={{ marginTop: 15, }} text={t('Submit')} onPress={Submit} />
                </View>
                <CustomAlert title={alertData?.message} type={alertData?.type} isVisible={!!alertData} onHide={hideAlert} />
            </Fragment>
        )
    }

    return (
        currentPlatform === 'web' ? (
            <WebBackgroundTopColor >
                {pageContent()}
                <View style={{ flex: 1 }}>
                <Image source={panda1} style={mainStyle.panda1} />
                </View>
            </WebBackgroundTopColor>
        ) : (<View style={mainStyle.mainContainer}>
            <ImageBackground source={currentTheme == 'dark' ? back2Dark : back2} style={{ width: screenWidth, height: screenHeight, }} resizeMode='cover' >
                <ScrollView>
                {pageContent()}
                </ScrollView>
                <View style={{ flex: 1 }}>
                <Image source={panda1} style={mainStyle.panda1} />
                </View>
            </ImageBackground >
        </View >)
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.mainBackground
    },
    inputsContainer: {
        width: currentPlatform === 'web' ? screenWidth / 2 : '90%',
        alignSelf: 'center',
        paddingVertical: 20,
        paddingTop: currentPlatform === 'web' ? 60 : 100
    },
})

export default ForgotPasswordScreen