import { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { storeJsonData, getStoreJsonData, removeStoreData, } from '../helper/GeneralStorage';
import Constants from '../config/globalConstants';
import i18n from '../i18n/i18n';
import { currentPlatform, darkTheme, lightTheme } from '../constants/Colors';
import { Appearance } from 'react-native';
import globalConstants from '../config/globalConstants';
import { baseURL, clearAuthToken, customGet, del, get, post, setAuthToken } from '../WebService/RequestBuilder';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [token, setToken] = useState(null);

    const [user, setUser] = useState(null);
    const [points, setPoints] = useState(0);
    const [subUser, setSubUser] = useState(null);
    const [subUserImage, setSubUserImage] = useState(null);
    const [userType, setUserType] = useState(null);
    const [isRegister, setIsRegister] = useState(false);
    // const [onlineStatus, setOnlineStatus] = useState(false);
    const lang = i18n.language;
    const [currentTheme, setCurrentTheme] = useState(darkTheme);
    const [currentThemeColor, setCurrentThemeColor] = useState(darkTheme);
    const [havingExperience, setHavingExperience] = useState(null);
    const [currentSubUserCourse, setCurrentSubUserCourse] = useState();
    const [currentTutorials, setCurrentTutorials] = useState(null);
    const [currentSubUserCourseImage, setCurrentSubUserCourseImage] = useState(null);
    const [currentCourseName, setCurrentCourseName] = useState(null);
    const [accountType, setAccountType] = useState('individual');
    const [adminConfig, setAdminConfig] = useState(null);
    const [isLogout, setIsLogout] = useState(false);

    const checkAuthStatus = async () => {
        setIsLoading(true);
        try {
            const tokenData = await getStoreJsonData(Constants.storageTokenKeyName);
            if (tokenData) {
                setToken(tokenData);
                setAuthToken(tokenData);
                setIsLoggedIn(true);
            }

            const userData = await getStoreJsonData(Constants.userData);
            if (userData) { setUser(userData); }

            const typeAccount = await getStoreJsonData(Constants.accountType);
            if (typeAccount) { setAccountType(typeAccount); }

            const userTypeData = await getStoreJsonData(Constants.user_type);
            if (userTypeData) { setUserType(userTypeData); }

            const SubUserData = await getStoreJsonData(Constants.subUserData);
            if (SubUserData && tokenData) { 
                const response = await customGet(Constants.user.getUserInfo.replace('{user_info_id}', SubUserData.id));
                setSubUser(response.user_info); 
                storeJsonData(Constants.subUserData, response.user_info);
                setHavingExperience(response.user_info.with_previous_experience)   
                setPoints(SubUserData.points)             
            }

            const imageData = await getStoreJsonData(Constants.subUserImage);
            if(imageData){
                setSubUserImage(imageData);
            }

            const courseNameData = await getStoreJsonData(Constants.currentCourseName);

            if(courseNameData){
                setCurrentCourseName(courseNameData);
            }

            const currentSubUserCourseData = await getStoreJsonData(Constants.currentSubUserCourse);

            if(currentSubUserCourseData){
                setCurrentSubUserCourse(currentSubUserCourseData);
            }

            const currentCourseImage = await getStoreJsonData(Constants.currentSubUserCourseImage);

            if(currentCourseImage){
                setCurrentSubUserCourseImage(currentCourseImage);
            }
        } catch (error) {
            console.log('Error while checking auth status:', error);
            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    const getAdminConfig = async () => {
        try {
            const {data} = await customGet(Constants.general.admin);

           setAdminConfig(data);
        } catch (error) {
            console.log("error getAdminConfig", error?.message);
        }
    }

    useEffect(() => {
        if(!isLoading && isLoggedIn) {
            getAdminConfig()
        }
    }, [isLoading, isLoggedIn])

    const saveDailyPoints = async () => {
        try {
            const {data} = await post(Constants.user.dailyPoints, {
                user_info_id: subUser.id
            });

            setPoints(data.total_points)   
        } catch (error) {
            console.log('error saveDailyPoints', error);
        }
    }

    useEffect(() => {
        if(subUser && token) {
            saveDailyPoints()
        }
    }, [subUser, token])

    // useEffect(()=>{
    //     logout()
    // },[])

    const checkTheme = async () => {
        const theme = Appearance.getColorScheme();
        const tokenData = await getStoreJsonData(Constants.appTheme);
        let newTheme = tokenData ? tokenData : currentPlatform === 'web' ? 'light' : theme;
        setCurrentTheme(newTheme)
        if(currentPlatform !== 'web') Appearance.setColorScheme(newTheme);
        setCurrentThemeColor(newTheme === 'dark' ? darkTheme : lightTheme)
    }

    const changeMode = async (theme) => {
        if(currentPlatform !== 'web') {
            Appearance.setColorScheme(theme);
        }
        await storeJsonData(globalConstants.appTheme, theme);
        setTimeout(() => {
            checkTheme()
        }, 200);

        try {
            const res = await post(Constants.user.toggleDarkMde, {
                user_info_id: subUser.id
            });
            return true
        } catch (error) {
            console.log('error ==> ', error);
        }
    }

    // const changeOnlineStatus = async (res) => {
    //     try {
            
    //         const data = await post(Constants.user.toggleOnlineStatus, {
    //             user_info_id: subUser.id
    //         });
            
    //         const isOnline = data?.is_online;
            
    //         await storeJsonData(globalConstants.onlineStatus, isOnline);
    //         setOnlineStatus(isOnline);

    //         return isOnline;
    //     } catch (error) {
    //         console.log('error ==> ', error);
    //     }
    // }

    const checkLang = async () => {
        const tokenData = await getStoreJsonData(globalConstants.default_lang);
        await i18n.changeLanguage(tokenData);
    }

    useEffect(() => {
        checkAuthStatus();
        checkTheme()
    }, []);

    const login = async (body, socialLogin = false) => {
        try {
            const {data} = await post(socialLogin ? Constants.auth.socialLogin : Constants.auth.login, body, null)
    
            if (data.user.email_verified_at) {
                const token = data.access_token;
                const userData = data.user;
    
                const userInfo = userData.user_info;
                const typeAccount = userData?.user_subscription?.family_account ? 'family' : 'individual';
    
                setAccountType(typeAccount);
                storeJsonData(Constants.accountType, typeAccount);
    
                if(typeAccount === 'individual') {
                    storeJsonData(Constants.subUserData, userInfo[0]);
                    setSubUser(userInfo[0]);
                    setHavingExperience(userInfo[0].with_previous_experience);
                    const attachment = userInfo[0]?.attachment;
                    if(attachment) {
                        setSubUserImage(attachment);
                        storeJsonData(Constants.subUserImage, attachment);
                    }
                    const language = userInfo[0]?.language;
                    await i18n.changeLanguage(language);
                    await storeJsonData(Constants.default_lang, language);
                }
    
                storeJsonData(Constants.storageTokenKeyName, token);
                storeJsonData(Constants.userData, userData);
    
                setAuthToken(token);
                setToken(token);
                setUser(userData);
                setIsLoggedIn(true);
    
                return { ...data, isVerifyEmail: true, accountType: typeAccount };
            } else {
                return { ...data, isVerifyEmail: false, typeAccount: 'individual' };
            }
        } catch (error) {
            console.log("error login", error);
            throw error;
        }
    };

    const register = async (username, phoneNumber, emailAddress, password, language='en', birthdayDate) => {
        try {
            let body = { name: username, phone_number: phoneNumber, email: emailAddress, password: password, language, birthday_date: birthdayDate };
            const res = await post(Constants.auth.register, body);
            await i18n.changeLanguage(language);
            await storeJsonData(Constants.default_lang, language);
            if (res.status) {
                setIsRegister(true);
                setIsLoggedIn(true);
                return res;
            } else {
                throw new Error('Registration failed');
            }
        } catch (error) {
            console.log('error', error);
            throw error;
        }
    };

    const resendOTP = async (email) => {
        try {
            const body = { email: email };
            const res = await post(Constants.auth.resendOTP, body);
            return res;
        } catch (error) {
            throw error;
        }
    };

    const EmailVerifyOTP = async (email, otpCode) => {
        const body = { "email": email, "otp": otpCode, "is_update_email": false, "is_forgot_password": false };
        try {
            const response = await axios.post(`${baseURL}${Constants.auth.verifyOTP}`, body, {
                headers: { 'Content-Type': 'application/json' }
            });
            const res = response.data;
            if (res.status) {
                const token = res.token;
                const userType = 'user';
                const userData = res.user;
                const userInfo = userData.user_info;

                if(userInfo.length == 1) {
                    storeJsonData(Constants.subUserData, userInfo[0]);
                    setSubUser(userInfo[0]);
                    setHavingExperience(userInfo[0].with_previous_experience);
                    const attachment = userInfo[0]?.attachment;
                    if(attachment){ 
                        setSubUserImage(attachment);
                        storeJsonData(Constants.subUserImage, attachment);
                    }
                }

                setIsRegister(true);
                storeJsonData(Constants.storageTokenKeyName, token);
                storeJsonData(Constants.userData, userData);
                storeJsonData(Constants.user_type, userType);

                setAuthToken(token);
                setToken(token);
                setUser(userData);
                setUserType(userType);
                setIsLoggedIn(true);

                return res;
            } else {
                throw new Error('Registration failed');
            }
        } catch (error) {
            throw error;
        }
    }

    const updateProfile = async (phoneNumber, username, emailAddress) => {
        try {
            let body = { phone_number: phoneNumber, name: username, email: emailAddress };
            const res = await post(Constants.user.editProfile, body);
            const userData = res.user;
            storeJsonData(Constants.userData, userData);
            setUser(userData);
            return true
        } catch (error) {
            console.log('error', error);
            throw error;
        }
    };

    const resetValues = () => {
        removeStoreData(Constants.storageTokenKeyName);
        removeStoreData(Constants.userData);
        removeStoreData(Constants.accountType);
        removeStoreData(Constants.user_type);
        removeStoreData(Constants.subUserData);
        removeStoreData(Constants.subUserImage);

        removeStoreData(Constants.currentCourseName);
        removeStoreData(Constants.currentSubUserCourse);
        removeStoreData(Constants.currentSubUserCourseImage);

        clearAuthToken();
        setUserType(null);
        setToken(null);
        setSubUser(null);
        setUser(null);
        setIsLoggedIn(false);
        setSubUserImage(null);
        setIsRegister(false);

        setCurrentSubUserCourse(null);
        setCurrentSubUserCourseImage(null);
        setCurrentCourseName(null);
    }

    const logout = async () => {
        try {
            setIsLoading(true)
            await del(Constants.auth.logout);
            resetValues()
        } catch (error) {
            resetValues()
            console.log("error logout: ", error);
        } finally {
            setIsLoading(false);
            setIsLogout(false);
        }
    };

    const logoutSubAccount = async () => {
        try {
            setIsLoading(true)
        } catch (error) {
            console.log("error logout sub account: ", error);
        } finally {
            removeStoreData(Constants.subUserData);
            setSubUser(null);
            setIsLoading(false)
        }
    };

    const AddNewSubUser = async (username, password, language=lang, birthdayDate) => {
        try {
            const body = { name: username, sub_password: password, language, birthday_date: birthdayDate };
            const res = await post(Constants.auth.newProfile, body);
            const userData = res.user;
            storeJsonData(Constants.userData, userData);
            setUser(userData);

            if(language !== lang) {
                await i18n.changeLanguage(language);
                await storeJsonData(Constants.default_lang, language);
            }
            
            return true
        } catch (error) {
            throw error;
        }
    }

    const loginSubUser = async (sub_password, user_info_id) => {
        try {
            const body = { sub_password: sub_password, user_info_id: user_info_id };
            const res = await post(Constants.auth.loginSubProfile, body);
            const userData = res.data;
            storeJsonData(Constants.subUserData, userData);
            setSubUser(userData);
            setHavingExperience(userData?.with_previous_experience);
            const attachment = userData?.attachment;

            if(attachment){ 
                setSubUserImage(attachment);
                storeJsonData(Constants.subUserImage, attachment);
            } else {
                setSubUserImage(null);
                removeStoreData(Constants.subUserImage);
            }

            const language = userData?.language;
            await i18n.changeLanguage(language);
            await storeJsonData(Constants.default_lang, language);
            return true
        } catch (error) {
            throw error;
        }
    }

    const updateSubProfile = async (username, user_info_id) => {
        try {
            const body = { name: username, user_info_id: user_info_id };
            const res = await post(Constants.user.editSubProfile, body);
            const userData = res.data;

            const updatedUserInfo = user.user_info.map(userInfo =>
                userInfo.id === userData.id ? userData : userInfo
            );

            user.user_info = updatedUserInfo;

            storeJsonData(Constants.userData, user);
            storeJsonData(Constants.subUserData, userData);

            setUser(user);
            setSubUser(userData);

            return true;
        } catch (error) {
            console.log('error', error);
            throw error;
        }
    };

    const userAddsubscription = async (id, selectedDate, card_number, holder_name, cvc, promoCode) => {
        const data = { subscription_id: id, expiry_date: selectedDate, card_number: card_number, holder_name: holder_name, cvc: cvc };
        if (promoCode) {
            data.promo_code = promoCode
        }
        post(Constants.user.addSubscription, data).then((res) => {
            setUser(prevUser => ({ ...prevUser, user_subscription: res.data }));
            return res.data
        }).catch(err => {
            console.log('err ==>', err)
            throw err
        });
    }

    const authContextValue = {
        isLoading,
        isLoggedIn,
        currentTheme,
        currentThemeColor,
        isRegister,
        token,
        user,
        subUser,
        userType,
        lang,
        subUserImage,
        // onlineStatus,
        havingExperience,
        currentSubUserCourse,
        currentTutorials,
        currentSubUserCourseImage,
        currentCourseName,
        accountType,
        adminConfig,
        points,
        isLogout,
        login,
        register,
        changeMode,
        checkLang,
        updateProfile,
        logout,
        AddNewSubUser,
        resendOTP,
        EmailVerifyOTP,
        userAddsubscription,
        loginSubUser,
        updateSubProfile,
        setSubUserImage,
        // changeOnlineStatus,
        setHavingExperience,
        setCurrentSubUserCourse,
        setCurrentTutorials,
        setCurrentSubUserCourseImage,
        setCurrentCourseName,
        logoutSubAccount,
        saveDailyPoints,
        setIsLogout
    };

    return (
        <AuthContext.Provider value={authContextValue}>
            {children}
        </AuthContext.Provider>
    );
};
