import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';
import * as ScreenOrientation from 'expo-screen-orientation';
import { StatusBar } from 'react-native';
import { Colors, isWeb } from '../constants/Colors';
import GardenLevelScreen from '../screens/App/GameStack/GardenLevelScreen';
import KitchenGameScreen from '../screens/App/GameStack/KitchenGameScreen';
import ChoseYourCharacter from '../screens/App/GameStack/ChoseYourCharacter';
import FlowerGame from '../screens/App/GameStack/FlowerGame';
import { useFocusEffect } from '@react-navigation/native';

const GameStack = () => {
  const Stack = createNativeStackNavigator();

  // useFocusEffect(
  //   React.useCallback(() => {
  //     async function changeScreenOrientation() {
  //       await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE);
  //     }

  //     if (!isWeb) {
  //       changeScreenOrientation();
  //     }

  //     return () => {
  //       async function resetScreenOrientation() {
  //         await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.DEFAULT);
  //       }

  //       if (!isWeb) {
  //         resetScreenOrientation();
  //       }
  //     };
  //   }, [isWeb])
  // );

  return (
    <>
      <StatusBar barStyle="default" backgroundColor={Colors.primary2} />
      <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName='FlowerGame'>
        <Stack.Screen name="ChoseYourCharacter" component={ChoseYourCharacter} />
        <Stack.Screen name="GardenLevelScreen" component={GardenLevelScreen} />
        <Stack.Screen name="KitchenGameScreen" component={KitchenGameScreen} />
        <Stack.Screen name="FlowerGame" component={FlowerGame} />
      </Stack.Navigator>
    </>
  );
};

export default GameStack;
