import { StyleSheet } from 'react-native';
import { Colors, FONTFAMILY, currentPlatform, screenWidth } from './constants/Colors';

export const preventOutlineOnWeb = currentPlatform === 'web' ? {outlineStyle: 'none'} : {}

export const mainStyle = StyleSheet.create({
    mainContainer: {
        flex: 1,
        backgroundColor: Colors.mainBackground
    },
    panda1: {
        position: 'absolute',
        zIndex: 10,
        bottom: 0,
        right: '5%',
        width: 100,
        height: 100,
        resizeMode: 'contain'
    },
    panda2: {
        position: 'absolute',
        zIndex: 2,
        bottom: 0,
        left: 0,
        width: 90,
        height: 260,
        resizeMode: 'contain'
    },
    panda9: {
        position: 'absolute',
        width: 100,
        height: 50,
        top: '2%',
        right: currentPlatform === 'web' ? 0 : -9
    },
    panda92: {
        position: 'absolute',
        width: 100,
        height: 50,
        left: '-2%',
        right: currentPlatform === 'web' ? 0 : -9
    },
    branch: {
        position: 'absolute',
        bottom: '7%',
        right: 0,
        width: 70,
        height: 130,
        resizeMode: 'contain',
    },
    tree2: {
        position: 'absolute',
        bottom: 0,
        right: 10,
        width: 70,
        height: 70,
        resizeMode: 'contain',
    },
    inputMainDiv: {
        width: '100%',
        paddingVertical: 3,
        marginBottom: 7,
        zIndex: 10,
    },
    inputDiv: {
        width: '100%',
        height: 50,
        backgroundColor: '#D9D9D9',
        opacity: 0.9,
        borderTopLeftRadius: 22,
        borderTopRightRadius: 25,
        borderBottomLeftRadius: 87,
        borderBottomRightRadius: 77,
        paddingHorizontal: 40,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    inputLabel: {
        fontSize: 22,
        color: '#000',
        fontFamily: FONTFAMILY
    },
    inputSubLabel: {
        marginVertical: 5,
        fontFamily: FONTFAMILY
    },
    input: {
        color: '#000',
        width: '90%',
        height: '100%',
        fontFamily: FONTFAMILY,
        ...preventOutlineOnWeb,
    },
    iconButton: {
        width: '10%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    icon: {
        width: 20,
        height: 20,
        resizeMode: 'contain',
    },
    CustomButton: {
        width: currentPlatform === 'web' ? '75%' : '100%',
        minHeight: 50,
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        borderTopLeftRadius: 22,
        borderTopRightRadius: 25,
        borderBottomLeftRadius: 87,
        borderBottomRightRadius: 77,
        zIndex: 10,
        textAlign: 'center',
    },
    CustomButtonSubmit: {
        width: currentPlatform === 'web' ? '75%' : '100%',
        minHeight: 50,
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 20,
        zIndex: 10,
        textAlign: 'center',
    },
    CustomButtonText: {
        color: '#fff',
        fontSize: 30,
        fontFamily: FONTFAMILY
    },
    qmainDiv: {
        width: '100%',
        height: '100%',
    },
    introductionMainDiv: {
        width: currentPlatform === 'web' ? screenWidth / 2 : '100%',
        height: '100%',
        alignContent: 'center',
        alignSelf: 'center'
    },
    qDiv1: {
        width: '100%',
        // height: '30%',
        marginVertical: 80,
        justifyContent: currentPlatform == 'ios' ? 'flex-end' : 'center',
        alignItems: 'center'
    },
    qDiv1Style: {
        fontSize: 25,
        width: '80%',
        textAlign: 'center',
        color: '#000',
        fontFamily: FONTFAMILY
    },
    qDiv2: {
        width: currentPlatform === 'web' ? screenWidth / 2 : '100%',
        // height: '25%',
        marginTop: 10,
        marginBottom: 50,
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center'
    },
    qDiv2Style: {
        fontSize: 20,
        width: currentPlatform === 'web' ? '70%' : '80%',
        textAlign: 'center',
        color: '#000',
        fontFamily: FONTFAMILY
    },
    qDiv3: {
        width: currentPlatform === 'web' ? screenWidth / 2 : '85%',
        alignSelf: 'center',
        height: '45%',
    },
    qDiv3Sub: {
        height: '10%',
        width: currentPlatform === 'web' ? screenWidth / 2 : '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignContent: 'center',
        alignSelf: 'center',
    },
    choices: {
        width: '98%',
        alignSelf: 'center',
    },
    questionsDiv1: {
        width: '100%',
        justifyContent: currentPlatform == 'ios' ? 'space-evenly' : 'space-around',
        alignItems: 'center',
        marginVertical: 25,
    },
    questionsDiv2: {
        width: '100%',
        marginVertical: 50,
        justifyContent: 'space-around',
        alignItems: 'center'
    },
    questionsDiv3: {
        width: '85%',
        alignSelf: 'center',
        marginVertical: 20,
    },
    pandaHeader: {
        width: 200, 
        height: 200,
        alignSelf: 'center',
        resizeMode: 'contain',
        marginTop: currentPlatform === 'web' ? -100 : 0
    }
    // logo: {
    //     width: screenWidth / 2.5,
    //     height: screenWidth / 2.5,
    //     alignSelf: 'center'
    // }
});