import React, { Fragment, useState } from 'react';
import { View, StyleSheet, ImageBackground, Image, Text, ScrollView } from 'react-native';
import { Colors, FONTFAMILY, HeaderHeight, currentPlatform, isIos, isWeb, screenHeight, screenWidth } from '../../constants/Colors';
import back2 from '../../assets/background/back2.png';
import back2Dark from '../../assets/background/back2Dark.png';
import back3 from '../../assets/background/back3.png';
import back3Dark from '../../assets/background/back3Dark.png';
import panda1 from '../../assets/panda/panda1.png';
import panda3 from '../../assets/panda/panda3.png';
import panda4 from '../../assets/panda/panda4.png';
import branch from '../../assets/panda/branch.png';
import { mainStyle } from '../../mainstyle';
import CustomButton from '../../components/customButton';
import { useAuth } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { WebBackgroundBottomColor, WebBackgroundTopColor } from '../../components/WebBackground';

const OnboardingScreen = ({ navigation }) => {
    const { t } = useTranslation();
    const [screen, setScreen] = useState(1);
    const { currentTheme, currentThemeColor } = useAuth();

    const content1 = () => {
        const descriptionWebDesign = currentPlatform === 'web' ? {
            width: screenWidth / 2,
        } : {};

        return (
                <Fragment>
                <View style={[styles.inputsContainer, currentPlatform !== 'web' && {flex: 1}]}>
                    <View style={{ width: '100%', height: '40%', alignItems: 'center', justifyContent: 'center', alignSelf: 'center' }}>
                        <Image source={panda3} style={styles.panda3} />
                    </View> 
                    <View style={{ width: '100%', height: '60%', paddingTop: 50 }} >
                        <Text style={{ ...styles.headerText, color: currentThemeColor.primaryTextColor }}>{t("Learn_how_to_code")}</Text>
                        <Text style={{ ...styles.discText, color: currentThemeColor.primaryTextColor, ...descriptionWebDesign}} >{t("Discover_the_art")}</Text>
                        <CustomButton btnStyle={{ marginTop: isWeb ? '20%' : '8%', width: isWeb ? '60%' : '40%', }} text={t('next')} onPress={() => { setScreen(2) }} />
                    </View>
                </View>
                <View style={{ flex: 1 }}>
                    <Image source={panda1} style={styles.panda1} />
                </View>
                <Image source={branch} style={styles.branch} />
            </Fragment>
        );
    }

    const Screen1 = () => {
        return currentPlatform === 'web' ? <WebBackgroundTopColor elementOnImage={<Image source={panda3} style={{
            alignSelf: 'center',
            height: 150,
            width: 150,
            resizeMode: 'contain',
        }} />
        }> {content1()}</WebBackgroundTopColor>  :(
            <ImageBackground source={currentTheme == 'dark' ? back2Dark : back2} style={{ width: screenWidth, height: screenHeight, }} resizeMode='cover' >
                {content1()}
            </ImageBackground >
        );
    }

    const content2 = () => {
        return (
            <Fragment>
                <View style={styles.inputsContainer}>
                    <View style={{ width: '100%', height: currentPlatform !== 'web' ? '60%' : '100%', paddingTop: 50 }} >
                        <Text style={{ ...styles.headerText, color: currentThemeColor.primaryTextColor }}>{t("Challenge_yourself")}</Text>
                        <Text style={{ ...styles.discText, color: currentThemeColor.primaryTextColor }}  >{t("Embark_on_an_exhilarating")}</Text>
                        <CustomButton btnStyle={{ marginTop: '20%', width: '60%', }} text={t('next')} onPress={() => { navigation.navigate('LoginScreen') }} />
                    </View>
                    <View style={{ width: '100%', height: '40%', alignItems: 'center', justifyContent: 'center', alignSelf: 'center' }}>
                        <Image source={panda4} style={styles.panda4} />
                    </View>
                </View>
            </Fragment>
        )
    }

    const content2Web = () => {
        return (
            <Fragment>
                <View style={styles.inputsContainer}>
                    <View style={{ width: '100%', height: currentPlatform !== 'web' ? '60%' : '100%', paddingTop: 50 }} >
                        <Text style={{ ...styles.headerText, color: currentThemeColor.primaryTextColor }}>{t("Challenge_yourself")}</Text>
                        <Text style={{ ...styles.discText, color: currentThemeColor.primaryTextColor, ...{width: screenWidth / 2} }}  >{t("Embark_on_an_exhilarating")}</Text>
                        <CustomButton btnStyle={{ marginVertical: 20, width: '40%', }} text={t('next')} onPress={() => { navigation.navigate('LoginScreen') }} />
                    </View>
                </View>
            </Fragment>
        )
    }


    const Screen2 = () => {
        return currentPlatform === 'web' ? <WebBackgroundBottomColor elementOnImage={content2Web()}> {<Image source={panda4} style={{width: 280, height: 280, resizeMode: 'contain', bottom: 0, alignSelf: 'center'}} />}</WebBackgroundBottomColor>  :(
            <ImageBackground source={currentTheme == 'dark' ? back3Dark : back3} style={{ width: screenWidth, height: currentPlatform !== 'web' ? screenHeight : '100%'}} resizeMode='cover' >
                {content2()}
            </ImageBackground >
        );
    }

    return (
        <View style={mainStyle.mainContainer}>
            <ScrollView>
                {screen == 1 && < Screen1 />}
                {screen == 2 && < Screen2 />}
            </ScrollView>
        </View >
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.mainBackground
    },
    inputsContainer: {
        width: '100%',
        height: '100%',
        alignSelf: 'center',
        marginTop: isIos ? 50 : 0,
    },
    panda1: {
        position: 'absolute',
        bottom: 0,
        left: '5%',
        width: 100,
        height: 100,
        resizeMode: 'contain'
    },
    panda3: {
        height: '85%',
        width: '80%',
        resizeMode: 'contain',
    },
    panda4: {
        height: '90%',
        width: '100%',
        resizeMode: 'contain',
    },
    branch: {
        position: 'absolute',
        bottom: '7%',
        right: 0,
        width: 70,
        height: 130,
        resizeMode: 'contain',
    },
    headerText: {
        textAlign: 'center',
        alignSelf: 'center',
        width: '80%',
        fontSize: 28,
        fontFamily: FONTFAMILY
    },
    discText: {
        textAlign: 'center',
        alignSelf: 'center',
        width: '80%',
        fontSize: 14,
        color: '#000',
        marginTop: 25,
        lineHeight: 22,
        fontFamily: FONTFAMILY
    }
})

export default OnboardingScreen;