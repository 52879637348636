import React, { useCallback, useEffect, useState } from "react";
import { View, StyleSheet, TouchableOpacity, Image, Text, ScrollView, Alert, Modal, StatusBar } from "react-native";
import { Colors, FONTFAMILY, currentPlatform } from "../../constants/Colors";
import { mainStyle } from "../../mainstyle";
import CustomButton from "../../components/customButton";

import panda7 from '../../assets/panda/panda7.png';
import panda5 from '../../assets/panda/panda5.png';
import arrowDown from '../../assets/icons/arrowDown.png';
import arrowDown2 from '../../assets/icons/arrowDown2.png';
import arrowRight from '../../assets/icons/arrowRight.png';
import arrowRight2 from '../../assets/icons/arrowRight2.png';
import arrowUp from '../../assets/icons/arrowUp.png';
import arrowUp2 from '../../assets/icons/arrowUp2.png';
import arrowLeft from '../../assets/icons/back.png';
import arrowBack2 from '../../assets/icons/arrowBack2.png';
import home2 from '../../assets/panda/home2.png';
import home3 from '../../assets/panda/home3.png';
import tree2 from '../../assets/panda/tree2.png';
import panda1 from '../../assets/panda/panda1.png';
import reload from '../../assets/icons/reload.png';
import { useAuth } from "../../contexts/AuthContext";
import { useTranslation } from "react-i18next";

const FirstGameScreen = ({ route, navigation }) => {

    const { t } = useTranslation();
    const { currentTheme, currentThemeColor } = useAuth();
    const [pandaIndex, setPandaIndex] = useState(0);
    const NumberOfSquare = 16;
    const [modalVisible, setModalVisible] = useState(false);
    const [code, setCode] = useState([]);
    const [randomNumber, setRandomNumber] = useState(route?.params?.randomNumber || 16);

    const changeLocation = useCallback(location => {
        const moveFunctions = {
            right: { index: pandaIndex + 1, code: 'panda.moveRight()' },
            left: { index: pandaIndex - 1, code: 'panda.moveLeft()' },
            down: { index: pandaIndex + 4, code: 'panda.moveDown()' },
            up: { index: pandaIndex - 4, code: 'panda.moveUp()' }
        };

        if (moveFunctions[location] && moveFunctions[location].index >= 0 && moveFunctions[location].index < NumberOfSquare) {
            setPandaIndex(moveFunctions[location].index);
            setCode(prev => [...prev, moveFunctions[location].code]);
        }
    }, [pandaIndex]);

    useEffect(() => {
        if (randomNumber === pandaIndex) {
            setModalVisible(true);
        }
    }, [randomNumber, pandaIndex]);

    const getRandomNumber = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const reSetGame = () => {
        const newRandomNumber = getRandomNumber(1, 16);
        setRandomNumber(newRandomNumber);
        setPandaIndex(0);
        setCode(['panda.reSet()']);
        setModalVisible(false);
    };

    const goToHome = () => {
        setModalVisible(false);
        navigation.navigate('SetCourseScreen');
    };

    return (
        <>
            <StatusBar barStyle="default" backgroundColor={Colors.secondary} />

            <View style={styles.container}>
                <View style={styles.Div1}>
                    <View style={styles.Div1container}>
                        {[...Array(NumberOfSquare)].map((_, index) => (
                            <View key={index} style={{ ...styles.square, backgroundColor: currentThemeColor.box }} >
                                {index === pandaIndex && pandaIndex != randomNumber && <Image source={panda7} style={{ width: '80%', height: '80%', resizeMode: 'contain' }} />}
                                {index === randomNumber && pandaIndex != randomNumber && <Image source={home2} style={{ width: '80%', height: '80%', resizeMode: 'contain' }} />}
                                {index === randomNumber && pandaIndex == randomNumber && < >
                                    <Image source={panda5} style={{ width: '50%', height: '50%', resizeMode: 'contain', position: 'absolute', zIndex: 3, bottom: 1 }} />
                                    <Image source={home3} style={{ width: '97%', height: '97%', resizeMode: 'contain', position: 'absolute', zIndex: 2, }} />
                                </ >}
                            </View>
                        ))}
                    </View>
                </View>

                <View style={styles.Div2}>
                    <View style={{ width: '100%', height: '100%', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }}>
                        <TouchableOpacity style={{ ...styles.arrowBtn, backgroundColor: currentThemeColor.box }} onPress={() => { changeLocation('down') }}>
                            <Image source={currentTheme == 'dark' ? arrowDown2 : arrowDown} style={styles.arrowBtnImage} />
                        </TouchableOpacity>
                        <TouchableOpacity style={{ ...styles.arrowBtn, backgroundColor: currentThemeColor.box }} onPress={() => { changeLocation('up') }}>
                            <Image source={currentTheme == 'dark' ? arrowUp2 : arrowUp} style={styles.arrowBtnImage} />
                        </TouchableOpacity>
                        <TouchableOpacity style={{ ...styles.arrowBtn, backgroundColor: currentThemeColor.box }} onPress={() => { changeLocation('left') }}>
                            <Image source={currentTheme == 'dark' ? arrowBack2 : arrowLeft} style={styles.arrowBtnImage} />
                        </TouchableOpacity>
                        <TouchableOpacity style={{ ...styles.arrowBtn, backgroundColor: currentThemeColor.box }} onPress={() => { changeLocation('right') }}>
                            <Image source={currentTheme == 'dark' ? arrowRight2 : arrowRight} style={styles.arrowBtnImage} />
                        </TouchableOpacity>
                    </View>
                </View>

                <View style={styles.Div3}>
                    <View style={styles.Div3container}>
                        <ScrollView style={{ width: '100%', height: '100%', }}>
                            <View style={{ width: '100%', height: '100%', flexDirection: 'column-reverse', }}>
                                <Text style={styles.codeText}>{'> Code'}</Text>
                                {code ? code.map((item, index) => (
                                    <Text key={index} style={styles.codeText}>{'> ' + item}</Text>
                                )) : null}
                            </View>
                        </ScrollView>
                    </View>
                </View>

                <Modal
                    visible={modalVisible}
                    animationType="slide"
                    transparent={true}
                    onRequestClose={() => setModalVisible(false)}
                >
                    <View style={{ flex: 2, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                        <View style={{ backgroundColor: 'white', borderRadius: 10, width: '80%', height: '50%', }}>
                            <View style={{ padding: 20, borderRadius: 10, width: '100%', height: '100%', }}>
                                <Text style={styles.ModalHeader}>{t("Now_you_finished_the_Game")}</Text>
                                <View style={{ width: '97%', paddingVertical: 10, alignSelf: 'center', marginTop: 50, flexDirection: 'row', justifyContent: 'space-evenly' }}>
                                    <CustomButton btnStyle={{ width: '69%', }} text={t('start')} onPress={() => { goToHome() }} />
                                    <CustomButton btnStyle={{ width: 50, height: 50 }} icon={reload} onPress={() => { reSetGame() }} />
                                </View>
                            </View>
                            <Image source={panda1} style={styles.panda1} />
                            <Image source={tree2} style={mainStyle.tree2} />
                        </View>
                    </View>
                </Modal>

            </View>
        </>
    );
};

export default FirstGameScreen;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.secondary,
        paddingTop: currentPlatform == 'ios' ? 60 : 0,
        paddingBottom: currentPlatform == 'ios' ? 20 : 0
    },
    Div1: {
        width: '100%',
        height: 400,
        justifyContent: 'center',
        alignItems: 'center',
    },
    Div1container: {
        backgroundColor: '#F9F1E9',
        width: '95%',
        height: '95%',
        borderRadius: 10,
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    square: {
        width: '25%',
        height: '25%',
        borderWidth: 0.5,
        justifyContent: 'center',
        alignItems: 'center'
    },
    Div2: {
        width: '100%',
        flex: 1,
    },
    Div3: {
        width: '100%',
        flex: 3,
        justifyContent: 'center',
        alignItems: 'center',
    },
    Div3container: {
        backgroundColor: '#000',
        width: '95%',
        height: '95%',
        borderRadius: 10,
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        padding: 10
    },
    arrowBtn: {
        width: 70,
        height: 70,
        backgroundColor: '#fff',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 10
    },
    arrowBtnImage: {
        width: '90%',
        height: '90%',
        resizeMode: 'contain'
    },
    codeText: {
        color: '#fff',
        marginBottom: 7,
        fontSize: 15,
        fontFamily: FONTFAMILY
    },
    ModalHeader: {
        textAlign: 'center',
        fontFamily: FONTFAMILY,
        alignSelf: 'center', 
        width: '90%',
        color: '#000',
        fontSize: 20
    },
    panda1: {
        position: 'absolute',
        bottom: 0,
        left: '5%',
        width: 100,
        height: 100,
        resizeMode: 'contain'
    },
});
