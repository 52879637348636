import { ImageBackground, FlatList, StyleSheet, Text, View, Modal, Image, ScrollView } from "react-native";
import back2 from '../../assets/background/back2.png';
import back2Dark from '../../assets/background/back2Dark.png';
import { useAuth } from "../../contexts/AuthContext";
import { Colors, FONTFAMILY, currentPlatform, isWeb, screenHeight, screenWidth } from "../../constants/Colors";
import { mainStyle } from "../../mainstyle";
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import { Fragment, useEffect, useState } from "react";
import ResultView from '../../components/ResultView';
import CustomLoader from "../../components/CustomLoader";
import globalConstants from "../../config/globalConstants";
import { customGet, get, post } from "../../WebService/RequestBuilder";
import panda1 from '../../assets/panda/panda1.png';
import { useTranslation } from "react-i18next";
import CustomButton from "../../components/customButton";
import tree2 from '../../assets/panda/tree2.png';
import { WebBackgroundTopColor } from "../../components/WebBackground";
import { CommonActions } from '@react-navigation/native';

const QuestionsResultsScreen = ({ navigation, route }) => {
  const { t } = useTranslation();
  const { tutorialId, action, isQuickExam, message } = route.params

  const { currentTheme, currentThemeColor, token } = useAuth();
  const [percentageCorrect, setPercentageCorrect] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [results, setResults] = useState([]);
  const [messageModal, setMessageModal] = useState('');

  useEffect(() =>  {
    const getResults = async () => {
      try {
        const {data} = await customGet(globalConstants.userCourses.results, {
          user_tutorial_question_id: tutorialId,
          is_quick_exam: isQuickExam ? 'yes' : 'no'
        });
        setPercentageCorrect(data.percentage_correct);
        setResults(data.questions_results);
      } catch (error) {
        console.log("error: ", error);
      } finally {
        setIsLoading(false);
      }
    }

    getResults();
  }, [tutorialId])

  const nextScreen = async () => {
    if (action === 'GameStack') {
        switch (message) {
          case 'Level not finished':
            setMessageModal('Level_not_finished');
            break;
          case 'Went to next level successfully':
            setMessageModal('Went_to_next_level_successfully');
            break;
          case 'User can not move to next level':
            setMessageModal('User_can_not_move_to_next_level');
          default:
            setMessageModal(message);
            break;
        }
        setModalVisible(true)
        return
    } else {
        navigation.navigate(action);
        return
    }
  }

  const goToGame = () => {
    setModalVisible(false)
    navigation.navigate(action)
  }

  const goToHome = () => {
    setModalVisible(false);
    navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [{ name: 'MainStack', state: { routes: [{ name: 'BottomTabNavigator' }] } }],
      })
    );
    // if(isWeb) {
    //   window.location.href = '/home';
    // } else {
    //   navigation.navigate('BottomTabNavigator')
    // }
  }

  if(isLoading) {
    return (
        <CustomLoader text={t('Lets_get_your_results')} />
    )
  }

  const pageContent = () => {
    return<Fragment>
          <View style={styles.divContainer}>
            <AnimatedCircularProgress
              size={250}
              width={10}
              fill={percentageCorrect}
              padding={10}
              lineCap={'round'}
              tintColor={Colors.btnColor}
              tintTransparency={true}
              rotation={360}
              style={styles.circularProgress}
              backgroundColor={isWeb ? currentThemeColor.webProgressBackground : Colors.mainBackground}
              >
              {(fill) => (
                <Text style={styles.progressText}>
                  {Number.isInteger(fill) ? fill.toString() : fill.toFixed(2)}%
                </Text>
              )}
            </AnimatedCircularProgress>

          <FlatList
            data={results}
            keyExtractor={(item, index) => index.toString()}
            renderItem={({ item }) => <ResultView isQuickExam={isQuickExam} question={item} />}
            contentContainerStyle={{ paddingBottom: 20 }} 
            showsVerticalScrollIndicator={false}
            scrollEnabled={false}
          />
          <CustomButton isSubmit btnStyle={{ width: '90%', marginBottom: 100 }} text={t('next')} onPress={() => { nextScreen() }} />
          <Modal
            visible={modalVisible}
            animationType="slide"
            transparent={true}
            onRequestClose={() => setModalVisible(false)}
          >
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
        <View style={{ backgroundColor: 'white', borderRadius: 10, width: isWeb ? '50%' : '80%', height: '50%', alignItems: 'center' }}>
            <Text style={styles.ModalHeader}>{t(messageModal)}</Text>
            <View style={{ width: '100%', marginTop: 20, justifyContent: 'center', alignItems: 'center' }}>
                <CustomButton isSubmit btnStyle={{ width: isWeb ? '60%' : '90%' }} text={t('go_to_home')} onPress={() => { goToHome() }} />
                <CustomButton isSubmit btnStyle={{ width: isWeb ? '60%' : '90%', marginTop: 10 }} text={t('Play_The_Game')} onPress={() => { goToGame() }} />
            </View>
            <Image source={panda1} style={styles.panda1} />
            <Image source={tree2} style={mainStyle.tree2} />
        </View>
    </View>
</Modal>

        {/* </ScrollView> */}
          </View>
    </Fragment>
  } 

  return (
    currentPlatform === 'web' ? (
      <WebBackgroundTopColor>
          {pageContent()}
      </WebBackgroundTopColor>
  ) : <View style={mainStyle.mainContainer}>
      <ImageBackground
        source={currentTheme === 'dark' ? back2Dark : back2}
        style={{ top: - 60, width: screenWidth, height: screenHeight + 100 }}
        resizeMode="cover"
      >
          <ScrollView>
          {pageContent()}
          </ ScrollView>

      </ImageBackground>
    </View>
  );
};

export default QuestionsResultsScreen;

const styles = StyleSheet.create({
  progressText: {
    color: Colors.btnColor,
    fontSize: 30,
    maxWidth: 100,
  },
  divContainer: {
    alignItems: 'center',
    width: currentPlatform === 'web' ? screenWidth / 2 : '100%',
    alignSelf: 'center'
  },
  circularProgress: {
    marginTop: 110,
    marginBottom: 30,
  },
  ModalHeader: {
    textAlign: 'center',
    fontFamily: FONTFAMILY,
    width: '90%',
    color: '#000',
    fontSize: 20,
    marginVertical: 40
  },
  panda1: {
    position: 'absolute',
    bottom: 0,
    left: '5%',
    width: 100,
    height: 100,
    resizeMode: 'contain'
  },
});
