export default {
    storageTokenKeyName: 'access_token',
    default_lang: 'default_lang',
    appTheme: 'appTheme',
    uId: 'uId',
    user_type: 'user_type',
    userData: 'userData',
    subUserData: 'subUserData',
    subUserImage: 'subUserImage',
    onlineStatus: 'onlineStatus',
    accountType: 'accountType',
    currentCourseName: 'currentCourseName',
    currentSubUserCourse: 'currentSubUserCourse',
    currentSubUserCourseImage: 'currentSubUserCourseImage',

    auth: {
        login: 'auth/login',
        register: 'auth/register',
        logout: 'logout',
        newProfile: 'user/new-profile',
        loginSubProfile: 'user/login-sub-profile',
        resendOTP: 'auth/resend-otp',
        verifyOTP: 'auth/verify-otp',
        passwordRequest: 'auth/password/request',
        socialLogin: 'auth/social-login',
        sendOtpForgotPassword: 'auth/send-otp-forgot-password',
        changeForgotPassword: 'auth/change-forgot-password',
    },
    user: {
        editProfile: 'user/edit-profile',
        editSubProfile: 'user/update-sub-profile',
        createFeedback: 'user/create-feedback',
        subscriptions: 'user/subscriptions',
        toggleDarkMde: 'user/toggle-dark-mode',
        changeLanguage: 'user/change-language',
        addSubscription: 'user/add-user-subscription',
        checkPromoCode: 'user/check-promo-code',
        uploadUserImage: 'user/upload-user-image',
        updateWithExperience: 'user/update-with-experience',
        changeProfilePassword: 'user/change-profile-password',
        dailyPoints: 'user/daily-points',
        changePasswordMainUser: 'user/change-password',
        toggleOnlineStatus: 'user/toggle-online',
        rankedUsersCourses: 'user/ranked-users-courses',
        rankedUsers: 'user/ranked-users',
        getUserInfo: 'user/user-info/{user_info_id}',
        setCodingTutorialsAsRead: 'user/set-coding-tutorial-as-read',
        weeklyPoints: 'user/weekly-points/{userInfoId}',
        userCourseProgress: 'user/user-progress',
    },
    userCourses: {
        newCourse: 'user/user-courses',
        saveAnswer: 'user/save-user-answers',
        nextLevel: 'user/next-level',
        nextQuestion: 'user/next-question',
        nextTutorial: 'user/next-tutorial',
        checkHavingCourse: 'user/check-user-course',
        results: 'user/questions-results',
        setFactAsRead: 'user/set-fact-as-read', //game_fact_id
        finishSandwichGame: 'user/finish-sandwich-game', //user_info_game_id
        userGame: 'user/user-game', // user_info_id
    },
    chatGPT: {
        introductionTutorials: 'user/introduction-tutorials',
        userQuickExam: 'user/user-quick-exam',
        userLevelTutorials: 'user/user-level-tutorials',
        userTutorialQuestions: 'user/user-tutorial-questions',
        userGameMaterial: 'user/user-game-material', //user_course_id
        askMrcode: 'user/chat/completions',
        checkIfCorrect: 'user/chat/questions',
        getQuestions: 'user/chat/generateQuestion',
        getExplanation: 'user/chat/explainTutorial'
    },
    general: {
        admin: 'user/admin',
        courses: 'user/courses',
    }
};
