import React from 'react';
import { Modal, View, Text, StyleSheet, ScrollView, ActivityIndicator } from 'react-native';
import { FONTFAMILY } from '../constants/Colors';
import CustomButton from '../components/customButton';
import { t } from 'i18next';

const ExplanationModal = ({ visible, onClose, explanation, explanationLoader }) => {

    return (
        <Modal
            animationType="slide"
            transparent={true}
            visible={visible}
            onRequestClose={onClose}
        >
            <View style={styles.modalContainer}>
                <View style={styles.modalView}>
                    <Text style={styles.modalTitle}>{t('Explanation')}</Text>
                    <ScrollView contentContainerStyle={styles.scrollViewContent}>
                        {explanationLoader ? (
                            <View style={styles.loadingContainer}>
                                <ActivityIndicator size="large" color="#0000ff" />
                                <Text style={styles.loadingText}>{t('Wait until we generate the explanation')}</Text>
                            </View>
                        ) : (
                            <Text style={styles.modalContent}>
                                {explanation ? explanation : 'No explanation available.'}
                            </Text>
                        )}
                    </ScrollView>
                    <CustomButton text={t("Cancel")} onPress={onClose} />
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
    modalView: {
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 20,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        width: '80%',
        maxHeight: '80%',
    },
    modalTitle: {
        fontFamily: FONTFAMILY,
        fontSize: 20,
        marginBottom: 15,
        textAlign: 'center',
    },
    scrollViewContent: {
        padding: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContent: {
        fontFamily: FONTFAMILY,
        fontSize: 16,
        textAlign: 'left',
    },
    loadingContainer: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    loadingText: {
        fontFamily: FONTFAMILY,
        fontSize: 16,
        marginTop: 10,
        textAlign: 'center',
    },
});

export default ExplanationModal;
