import React, { Fragment, useEffect, useState } from "react";
import { Text, View, ImageBackground, StyleSheet, Image, FlatList, ScrollView, Platform, TouchableOpacity } from "react-native";
import { BOTTOMBARHEIGHT, Colors, currentPlatform, isWeb, screenHeight, screenWidth } from "../../../constants/Colors";
import { mainStyle } from "../../../mainstyle";

import back6Dark from '../../../assets/background/back6Dark.png';
import back6 from '../../../assets/background/back6.png';
import star1 from '../../../assets/icons/star1.png';
import star2 from '../../../assets/icons/star2.png';
import star3 from '../../../assets/icons/star3.png';
import ring1 from '../../../assets/icons/ring1.png';
import ring2 from '../../../assets/icons/ring2.png';
import ring3 from '../../../assets/icons/ring3.png';
import panda1 from '../../../assets/panda/panda6.png';
import panda2 from '../../../assets/panda/panda5.png';
import panda3 from '../../../assets/panda/panda7.png';
import userP from '../../../assets/background/userP.png';
import { useAuth } from "../../../contexts/AuthContext";
import { customGet, get } from "../../../WebService/RequestBuilder";
import globalConstants from "../../../config/globalConstants";
import CustomLoader from "../../../components/CustomLoader";
import { WebBackgroundTopColor } from "../../../components/WebBackground";
import { useTranslation } from "react-i18next";


const TopRatedScreen = () => {
    const { t } = useTranslation();
    const { currentTheme, currentThemeColor, currentCourseName, currentSubUserCourse } = useAuth();
    const [rankType, setRankType] = useState('global');
    const [rankedUsers, setRankedUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getData = async () => {
            try {
                // setIsLoading(true);
                let response = null;

                if(rankType !== 'global') {
                    response = await customGet(globalConstants.user.rankedUsersCourses,  {
                        course_id: currentSubUserCourse?.course_id
                    });
                } else {
                    response = await customGet(globalConstants.user.rankedUsers);
                }
         
                 const updatedData = response?.data.map((item, index) => {
                     if (index < 3) {
                         let orderLabel;
                         switch (index) {
                             case 0:
                                 orderLabel = "1st";
                                 break;
                             case 1:
                                 orderLabel = "2nd";
                                 break;
                             case 2:
                                 orderLabel = "3rd";
                                 break;
                             default:
                                 break;
                         }
                         return { ...item, order: orderLabel };
                     }
     
                     if(item.current_level == 0) item.current_level = "1";
                     return item;
                 });
         
                 setRankedUsers(updatedData);
            } catch (error) {
                console.log("Top rated error", error);
            } finally {
                setIsLoading(false);
            }
        };

        getData();
    }, [rankType]);

    const firstThree = rankedUsers.slice(0, 3);
    const rest = rankedUsers.slice(3);

    const sortedData = firstThree.sort((a, b) => {
        const orderMap = { '2nd': 1, '1st': 2, '3rd': 3 };
        return orderMap[a.order] - orderMap[b.order];
    });

    const renderItemFirstThree = ({ item }) => {
        let starImage, ringImage, pandaImage, style;
    
        if (item.order === '1st') {
            starImage = star3;
            ringImage = ring1;
            pandaImage = panda1;
            style = {
                height: '100%',
                width: '90%',
                bottom: '15%'
            };
        } else if (item.order === '2nd') {
            starImage = star2;
            ringImage = ring2;
            pandaImage = panda2;
            style = {
                height: '100%',
                width: '90%',
                top: '10%'
            };
        } else if (item.order === '3rd') {
            starImage = star1;
            ringImage = ring3;
            pandaImage = panda3;
            style = {
                height: '100%',
                width: '90%',
                top: '10%'
            };
        }
    
        // const containerWidth = (screenWidth - 40) / sortedData.length;
        const itemContainerStyle = {
            // width: screenWidth,
            alignItems: 'center',
        };
    
        return (
            <View style={[styles.itemContainer, itemContainerStyle, style]}>
                <Image source={starImage} style={styles.starImage} />
                <View style={styles.ringImageBackground}>
                    <Image source={ringImage} style={styles.ringImage} />
                    <View style={styles.pandaContainer}>
                        <Image source={pandaImage} style={styles.pandaImage} />
                    </View>
                </View>
                <Text style={{ ...styles.name, color: currentThemeColor.primaryTextColor }}>{item.user_name}</Text>
            </View>
        );
    };

    if(isLoading) {
        return (
            <CustomLoader text={t('Loading')} />
        )
    }

    const pageContent = () => {
        return (
                    <View style={styles.topRatedView}>
                        {/* <View> */}
                            {currentPlatform !== 'web'&&<FlatList
                                data={sortedData}
                                renderItem={renderItemFirstThree}
                                keyExtractor={(item) => (item.user_info_id ? item.user_info_id.toString() : null)}
                                contentContainerStyle={styles.listContainer}
                                scrollEnabled={false}
                            />}
                        {/* </View> */}
                        <View style={styles.topRatedHeader}>
                            <Text style={{ ...styles.topRatedHeaderTxt, color: currentThemeColor.primaryTextColor }}  >{t("top_rated")}</Text>
                        </View>
                        <View style={styles.switchContainer}>
                            <TouchableOpacity onPress={() => {setRankType('global')}} style={[styles.switchButton, rankType === 'global' ? { backgroundColor: currentThemeColor.backgroundToggleRank } : {}]}>
                                <Text style={styles.buttonText}>{t("General_Rate")}</Text>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => {setRankType(currentCourseName)}} style={[styles.switchButton, rankType === currentCourseName ? { backgroundColor: currentThemeColor.backgroundToggleRank } : {}]}>
                                <Text style={styles.buttonText}>{currentCourseName}</Text>
                            </TouchableOpacity>
                        </View>

                        <View style={{paddingBottom: isWeb ? 180 : 0}}>
                            {rest?.map((item, index) => {
                                return (
                                    <View key={`item-${index}`} style={[styles.topRatedViewItem]}>
                                        <Text style={styles.topId}>{item.rank_order}</Text>
                                        <View style={styles.viewImage}>
                                            <Image source={item?.attachment ? {uri: item?.attachment} : userP} style={styles.personImage} />
                                        </View>
                                        <View style={styles.topViewName}>
                                            <Text style={styles.topName}>{item.user_name}</Text>
                                            <Text style={styles.topLevel}>{t("Level")} {item.current_level}</Text>
                                        </View>
                                        <Text style={styles.topPoints}>{item.points} {t("Points")}</Text>
                                    </View>
                                )
                            })}
                            </View>
                    </View>
        )
    }

    return (
        isWeb ? (
                <ScrollView style={{backgroundColor: currentThemeColor.webMainBackground}} showsVerticalScrollIndicator={false} contentContainerStyle={[styles.scrollViewContent]}>
            <View style={[mainStyle.mainContainer]}>
                <WebBackgroundTopColor elementOnImage={<FlatList
                    data={sortedData}
                    renderItem={renderItemFirstThree}
                    keyExtractor={(item) => (item.user_info_id ? item.user_info_id.toString() : null)}
                    contentContainerStyle={styles.listContainer}
                    scrollEnabled={true}
                />} customHightHeader={300}>
        <View style={{ width: screenWidth, height: screenHeight, paddingBottom: BOTTOMBARHEIGHT }} >

        {pageContent()}

        </View>
            </WebBackgroundTopColor>
        </View>
        </ScrollView>
            
        ) : <View style={mainStyle.mainContainer}>
            <ImageBackground source={currentTheme == 'dark' ? back6Dark : back6} style={{ width: screenWidth, height: screenHeight, flex: 1, paddingBottom: BOTTOMBARHEIGHT }} resizeMode='cover'>
                <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={styles.scrollViewContent}>
                    {pageContent()}
                </ScrollView>
            </ImageBackground>
        </View>
    );

};

const styles = StyleSheet.create({
    scrollViewContent: {
        flexGrow: 1,
    },
    itemContainer: {
        flex: 1,
        alignItems: 'center',
        width: 137,
        alignContent: 'center',
    },
    listContainer: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        paddingVertical: 50,
        paddingHorizontal: 8,
        width: isWeb ? screenWidth / 2 : screenWidth - 30,
        maxWidth: screenWidth,
        alignSelf: 'center'
    },
    starImage: {
        width: 100,
        height: 42,
        bottom: '6%',
        resizeMode: 'contain',
    },
    pandaContainer: {
        position: 'absolute',
        bottom: '15%',
        left: '1%',
        width: 80,
        height: 90,
        justifyContent: 'center',
        alignItems: 'center',
    },
    pandaImage: {
        width: 80,
        height: 90,
        resizeMode: 'contain',
    },
    ringImage: {
        width: 116,
        height: 103,
        resizeMode: 'contain',
        borderRadius: 50,
    },
    ringImageBackground: {
        width: 82,
        height: 82,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Colors.mainBackground,
        borderRadius: 50,
    },
    name: {
        fontSize: 22,
        fontWeight: '400',
        lineHeight: 35,
        color: Colors.black,
        marginTop: 8,
        width: '100%',
        textAlign: 'center'
    },
    topRatedHeaderTxt: {
        color: Colors.black,
        fontWeight: '400',
        fontSize: 30,
    },
    topRatedHeader: {
        height: 70,
        width: isWeb ? screenWidth / 2 : screenWidth - 40,
        backgroundColor: 'rgba(0, 0, 0, 0.27)',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        marginBottom: 24,
    },
    topRatedView: {
        alignItems: 'center',
        alignSelf: 'center',
        paddingTop: currentPlatform == 'ios' ? 50 : 0,
        width: isWeb ? screenWidth / 2 : screenWidth - 40,
    },
    topRatedViewItem: {
        height: 70,
        width: isWeb ? screenWidth / 2 : screenWidth - 40,
        backgroundColor: 'rgba(0, 0, 0, 0.27)',
        marginBottom: 24,
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    personImage: {
        width: 50,
        height: 50,
        borderRadius: 50,
    },
    viewImage: {
        width: 60,
    },
    topName: {
        color: Colors.mainBackground,
        fontSize: 20,
        textAlign: 'left'
    },
    topLevel: {
        color: Colors.mainBackground,
        fontSize: 17,
        textAlign: 'left'
    },
    topPoints: {
        color: Colors.mainBackground,
        top: '4%',
        fontSize: 15,
    },
    topViewName: {
        width: '40%'
    },
    points: {
        width: '20%'
    },
    topId: {
        fontSize: 25,
        color: Colors.lightGray,
        width: 40,
        marginHorizontal: 10,
    },
    switchContainer: {
        flexDirection: 'row',
        // width: screenWidth - 40,
        height: 60,
        paddingVertical: 7,
        backgroundColor:  'rgba(0, 0, 0, 0.27)',
        marginBottom: 20,
        width: isWeb ? screenWidth / 2 : screenWidth - 40,

    },
    switchButton: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: 7
    },
    buttonText: {
        color: 'white',
        fontSize: 20,
    }
});


export default TopRatedScreen;