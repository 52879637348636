import { View, Text, TextInput, TouchableOpacity, Image } from "react-native";
import Feather from 'react-native-vector-icons/Feather';
import { mainStyle } from "../mainstyle";
import { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import showPassword from '../assets/icons/show.png';
import hidePassword from '../assets/icons/hide.png';


const Input = ({ lable = 'lable', placeholder = "placeholder", onChange = {}, secureTextEntry = false, type = 'text', mainDivStyle, lableStyle, keyboardType = 'default', subText, multiline = false, value, maxLength }) => {

    const [issecure, setissecure] = useState(secureTextEntry);
    const { currentTheme, currentThemeColor, lang } = useAuth();

    return (
        <View style={{ ...mainStyle.inputMainDiv, ...mainDivStyle, width: type == 'short' ? '48%' : '100%' }}>
            <Text style={{ ...mainStyle.inputLabel, color: currentThemeColor.primaryTextColor, ...lableStyle, alignSelf: lang == 'ar' ? 'flex-end' : 'flex-start' }}>{lable}</Text>
            {subText && < Text style={{ ...mainStyle.inputSubLabel, color: currentThemeColor.primaryTextColor }}>{subText}</Text>}
            <View style={{ ...mainStyle.inputDiv, height: multiline ? 150 : 50, flexDirection: lang == 'ar' ? 'row-reverse' : 'row', }}>
                < TextInput
                    placeholder={placeholder}
                    style={{ ...mainStyle.input, textAlign: lang == 'ar' ? 'right' : 'left',...(multiline ? { textAlignVertical: 'top', paddingTop: 20 } : {}) }}                    placeholderTextColor={"#8A8A8A"}
                    onChangeText={(text) => { onChange(text) }}
                    secureTextEntry={issecure}
                    keyboardType={keyboardType}
                    multiline={multiline}
                    value={value}
                    maxLength={maxLength ?? maxLength}
                />
                {type == 'password' && (
                    <TouchableOpacity onPress={() => { setissecure(!issecure) }} style={mainStyle.iconButton}>
                        <Image
                            source={issecure ? showPassword : hidePassword}
                            style={mainStyle.icon}
                        />
                    </TouchableOpacity>
                )}
            </View>
        </View >
    )
}

export default Input;