import { useState, useEffect, useCallback, Fragment } from 'react';
import { View, Text, StyleSheet, Image, ImageBackground, TouchableOpacity, Appearance, Modal, Pressable, ScrollView } from 'react-native';
import { SelectList } from 'react-native-dropdown-select-list'
import { Colors, FONTFAMILY, isWeb, screenHeight, screenWidth } from '../../../constants/Colors';
import { mainStyle } from '../../../mainstyle';
import Header from '../../../components/header';
import SwitchWithImageAndAnimation from '../../../components/SwitchWithImageAndAnimation';
import back4 from '../../../assets/background/back4.png';
import back4Dark from '../../../assets/background/back4Dark.png';
import panda2 from '../../../assets/panda/panda2.png';
import branch from '../../../assets/panda/branch.png';
import arrowDown from '../../../assets/icons/arrowDown.png';
import arrowUp from '../../../assets/icons/arrowUp.png';
import { storeJsonData } from '../../../helper/GeneralStorage';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../contexts/AuthContext';
import globalConstants from '../../../config/globalConstants';
import { post } from '../../../WebService/RequestBuilder';
import CustomButton from '../../../components/customButton';
import CustomAlert from '../../../components/customAlert';
import CustomLoader from '../../../components/CustomLoader';
import { WebBackgroundTopColor } from '../../../components/WebBackground';



const SettingsScreen = ({ navigation }) => {
    const { t, i18n } = useTranslation();
    const { currentTheme, currentThemeColor, changeMode, lang, user, subUser, accountType } = useAuth();

    const [isEnabled, setIsEnabled] = useState(currentTheme !== 'dark');
    const [flexDirection, setflexDirection] = useState('row');
    const [modalVisible, setModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const Languages = [
        { value: "English", storedValue: 'en' },
        { value: "عربي", storedValue: 'ar' },
        { value: "Española", storedValue: 'es' },
        { value: "française", storedValue: 'fr' }
    ];

    const placeholderLangLabel = Languages.find(item => item.storedValue === lang)?.value;

    useEffect(() => {
        setflexDirection(lang == 'ar' ? 'row-reverse' : 'row');
    }, [lang])

    const setNewLang = async (newLang) => {
        try {
            setIsLoading(true)
            await i18n.changeLanguage(newLang);
            await storeJsonData(globalConstants.default_lang, newLang);
            post(globalConstants.user.changeLanguage, { "language": newLang, 'user_info_id': subUser.id }).then((res) => {
            }).catch((error) => { console.log('error  ===>', error); })
        } catch (error) {
            console.log();
        } finally {
            setIsLoading(false)
        }
    };

    const changeModeFunction = (res) => {
        setIsEnabled(res);
        const mode = res ? 'light' : 'dark';
        changeMode(mode);
    };

    // const changeOnlineStatusFunction = async (res) => {
    //     setIsEnabled(res);
    //     await changeOnlineStatus()
    // };

    const getData = () => {
        // console.log("currentTheme", currentTheme);
        // const theme = Appearance.getColorScheme();
        setIsEnabled(currentTheme !== 'dark');
    };

    useEffect(() => {
        getData();
    }, []);


    const [alertData, setAlertData] = useState(null);
    const showAlert = useCallback((message, type) => { setAlertData({ message, type }); }, []);
    const hideAlert = useCallback(() => { setAlertData(null); }, []);

    const submitReset = () => {
        const body = { email: user.email };
        post(globalConstants.auth.passwordRequest, body).then((res) => {
            showAlert(t("Reset_link_sent_to_your_email"), 'success');
        }).catch((error) => {
            showAlert(t("Email_was_sent_wait_before_trying"), 'error');
            console.log('error', error);
        }).finally(() => {
            setModalVisible(false);
        });
    }

    if(isLoading) {
        return (
            <CustomLoader text={t('Changing_Language')} />
        )
    }

    const pageContent = () => {
        return (
            <Fragment>
                    <Header title={t('Settings')} />

                    <View style={styles.inputsContainer}>
                    <View style={{ ...styles.imgDiv, flexDirection }}>
                        <Text style={{ ...styles.label, color: currentThemeColor.primaryTextColor }}>{t("App_Theme")}</Text>
                        <SwitchWithImageAndAnimation Value={isEnabled} onPress={(newValue) => changeModeFunction(!isEnabled)} />
                    </View>
                    <View style={{ ...styles.imgDiv, flexDirection }}>
                        <Text style={{ ...styles.label, color: currentThemeColor.primaryTextColor }}>{t("App_Language")}</Text>
                        <SelectList 
                            placeholder={placeholderLangLabel}
                            data={Languages} 
                            setSelected={(selectedItem, index) =>{
                                const langValue = Languages.find(item => item.value === selectedItem)?.storedValue;
                                 setNewLang(langValue)}} 
                            boxStyles={styles.btnLang} 
                            dropdownTextStyles={styles.btnText} 
                            arrowicon={<Image source={arrowDown} style={{ width: 25, height: 25, left: '20%' }} />} 
                            closeicon={<Image source={arrowUp} style={{ width: 25, height: 25 }} />}
                            dropdownStyles={{ backgroundColor: '#fff', borderColor: '#fff', width: 110, maxWidth: 110 }} 
                            dropdownItemStyles={{ backgroundColor: '#fff', width: 110, maxWidth: 110 }} 
                            search={false} 
                        />
                    </View>
                    {!user?.is_social_media_login&&subUser?.is_main_profile&&<View style={{ ...styles.imgDiv, flexDirection }}>
                        <Text style={{ ...styles.label, color: currentThemeColor.primaryTextColor }}>{t("Reset_Password")}</Text>
                        <TouchableOpacity style={styles.btn} onPress={() => navigation.navigate('ChangePasswordScreen', {isMainPassword:true, subUserId: subUser?.id})} >
                            <Text style={styles.btnText}>{t("Reset")}</Text>
                        </TouchableOpacity>
                    </View>}
                    {accountType === 'family'&&<View style={{ ...styles.imgDiv, flexDirection }}>
                        <Text style={{ ...styles.label, color: currentThemeColor.primaryTextColor }}>{t("Reset_sub_password")}</Text>
                        {<TouchableOpacity style={[styles.btn, isWeb&&{width: 110}]} onPress={() => navigation.navigate('ChangePasswordScreen', {isMainPassword:false, subUserId: subUser?.id})} >
                            <Text style={styles.btnText}>{t("Reset")}</Text>
                        </TouchableOpacity>}
                    </View>}
                </View>

                <Modal visible={modalVisible} animationType="slide" transparent={true} onRequestClose={() => setModalVisible(false)}>
                <Pressable style={{ flex: 2, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.5)' }} onPress={() => { setModalVisible(false) }}>
                    <View style={{ backgroundColor: 'white', padding: 20, borderRadius: 10, width: '80%', height: '40%', justifyContent: 'space-around' }}>

                        < Text style={{ fontFamily: FONTFAMILY, textAlign: 'center', color: '#000', fontSize: 20, }}>{t('Are_sure_want_password')}</Text>
                        <View style={mainStyle.qDiv3Sub} >
                            <CustomButton btnStyle={{ width: '48%', backgroundColor: Colors.btnColor }} text={t('yes')} onPress={() => { submitReset() }} />
                            <CustomButton btnStyle={{ width: '48%', backgroundColor: Colors.secondary }} text={t('no')} onPress={() => { setModalVisible(false) }} />
                        </View>


                    </View>
                    <Image source={branch} style={mainStyle.branch} />
                    <Image source={panda2} style={mainStyle.panda2} />

                </Pressable>
            </Modal >
            <CustomAlert title={alertData?.message} type={alertData?.type} isVisible={!!alertData} onHide={hideAlert} />

            </Fragment>
        )
    }

    return (
        isWeb ? 
        <WebBackgroundTopColor >
            {pageContent()}
            <Image source={branch} style={mainStyle.branch} />
                <Image source={panda2} style={mainStyle.panda2} />
        </WebBackgroundTopColor>

         : <View style={mainStyle.mainContainer}>
            <ImageBackground source={currentTheme === 'dark' ? back4Dark : back4} style={{ width: screenWidth, height: screenHeight }} resizeMode='cover'>
                <ScrollView>
                    {pageContent()}
                </ScrollView>
                <Image source={branch} style={mainStyle.branch} />
                <Image source={panda2} style={mainStyle.panda2} />
            </ImageBackground >
        </View >
    );
};

const styles = StyleSheet.create({
    inputsContainer: {
        width: isWeb ? screenWidth / 2 :'90%',
        alignSelf: 'center',
        paddingVertical: 90,
    },
    imgDiv: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        alignSelf: 'center',
        marginBottom: 20
    },
    label: {
        width: '65%',
        fontSize: 21,
        color: '#000',
        fontWeight: "400",
    },
    btn: {
        width: '35%',
        height: 36,
        padding: 2,
        borderRadius: 13,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: '#C6C5C5'
    },
    btnText: { color: '#000', fontSize: 15 },
    btnLang: {
        width: 110,
        height: 46,
        padding: 6,
        borderRadius: 13,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: '#C6C5C5',
        borderColor: '#C6C5C5',
    },
});

export default SettingsScreen;