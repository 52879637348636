import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { View, StyleSheet, ImageBackground, Image, Text, Modal, SafeAreaView, ScrollView } from 'react-native';
import { FONTFAMILY, currentPlatform, screenHeight, screenWidth } from '../../constants/Colors';
import back2 from '../../assets/background/back2.png';
import back2Dark from '../../assets/background/back2Dark.png';
import panda1 from '../../assets/panda/panda1.png';
import panda2 from '../../assets/panda/panda2.png';
import { mainStyle } from '../../mainstyle';
import CustomButton from '../../components/customButton';
import branch from '../../assets/panda/branch.png';
import { useAuth } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import globalConstants from '../../config/globalConstants';
import { post } from '../../WebService/RequestBuilder';
import { WebBackgroundTopColor } from '../../components/WebBackground';

const CodingIntroductionScreen = ({ navigation, route }) => {

    const { t } = useTranslation();
    const { currentTheme, currentThemeColor, lang } = useAuth();
    const [modalVisible, setModalVisible] = useState(false);
    const [tIndex, setTIndex] = useState(0);
    const [item, setItem] = useState({});

    const { introTutorials } = route.params;

    const nextQ = async () => {
        try {
            await post(globalConstants.user.setCodingTutorialsAsRead, {
                user_coding_introduction_id: item.id
            })

            setTIndex(prevIndex => prevIndex < introTutorials.length ? prevIndex + 1 : prevIndex);
        } catch (error) {
            console.log('error in setAsRead: ', error);
        }
    };

    useEffect(() => {
        if (tIndex < introTutorials.length) {
            const tutorial = introTutorials[tIndex];
            setItem(tutorial);
        } else {
            setModalVisible(true);
        }
    }, [tIndex]);

    // const randomNumber = useMemo(() => {
    //     const getRandomNumber = (min, max) => {
    //         return Math.floor(Math.random() * (max - min + 1)) + min;
    //     };
    //     return getRandomNumber(1, 16);
    // }, []);

    const nextScreen = () => {
        setModalVisible(false);
        // setTimeout(() => {
        //     // navigation.navigate('FirstGameScreen', { randomNumber });
        //     navigation.navigate('GameStack');
        // }, 300);
        navigation.navigate('SetCourseScreen', {checkRoute:true})
    }
    
    const pageContent = () => {
        return (
            <Fragment>
                <View style={mainStyle.introductionMainDiv}>
                    <View style={{ ...mainStyle.qDiv1, justifyContent: 'space-around' }}>
                        <Text style={{ ...mainStyle.qDiv1Style, color: currentThemeColor.primaryTextColor }}>{item.title}</Text>
                    </View>
                    <View style={{ ...mainStyle.qDiv2, justifyContent: 'space-around' }}>
                        <Text style={{ ...mainStyle.qDiv2Style, color: currentThemeColor.primaryTextColor }}>{item.tutorial}</Text>
                    </View>
                    <View style={{ ...mainStyle.qDiv3, height: '40%' }}>
                        <CustomButton btnStyle={{ width: currentPlatform === 'web' ? '75%' : '96%', marginTop: 50 }} text={t('next')} onPress={nextQ} />
                    </View>
                </View>
            <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
                coverScreen={false}
                onSwipeComplete={() => setModalVisible(false)}
            >
                <View style={styles.ModalContainer}>
                    <View style={styles.ModalView}>
                        <View>
                            <Text style={styles.hText}>{t("Lets_start_learning")}</Text>
                            <Text style={styles.pText}>{t("Lets_start_learning_in_deep")}</Text>
                        </View>
                        <CustomButton isSubmit btnStyle={{ width: '96%', marginTop: 50 }} text={t("go_to_home")} onPress={() => {nextScreen()}} />
                        <Image source={branch} style={mainStyle.branch} />
                    </View>
                </View>
            </Modal>
            </Fragment>
        )
    }

    return (
        currentPlatform === 'web' ? (
                <WebBackgroundTopColor>
                    {pageContent()}
                    <View style={{ flex: 1 }}>
                        <Image source={panda1} style={mainStyle.panda1} />
                        <Image source={panda2} style={mainStyle.panda2} />
                    </View>
                </WebBackgroundTopColor>
            ) : <View style={mainStyle.mainContainer}>
                <ImageBackground source={currentTheme == 'dark' ? back2Dark : back2} style={{ width: screenWidth, height: screenHeight }} resizeMode='cover'>
                    <ScrollView>
                        {pageContent()}
                    </ScrollView>
                    <View style={{ flex: 1 }}>
                        <Image source={panda1} style={mainStyle.panda1} />
                        <Image source={panda2} style={mainStyle.panda2} />
                    </View>
                </ImageBackground>
            </View>
    );
}

const styles = StyleSheet.create({
    ModalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#0707078c',
    },
    ModalView: {
        backgroundColor: "white",
        borderRadius: 20,
        width: currentPlatform === 'web' ? '75%' : '90%',
        paddingVertical: 40,
        paddingHorizontal: '6%',
        alignItems: "center",
        justifyContent: 'center',
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5
    },
    check: {
        backgroundColor: "#3da2f5",
        width: 70,
        height: 70,
        marginBottom: 30,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 99,
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 11, },
        shadowOpacity: 0.57,
        shadowRadius: 15.19,
        elevation: 19,
    },
    hText: {
        fontFamily: FONTFAMILY,
        color: '#000',
        textAlign: 'center',
        fontSize: 22,
        marginBottom: 20,
    },
    pText: {
        fontFamily: FONTFAMILY,
        color: '#000',
        textAlign: 'center',
        fontSize: 16,
    }
})

export default CodingIntroductionScreen