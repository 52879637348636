import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React, { useEffect, useState } from 'react';
import HomeScreen from '../screens/App/HomeStack/HomeScreen';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { Image, StyleSheet, Text, View } from 'react-native';
import { BOTTOMBARHEIGHT, Colors } from '../constants/Colors';

import home from '../assets/panda/home.png';
import rank from '../assets/icons/rank.png';
import progress from '../assets/icons/progress.png';
import Settings from '../assets/icons/Settings.png';
import MainSettingsScreen from '../screens/App/SettingsStack/MainSettingsScreen';
import TopRatedScreen from '../screens/App/HomeStack/TopRatedScreen';
import UserProgressScreen from '../screens/App/HomeStack/UserProgressScreen';

const BottomTabNavigator = () => {
    const Tab = createBottomTabNavigator();

    return (
        <Tab.Navigator screenOptions={{
            tabBarStyle: {
                position: 'absolute',
                backgroundColor: Colors.secondary,
                height: BOTTOMBARHEIGHT,
                shadowColor: "#000",
                shadowOffset: { width: 0, height: 12, },
                shadowOpacity: 0.58,
                shadowRadius: 16.00,
                elevation: 24,
            },
        }} initialRouteName='HomeScreen'>
            <Tab.Screen name="HomeScreen" component={HomeScreen}
                options={{
                    tabBarShowLabel: false,
                    tabBarIcon: ({ focused }) => (
                        <View style={{ height: '100%', alignItems: 'center', justifyContent: 'center', width: '25%', }}>
                            {/* <Icon name="home" color={'#748c94'} size={30} style={{ marginHorizontal: 2 }} /> */}
                            <Image source={home} style={{ width: 55, height: 55, alignSelf: 'center' }} resizeMode='contain' />
                            {focused && <View style={{ width: 55, alignSelf: 'center', height: 1, marginTop: 5, top: 0, backgroundColor: Colors.tertiary }} />}
                        </View>
                    ),
                    headerShown: false,
                }} />
            <Tab.Screen name="TopRatedScreen" component={TopRatedScreen}
                options={{
                    tabBarShowLabel: false,
                    tabBarIcon: ({ focused }) => (
                        <View style={{ height: '100%', alignItems: 'center', justifyContent: 'center', }}>
                            {/* <Icon name="home" color={'#748c94'} size={30} style={{ marginHorizontal: 2 }} /> */}
                            <Image source={rank} style={{ width: 55, height: 55, }} resizeMode='contain' />
                            {focused && <View style={{ width: 55, alignSelf: 'center', height: 1, marginTop: 5, top: 0, backgroundColor: Colors.tertiary }} />}
                        </View>
                    ),
                    headerShown: false,
                }} />
            <Tab.Screen name="UserProgressScreen" component={UserProgressScreen}
                options={{
                    tabBarShowLabel: false,
                    tabBarIcon: ({ focused }) => (
                        <View style={{ height: '100%', alignItems: 'center', justifyContent: 'center', }}>
                            {/* <Icon name="home" color={'#748c94'} size={30} style={{ marginHorizontal: 2 }} /> */}
                            <Image source={progress} style={{ width: 55, height: 55, }} resizeMode='contain' />
                            {focused && <View style={{ width: 55, alignSelf: 'center', height: 1, marginTop: 5, top: 0, backgroundColor: Colors.tertiary }} />}
                        </View>
                    ),
                    headerShown: false,
                }} />
            <Tab.Screen name="MainSettingsScreen" component={MainSettingsScreen}
                options={{
                    tabBarShowLabel: false,
                    tabBarIcon: ({ focused }) => (
                        <View style={{ height: '100%', alignItems: 'center', justifyContent: 'center', }}>
                            {/* <Icon name="home" color={'#748c94'} size={30} style={{ marginHorizontal: 2 }} /> */}
                            <Image source={Settings} style={{ width: 60, height: 60, }} resizeMode='contain' />
                            {focused && <View style={{ width: 55, alignSelf: 'center', height: 1, marginTop: 5, top: 0, backgroundColor: Colors.tertiary }} />}
                        </View>
                    ),
                    headerShown: false,
                }} />
        </Tab.Navigator>
    );
};

export default BottomTabNavigator;


const styles = StyleSheet.create({
    Div1: {
        width: '100%',
        height: 170,
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center'
    },
})