import React, { Fragment, useEffect, useState } from 'react';
import { View, StyleSheet, ImageBackground, Image, Text, TouchableOpacity, SafeAreaView, ScrollView } from 'react-native';
import { currentPlatform, screenHeight, screenWidth } from '../../constants/Colors';
import back2 from '../../assets/background/back2.png';
import back2Dark from '../../assets/background/back2Dark.png';
import panda1 from '../../assets/panda/panda1.png';
import Input from '../../components/input';
import CustomButton from '../../components/customButton';
import { mainStyle } from '../../mainstyle';
import Header from '../../components/header';
import { useAuth } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import CustomAlert from '../../components/customAlert';
import { WebBackgroundTopColor } from '../../components/WebBackground';
import panda3 from '../../assets/panda/panda3.png';

const EmailVerify = ({ navigation, route }) => {
    const { t } = useTranslation();
    const { currentTheme, currentThemeColor, resendOTP, EmailVerifyOTP } = useAuth();
    const [otpCode, setOtpCode] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState({ otpEm: false });
    const [showAlert, setShowAlert] = useState(false);
    const [showAlerterror, setshowAlerterror] = useState(false);
    const handleHideAlert = () => {
        setShowAlert(false);
        setshowAlerterror(false)
    };

    useEffect(() => {
        const { pre, res } = route?.params ?? {};
        if (pre && res) {
            setEmail(pre === 'reg' ? res.emailAddress : res.user.email);
        }
    }, [route]);

    const resendOTPFun = async () => {
        if (email) {
            try {
                await resendOTP(email);
                setShowAlert(true)
            } catch (error) {
                console.log('resendOTPFun Error:', error);
                setshowAlerterror(true)
            }
        }
    };

    const handleSubmit = async () => {
        if (!otpCode) {
            setError(prevErrors => ({ ...prevErrors, otpEm: true }));
        } else {
            try {
                const res = await EmailVerifyOTP(email, otpCode);
                if (res.status) navigation.navigate('SetCourseStack');
            } catch (error) {
                console.log('handleSubmit OTP Error:', error);
            }
        }
    };

    const pageContent = () => {
        return (
            <Fragment>
                <Header title={t('Email_Verify')} />
                <Image source={panda3} style={mainStyle.pandaHeader} />

                <View style={styles.inputsContainer}>
                    <Input
                        lable={t('One_Time_Password')}
                        placeholder={t('Enter_OTP')}
                        onChange={setOtpCode}
                        subText={t('Enter_the_otp_sent_to_your_email_address')}
                        keyboardType='decimal-pad'
                    />
                    {error.otpEm && <Text style={styles.error}>{t('Make_sure_to_fill_the_OTP')}</Text>}
                    <TouchableOpacity onPress={resendOTPFun}>
                        <Text style={{ ...styles.resendText, color: currentThemeColor.primaryTextColor, }}>{t('Didnt_get_OTP_resend')}</Text>
                    </TouchableOpacity>
                    <CustomButton btnStyle={styles.button} text={t('Submit')} onPress={handleSubmit} />
                </View>
                <CustomAlert title={t("OTP_has_been_sent_Successfully")} isVisible={showAlert} onHide={handleHideAlert} />
                <CustomAlert title={t("OTP_is_Already_been_sent")} type='error' isVisible={showAlerterror} onHide={handleHideAlert} />
            </Fragment>
        )
    }

    return (
        currentPlatform === 'web' ? (
                <WebBackgroundTopColor>
                    {pageContent()}
                    <View style={styles.imageContainer}>
                        <Image source={panda1} style={mainStyle.panda1} />
                    </View>
                </WebBackgroundTopColor>
            ) : <View style={styles.container}>
                <ImageBackground source={currentTheme === 'dark' ? back2Dark : back2} style={styles.backgroundImage} resizeMode='cover'>
                    <ScrollView>
                    {pageContent()}
                    </ScrollView>
                    <View style={styles.imageContainer}>
                <Image source={panda1} style={mainStyle.panda1} />
                </View>
                </ImageBackground>
            </View>
        
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    backgroundImage: {
        width: screenWidth,
        height: screenHeight,
    },
    inputsContainer: {
        width: currentPlatform === 'web' ? screenWidth / 2 : '90%',
        alignSelf: 'center',
        paddingVertical: 20,
        paddingTop: currentPlatform === 'web' ? 60 : 80
    },
    error: {
        color: 'red',
        fontFamily: 'FONTFAMILY',
        fontSize: 16,
        textAlign: 'center',
        marginVertical: 10,
    },
    resendText: {
        fontFamily: 'FONTFAMILY',
        fontSize: 15,
        fontWeight: '600',
        marginTop: 15,
    },
    button: {
        marginTop: 15,
    },
    imageContainer: {
        flex: 1,
    },
});

export default EmailVerify;
