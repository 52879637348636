import { StyleSheet, View } from 'react-native';
import React from 'react';
import Svg, { Ellipse, G, Circle, Line, Defs, LinearGradient, Stop, Path } from 'react-native-svg';
import { createAnimatableComponent } from 'react-native-animatable';
import { colors } from '../../constants/Colors';

const AnimatableCircle = createAnimatableComponent(Circle);
const AnimatableEllipse = createAnimatableComponent(Ellipse);
const AnimatablePath = createAnimatableComponent(Path);

const FlowerFigure = ({ wrongWord }) => {

  const petalsLeft = 5 - wrongWord;

  const Stem = (
    <AnimatablePath
      animation="fadeIn"
      duration={1000}
      d="M150 200 Q160 300 150 400"
      stroke="green"
      strokeWidth="5"
      fill="transparent"
    />
  );

  const Leaves = (
    <G>
      <Defs>
        <LinearGradient id="leafGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <Stop offset="0%" stopColor="lightgreen" />
          <Stop offset="100%" stopColor="green" />
        </LinearGradient>
      </Defs>
      <AnimatableEllipse
        animation="fadeIn"
        duration={1000}
        cx="130"
        cy="300"
        rx="20"
        ry="40"
        fill="url(#leafGradient)"
        rotation="30"
        origin="130, 300"
      />
      <AnimatableEllipse
        animation="fadeIn"
        duration={1000}
        cx="170"
        cy="300"
        rx="20"
        ry="40"
        fill="url(#leafGradient)"
        rotation="-30"
        origin="170, 300"
      />
    </G>
  );

  const Petals = (
    <G>
      <Defs>
        <LinearGradient id="petalGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <Stop offset="0%" stopColor="pink" />
          <Stop offset="100%" stopColor="deeppink" />
        </LinearGradient>
        <LinearGradient id="centerGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <Stop offset="0%" stopColor="yellow" />
          <Stop offset="100%" stopColor="gold" />
        </LinearGradient>
      </Defs>
      <AnimatableEllipse
        animation={petalsLeft >= 5 ? 'fadeIn' : 'fallWithWind'}
        duration={4000}
        easing="ease-in-out"
        cx="150"
        cy="70"
        rx="40"
        ry="60"
        fill="url(#petalGradient)"
        rotation="0"
        origin="150, 120"
        stroke="deeppink"
        strokeWidth="2"
      />
      <AnimatableEllipse
        animation={petalsLeft >= 4 ? 'fadeIn' : 'fallWithWind'}
        duration={4000}
        easing="ease-in-out"
        cx="150"
        cy="170"
        rx="40"
        ry="60"
        fill="url(#petalGradient)"
        rotation="0"
        origin="150, 120"
        stroke="deeppink"
        strokeWidth="2"
      />
      <AnimatableEllipse
        animation={petalsLeft >= 3 ? 'fadeIn' : 'fallWithWind'}
        duration={4000}
        easing="ease-in-out"
        cx="100"
        cy="120"
        rx="60"
        ry="40"
        fill="url(#petalGradient)"
        rotation="0"
        origin="150, 120"
        stroke="deeppink"
        strokeWidth="2"
      />
      <AnimatableEllipse
        animation={petalsLeft >= 2 ? 'fadeIn' : 'fallWithWind'}
        duration={4000}
        easing="ease-in-out"
        cx="200"
        cy="120"
        rx="60"
        ry="40"
        fill="url(#petalGradient)"
        rotation="0"
        origin="150, 120"
        stroke="deeppink"
        strokeWidth="2"
      />
      <AnimatableCircle
        animation={petalsLeft >= 1 ? 'fadeIn' : 'fallWithWind'}
        duration={4000}
        easing="ease-in-out"
        cx="150"
        cy="120"
        r="30"
        fill="url(#centerGradient)"
        stroke="orange"
        strokeWidth="2"
      />
    </G>
  );

  return (
    <View style={styles.container}>
      <Svg version="1.1" viewBox="0 0 300 400" preserveAspectRatio="xMinYMin meet" width="140" height="200">
        {Stem}
        {Leaves}
        {Petals}
      </Svg>
    </View>
  );
};

export default FlowerFigure;

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});


import * as Animatable from 'react-native-animatable';

Animatable.initializeRegistryWithDefinitions({
  fallWithWind: {
    0: { translateY: 0, translateX: 0, opacity: 1 },
    0.25: { translateY: 20, translateX: 15, opacity: 0.9 },
    0.5: { translateY: 80, translateX: -15, opacity: 0.8 },
    0.75: { translateY: 150, translateX: 15, opacity: 0.6 },
    1: { translateY: 300, translateX: -30, opacity: 0 },
  },
});
