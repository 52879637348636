import React, { useState, useEffect } from "react";
import { Image, ScrollView, Text, TouchableOpacity, View, StyleSheet } from "react-native";
import { sharingGameStyle } from "../sharingGameStyle";
import leftArrowW from '../assets/game/leftArrowW.png';
import rightArrowW from '../assets/game/rightArrowW.png';
import AutoTypingText from 'react-native-auto-typing-text';
import { isIos } from "../constants/Colors";

const GameModalView = ({ title, body, style, isOpen, action = () => {}, lang='en' }) => {
    const [finishTyping, setFinishTyping] = useState(false);
    const [key, setKey] = useState(0);

    useEffect(() => {
        setFinishTyping(false);
        setKey(prevKey => prevKey + 1);
    }, [title, body]);

    if (!isOpen) return null;

    return (
        <View style={[StyleSheet.absoluteFill, { justifyContent: 'center', alignItems: 'center', zIndex: 999 }]}>
            <TouchableOpacity style={[StyleSheet.absoluteFill, { backgroundColor: 'rgba(0, 0, 0, 0.5)' }]} activeOpacity={1} />
            <View style={[sharingGameStyle.modalContainer, style]}>
                {title && <Text style={sharingGameStyle.modelHeader}>{title}</Text>}
                <ScrollView style={sharingGameStyle.modalScroll} showsVerticalScrollIndicator={false}>
                    <View style={sharingGameStyle.modalContent}>
                        {isIos ? <Text style={sharingGameStyle.modalText} key={key}> {body} </Text> : <AutoTypingText
                            key={key}
                            text={body}
                            charMovingTime={80}
                            delay={0}
                            style={sharingGameStyle.modalText}
                            onComplete={() => { setFinishTyping(true) }}
                        />}
                    </View>
                </ScrollView>
                {(finishTyping || isIos) &&
                    <TouchableOpacity onPress={action} style={[sharingGameStyle.arrowContainer, lang === 'ar' && { alignSelf: 'flex-start' }]}>
                        <Image source={lang === 'ar' ? rightArrowW : leftArrowW} style={sharingGameStyle.arrow} />
                    </TouchableOpacity>
                }
            </View>
        </View>
    );
}

export default GameModalView;
