import React from 'react';
import { View, StyleSheet, Image } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import shape from '../assets/webBackground/shape.png'
import shapeWhite from '../assets/webBackground/shapeWhite.png'

// import backgroundLight from '../assets/webBackground/lightMode.png'
// import backgroundDark from '../assets/webBackground/darkMode.png'


import { screenHeight, screenWidth } from '../constants/Colors';
import { SafeAreaView } from 'react-native-web';
import { useAuth } from '../contexts/AuthContext';


export const WebBackgroundTopColor = ({children, elementOnImage, customHightHeader}) => {
    const { currentTheme, currentThemeColor, lang } = useAuth();

    return (
        <SafeAreaView style={[styles.mainDiv, {backgroundColor: currentThemeColor?.webMainBackground}]}>
            <View style={[styles.topDiv2, {backgroundColor: currentThemeColor?.webBackground}, customHightHeader&&{minHeight: customHightHeader}]}>
                {elementOnImage}
            </View>
            <Svg style={[styles.svgBackground, customHightHeader&&{top: customHightHeader}]} viewBox="0 0 1200 120">
                <Path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" fill={currentThemeColor?.webBackground} fillOpacity="1" />
            </Svg>
            <Image source={currentTheme == 'dark' ? shapeWhite : shape} style={styles.shape} />
            <Image source={currentTheme == 'dark' ? shapeWhite : shape} style={styles.shape2} />

            {children}
        </SafeAreaView>
    );
}

export const WebBackgroundBottomColor = ({children, elementOnImage}) => {
    const { currentTheme, currentThemeColor, lang } = useAuth();

    return (
        <SafeAreaView style={[styles.mainDiv2, {backgroundColor: currentThemeColor?.webBackground}]}>
            <View style={[styles.topDiv2B, {backgroundColor: currentThemeColor?.webMainBackground}]}>
                {elementOnImage}
            </View>
            <Svg style={styles.svgBackground2} viewBox="0 0 1200 120">
                <Path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" fill={currentThemeColor?.webMainBackground} fillOpacity="1" />
            </Svg>
            <Image source={currentTheme == 'dark' ? shapeWhite : shape} style={styles.shape} />
            <Image source={currentTheme == 'dark' ? shapeWhite : shape} style={styles.shape2} />
            {children}
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    mainDiv: {
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        zIndex: 0,
        maxWidth: screenWidth,
        minHeight: screenHeight,
        alignContent: 'center',
    },
    topDiv: {
        backgroundColor: 'transparent',
        zIndex: 0,
        // width: '100%',
        minHeight: 160,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
    },
    topDiv2: {
        backgroundColor: 'transparent',
        zIndex: 0,
        width: '100%',
        minHeight: 160,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
    },
    svgBackground: {
        position: 'absolute',
        top: 160,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
    },
    shape: {
        position: 'absolute',
        width: 200,
        height: 200,
        top: 25,
        right: 0,
        resizeMode: 'contain',
        zIndex: 0,
        overflow: 'hidden',
    },
    shape2: {
        position: 'absolute',
        width: 200,
        height: 200,
        top: 50,
        left: -100,
        resizeMode: 'contain',
        zIndex: 0
    },

    mainDiv2: {
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        zIndex: 0,
        maxWidth: screenWidth,
        minHeight: screenHeight,
    },
    topDiv2: {
        backgroundColor: 'transparent',
        zIndex: 0,
        // width: '100%',
        minHeight: 160,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
    },
    topDiv2B: {
        backgroundColor: 'transparent',
        zIndex: 0,
        width: '100%',
        minHeight: 350,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
    },
    svgBackground2: {
        position: 'absolute',
        top: 350,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
    },
});
