import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';
import LoginScreen from '../screens/Auth/LoginScreen';
import SignupScreen from '../screens/Auth/SignupScreen';
import ForgotPasswordScreen from '../screens/Auth/ForgotPasswordScreen';
import OnboardingScreen from '../screens/Auth/onboardingScreen';
import { StatusBar } from 'react-native';
import { Colors } from '../constants/Colors'; 
import EmailVerify from '../screens/Auth/emailVerify';
import CheckUserExperienceScreen from '../screens/setCourse/CheckUserExperienceScreen';
import VerifyOtpScreen from '../screens/Auth/VerifyOtpScreen';
import SetNewPasswordScreen from '../screens/Auth/SetNewPasswordScreen';

const AuthStack = ({ route }) => {

    const Stack = createNativeStackNavigator();

    return (
        <>
            <StatusBar barStyle="default" backgroundColor={Colors.primary2} />
            <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName='OnboardingScreen'>
                <Stack.Screen name="OnboardingScreen" component={OnboardingScreen} />
                <Stack.Screen name="LoginScreen" component={LoginScreen} />
                <Stack.Screen name="SignupScreen" component={SignupScreen} />
                <Stack.Screen name="ForgotPasswordScreen" component={ForgotPasswordScreen} /> 
                <Stack.Screen name="EmailVerify" component={EmailVerify} />
                <Stack.Screen name="CheckUserExperienceScreen" component={CheckUserExperienceScreen} />
                <Stack.Screen name="VerifyOtpScreen" component={VerifyOtpScreen} />
                <Stack.Screen name="SetNewPasswordScreen" component={SetNewPasswordScreen} />
            </Stack.Navigator>
        </>
    );
};

export default AuthStack;
