import { useTranslation } from "react-i18next";
import { useAuth } from "../../../contexts/AuthContext";
import { mainStyle } from "../../../mainstyle";
import { Image, ImageBackground, KeyboardAvoidingView, ScrollView, StyleSheet, View } from "react-native";
import { useCallback, useState } from "react";
import CustomAlert from "../../../components/customAlert";
import back4 from '../../../assets/background/back4.png';
import back4Dark from '../../../assets/background/back4Dark.png';
import panda2 from '../../../assets/panda/panda2.png';
import branch from '../../../assets/panda/branch.png';
import Header from "../../../components/header";
import Input from "../../../components/input";
import { isWeb, screenHeight, screenWidth } from "../../../constants/Colors";
import CustomButton from "../../../components/customButton";
import { post } from "../../../WebService/RequestBuilder";
import globalConstants from "../../../config/globalConstants";
import { WebBackgroundTopColor } from "../../../components/WebBackground";

const ChangePasswordScreen = ({ navigation, route }) => {
    const { t } = useTranslation();
    const { currentTheme, currentThemeColor, lang, login } = useAuth();

    const {isMainPassword, subUserId} = route?.params;

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');


    const [alertData, setAlertData] = useState(null);
    const showAlert = useCallback((message, type) => { setAlertData({ message, type }); }, []);
    const hideAlert = useCallback(() => { setAlertData(null); }, []);

    const handleSubmit = async () => {
        try {
            if(confirmPassword !== newPassword) {
                showAlert(t('Password_should_match'), 'error')
            } else {
                if(isMainPassword) {
                    await post(globalConstants.user.changePasswordMainUser, {
                        old_password: oldPassword,
                        password: newPassword
                    }).then(() => {
                        showAlert(t('Password_changed_successfully'), 'success')
                        setTimeout(() => {
                            navigation.goBack();
                        }, 2000);
                    })
                } else {
                    await post(globalConstants.user.changeProfilePassword, {
                        sub_password: newPassword,
                        old_sub_password: oldPassword,
                        user_info_id: subUserId
                    }).then(() => {
                        showAlert(t('Password_changed_successfully'), 'success')
                        setTimeout(() => {
                            navigation.goBack();
                        }, 2000);
                    })
                }
            }
        } catch (error) {
            showAlert(t(error?.message), 'error')
        }
    }

    const pageContent = () => {
        return (
            <>
                <KeyboardAvoidingView>
                {!isWeb&&<Header title={t('Reset_Password')} />}
                <View style={styles.inputsContainer}>

                <Input
                    lable={t('Current_password')}
                    placeholder={t('Current_password')}
                    onChange={setOldPassword}
                    value={oldPassword}
                    type="password"
                    secureTextEntry
                    maxLength={!isMainPassword ? 4 : null}
                    keyboardType={!isMainPassword ? 'numeric' : 'default'}
                />
                <Input
                    lable={t('New_password')}
                    placeholder={t('New_password')}
                    secureTextEntry
                    onChange={setNewPassword}
                    type="password"
                    mainDivStyle={{ marginBottom: 0 }}
                    value={newPassword}
                    maxLength={!isMainPassword ? 4 : null}
                    keyboardType={!isMainPassword ? 'numeric' : 'default'}
                />
                <Input
                    lable={t('Confirm_password')}
                    placeholder={t('Confirm_password')}
                    secureTextEntry
                    onChange={setConfirmPassword}
                    type="password"
                    mainDivStyle={{ marginBottom: 0 }}
                    value={confirmPassword}
                    maxLength={!isMainPassword ? 4 : null}
                    keyboardType={!isMainPassword ? 'numeric' : 'default'}
                />

                <CustomButton
                    btnStyle={{ marginTop: 15 }}
                    text={t('Reset')}
                    onPress={handleSubmit}
                />
                </View>

                </KeyboardAvoidingView>
                <CustomAlert title={alertData?.message} type={alertData?.type} isVisible={!!alertData} onHide={hideAlert} />
            </>
        )
    }

    return(
        isWeb ? <WebBackgroundTopColor >
            <Header title={t('Reset_Password')} />
                {pageContent()}
            <Image source={branch} style={mainStyle.branch} />
            <Image source={panda2} style={mainStyle.panda2} />
    </WebBackgroundTopColor> : <View style={mainStyle.mainContainer}>
            <ImageBackground source={currentTheme === 'dark' ? back4Dark : back4} style={{ width: screenWidth, height: screenHeight }} resizeMode='cover'>
                <ScrollView>
                    {pageContent()}
                </ScrollView>
                <Image source={branch} style={mainStyle.branch} />
                <Image source={panda2} style={mainStyle.panda2} />
            </ImageBackground >

        </View >
    )
}

const styles = StyleSheet.create({
    inputsContainer: {
        width: isWeb ? '50%' : '90%',
        marginTop: isWeb ? 50 : 0,
        justifyContent: 'center',
        alignSelf: 'center',
        alignItems: 'center',
        paddingVertical: 20,
        height: screenHeight - 200,
    },
});

export default ChangePasswordScreen;