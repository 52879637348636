import axios from 'axios';
import i18n from '../i18n/i18n';
import { currentPlatform, isWeb } from '../constants/Colors';
import { Alert } from 'react-native';
import { EXPO_PUBLIC_BASE_URI } from '@env';

export const baseURL = EXPO_PUBLIC_BASE_URI;


let current_token = '';

const apiClient = axios.create({
    baseURL: baseURL,
    timeout: 5000,
});

let activeRequests = 0;
let isLoading = false;

const showLoadingIndicator = () => {
    if (activeRequests === 0) {
        isLoading = true;
    }
    activeRequests++;
};

const hideLoadingIndicator = () => {
    activeRequests--;
    if (activeRequests === 0) {
        isLoading = false;
    }
};

apiClient.interceptors.request.use(
    config => {
        showLoadingIndicator();
        config.headers['Accept'] = 'application/json';
        config.headers['X-Header-Language'] = i18n.language;
        config.headers['Content-Type'] = "application/json"; // 'application/x-www-form-urlencoded'; 
        // console.log('----------API config Start------');
        // console.log(config);
        // console.log('----------API config End------');
        return config;
    },
    error => {
        // console.log("error config in", error);
        hideLoadingIndicator();
        return Promise.reject(error);
    },
);

apiClient.interceptors.response.use(
    response => {
        // console.log('----------API Response Start------');
        // console.log(response.data);
        // console.log('----------API Response End------');
        hideLoadingIndicator();
        return response;
    },
    error => {
        // console.log("error apiClient in", error);
        hideLoadingIndicator();
        // Alert.alert('Error', error.message);
        return Promise.reject(error);
    },
);

const request = async (method, url, data = null, params = {}, timeout) => {
    try {
        const response = await apiClient.request({ method, url, data, params, timeout });
        return response.data;
    } catch (error) {
        throw new Error(error?.response?.data?.message || error.message);
    }
};

const createApiFunction = (method, timeout = 5000000) =>
    async (url, data = null, params = {}) => {
        const response = await request(method, url, data, params, timeout);
        return response;
    };

export const get = createApiFunction('get');
export const post = createApiFunction('post');
export const put = createApiFunction('put');
export const del = createApiFunction('delete');

// export const customGet = async (url, token, params = null) => {
//     try {
//         if(params) {
//             return axios({
//                 method: "get",
//                 url: `${baseURL}${url}`,
//                 params: params,
//                 headers: {
//                   Authorization: `Bearer ${token}`,
//                 },
//             }).then((response) => {
//             console.log('custom get', response.data);
//             return response.data;
//             });
//         } else {
//             setAuthToken(token);
//             const response = await apiClient.get(url);
//             return response.data;
//         }
//     } catch (error) {
//         throw new Error(error.response?.data?.message || error.message);
//     }
// };

export const customGet = async (url, params = null) => {
    try {
        const headers = {
            Authorization: current_token,
        };

        const response = await axios.get(`${baseURL}${url}`, {
            params: params,
            headers: headers,
        });

        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || error.message);
    }
};


export const uploadFile = async (url, imagedata, userInfoId) => {
    try {
        const headers = {
            Authorization: current_token,
            "Content-Type": "multipart/form-data",
          };
        const formData = new FormData();
        if(isWeb) {
            formData.append('image', imagedata.blob, imagedata.name);
        } else {
            formData.append(`image`, {
                uri: currentPlatform === 'ios' ? imagedata.uri : `file://${imagedata.uri}`,
                type: imagedata.mimeType,
                name: imagedata?.fileName ? imagedata?.fileName : 'image'
            });
        }

        formData.append(`user_info_id`, userInfoId);
        const response = await axios.post(`${baseURL}${url}`, formData, {
            headers: headers,
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || error.message);
    }
};

export const uploadFiles = async (url, files, body = {}, arrData = null) => {
    try {
        let formData;

        if (arrData) {
            formData = arrData;
        } else {
            formData = new FormData();
        }

        files.forEach((file, index) => {
            formData.append(`images[${index}]`, {
                uri: currentPlatform === 'ios' ? file.path : `file://${file.realPath}`,
                type: file.mime,
                name: file.fileName
            });
        });

        Object.entries(body).forEach(([key, value]) => {
            formData.append(key, value);
        });

        const response = await request('post', url, formData, null);
        return response;
    } catch (error) {
        throw new Error(error.response?.data?.message || error.message);
    }
};

export const getPage = async (url, current_page = 1, per_page = 10, param = {}, data = null,) => {
    const params = { ...param, current_page, per_page };
    const response = await get(url, data, params);
    return response;
};

export const setHeaders = headers => {
    Object.assign(apiClient.defaults.headers.common, headers);
};
export const setAuthToken = (token) => {
    current_token = `Bearer ${token}`;
    apiClient.defaults.headers.common['Authorization'] = current_token
};

export const clearAuthToken = () => {
    delete apiClient.defaults.headers.common['Authorization'];
};

export const isLoadingIndicatorVisible = () => {
    return isLoading;
};