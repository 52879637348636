import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Colors } from '../constants/Colors';
import BottomTabNavigator from './BottomTabNavigator';
import { StatusBar } from 'react-native';
import MultiUsersSelect from '../screens/Auth/multiUsersSelect';
import SubUserLogin from '../screens/Auth/subUserLogin';
import AddNewSubUser from '../screens/Auth/AddNewSubUser';

const MultiUserStack = () => {
    const Stack = createNativeStackNavigator();

    return (
        <>
            <StatusBar barStyle="default" backgroundColor={Colors.secondary2} />
            <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName={'MultiUsersSelect'}>
                <Stack.Screen name="MultiUsersSelect" component={MultiUsersSelect} />
                <Stack.Screen name="SubUserLogin" component={SubUserLogin} />
                <Stack.Screen name="AddNewSubUser" component={AddNewSubUser} />
                <Stack.Screen name="BottomTabNavigator" component={BottomTabNavigator} />
            </Stack.Navigator>
        </>
    );
};

export default MultiUserStack;