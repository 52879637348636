import {
  StyleSheet,
  View,
  TouchableOpacity,
  Text,
  SafeAreaView,
  Dimensions,
  KeyboardAvoidingView,
  Platform,
  ActivityIndicator
} from 'react-native';
import React, { useState, useReducer, useEffect } from 'react';
import Header from './Header';
import StatusPopup from './StatusPopup';
import FlowerFigure from './ManFigure';
import { colors } from '../../constants/Colors';
import FunctionEditor from './FunctionEditor';
import globalConstants from '../../config/globalConstants';
import { post } from '../../WebService/RequestBuilder';
import { useAuth } from '../../contexts/AuthContext';
import { t } from 'i18next';
import { ScrollView } from 'react-native';
import CheatModal from '../CheatModal';

const { width } = Dimensions.get('window');
const scale = width < 375 ? width / 375 : 1;

const Index = () => {
  const [wrongLetters, setWrongLetters] = useState([]);
  const [status, setStatus] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentLevel, setCurrentLevel] = useState('easy');
  const [hintVisible, toggleHint] = useReducer((s) => !s, false);
  const [flowerKey, setFlowerKey] = useState(0);
  const [inputValue, setInputValue] = useState('');
  const [cheat, setCheat] = useState(false);
  const { currentCourseName, lang } = useAuth();
  const [fetchQuestion, setfetchQuestion] = useState([]);
  const [loadingAnswerCheck, setLoadingAnswerCheck] = useState(false);
  const [loadingQuestionGeneration, setLoadingQuestionGeneration] = useState(false);


  const question = fetchQuestion?.[currentIndex]?.question;
  const statusColor = fetchQuestion?.[currentIndex]?.status;

  const fetchQuestions = async () => {
    try {
      setLoadingQuestionGeneration(true);
      const { data } = await post(globalConstants.chatGPT.getQuestions, {
        topic: currentCourseName,
        language: lang,
        previousQuestions: fetchQuestion ? fetchQuestion : [],
      });
      setfetchQuestion((prevQuestions) => [...prevQuestions, ...data]);
    } catch (error) {
      console.error('Error fetching questions:', error);
    } finally {
      setLoadingQuestionGeneration(false);
    }
  };

  useEffect(() => {
    if (!fetchQuestion.length || fetchQuestion.length <= currentIndex) {
      fetchQuestions();
    }
    if (fetchQuestion.length) {
      setCurrentLevel(fetchQuestion[currentIndex]?.level);
    }

  }, [currentIndex, fetchQuestion]);

  const storeWholeWord = async (wordInput) => {
    if (wordInput.trim() === '') {
      return;
    }
    try {
      setLoadingAnswerCheck(true);
      const { data } = await post(globalConstants.chatGPT.checkIfCorrect, {
        question: question,
        answer: wordInput,
      });

      if (data && data.correct) {
        setStatus('win');
        const updatedQuestions = fetchQuestion.filter((_, index) => index !== currentIndex);
        setfetchQuestion(updatedQuestions);

        if (updatedQuestions.length === 0) {
          setStatus('completed');
        } else if (currentIndex >= updatedQuestions.length) {
          setCurrentIndex(0);
        }
      } else {
        setWrongLetters((prev) => [...prev, wordInput]);
        if (wrongLetters.length + 1 > 3) {
          setStatus('lost');
        }
      }
    } catch (error) {
      console.error('Error checking the word:', error);
    } finally {
      setLoadingAnswerCheck(false);
    }
  };


  const handlePopupButton = () => {
    if (status === 'win') {
      setCurrentIndex((i) => (i + 1) % fetchQuestion.length);
    }

    setWrongLetters([]);
    setStatus('');
    setInputValue('');

    if (status === 'completed') {
      setCurrentIndex(0);
    }

    if (status === 'lost') {
      setFlowerKey((prevKey) => prevKey + 1);
    } else {
      setFlowerKey((prevKey) => prevKey + 1);
    }
  };

  const getLevelButtonStyle = () => {
    const backgroundColor = statusColor || 'gray';
    return { backgroundColor };
  };

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.header}>
        <Header />
        <TouchableOpacity style={styles.hintButton} onPress={toggleHint}>
          <Text style={styles.hintButtonText}>{!hintVisible ? t('show hint') : t('hide hint')}</Text>
        </TouchableOpacity>
        {wrongLetters.length === 3 && fetchQuestion.length > 0 && (
          <TouchableOpacity
            style={styles.cheatButton}
            onPress={() => setCheat(true)}
          >
            <Text style={styles.cheatButtonText}>{t('Cheat')}</Text>
          </TouchableOpacity>
        )}
      </View>
      {
        fetchQuestion.length > 0 &&
        < CheatModal
          cheatVisible={cheat}
          toggleHint={() => setCheat(false)}
          wordData={fetchQuestion[currentIndex]}
        />
      }
      <TouchableOpacity style={[styles.levelButton, getLevelButtonStyle()]}>
        <Text style={styles.hintButtonText}>
          {currentLevel && currentLevel}
        </Text>
      </TouchableOpacity>
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        keyboardVerticalOffset={Platform.OS === 'ios' ? 100 : 0}
      >
        <ScrollView>
          <FlowerFigure key={flowerKey} wrongWord={wrongLetters.length} />
          <View style={styles.height}>
            {loadingQuestionGeneration && (
              <>
                <ActivityIndicator size="large" color={colors.primary} style={styles.loader} />
                <Text style={styles.loadingText}>{t('Hold tight! Our AI is crafting some cool questions just for you')}</Text>
              </>
            )}
            {loadingAnswerCheck && (
              <>
                <ActivityIndicator size="large" color={colors.primary} style={styles.loader} />
                <Text style={styles.loadingText}>{t('Checking your answer with our AI superpowers')}</Text>
              </>
            )}
            {!loadingAnswerCheck && !loadingQuestionGeneration && fetchQuestion.length > 0 && (
              <FunctionEditor
                wordData={fetchQuestion[currentIndex]}
                onSubmit={storeWholeWord}
                hintVisible={hintVisible}
                setInputValue={setInputValue}
                inputValue={inputValue}
                toggleHint={toggleHint}
              />
            )}
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
      <StatusPopup status={status} onPress={handlePopupButton} />
    </SafeAreaView>
  );
};

export default Index;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: Platform.OS === 'web' ? 'auto' : 20 * scale,
    maxWidth: Platform.OS === 'web' ? 800 : '100%',
  },
  height: {
    minHeight: 250 * scale,
    width: '100%',
  },
  hintButton: {
    position: 'absolute',
    top: 20 * scale,
    right: Platform.OS === 'web' ? '10%' : 0,
    backgroundColor: colors.hintButton,
    paddingVertical: Platform.OS === 'web' ? 15 : 10 * scale,
    paddingHorizontal: Platform.OS === 'web' ? 20 : 10 * scale,
    borderRadius: 25 * scale,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    elevation: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  levelButton: {
    position: 'absolute',
    top: 20 * scale,
    left: 0,
    paddingVertical: 10 * scale,
    paddingHorizontal: 10 * scale,
    borderRadius: 25 * scale,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    elevation: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  cheatButton: {
    position: 'absolute',
    top: 70 * scale,
    right: Platform.OS === 'web' ? '10%' : 0,
    backgroundColor: 'red',
    paddingVertical: Platform.OS === 'web' ? 15 : 10 * scale,
    paddingHorizontal: Platform.OS === 'web' ? 20 : 10 * scale,
    marginTop: Platform.OS === 'web' ? 20 : 0,
    borderRadius: 25 * scale,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    elevation: 2,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  },
  cheatButtonText: {
    color: '#fff',
    fontSize: 16 * scale,
    fontWeight: '700',
  },
  hintButtonText: {
    color: colors.hintButtonText,
    fontSize: 16 * scale,
    fontWeight: '700',
  },
  loader: {
    marginVertical: 20 * scale,
  },
  loadingText: {
    textAlign: 'center',
    marginTop: 10 * scale,
    color: colors.primary,
    fontSize: 16 * scale,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 1,
    minWidth: Platform.OS === 'web' ? 600 : 'auto',
  }

});