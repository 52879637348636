import React, {useCallback, useEffect, useState} from 'react';
import { View, StyleSheet, ImageBackground, Image, TouchableOpacity, Text, ScrollView, KeyboardAvoidingView } from 'react-native';
import { Colors, FONTFAMILY, currentPlatform, screenHeight, screenWidth } from '../../constants/Colors';
import back2 from '../../assets/background/back2.png';
import back2Dark from '../../assets/background/back2Dark.png';
import panda1 from '../../assets/panda/panda1.png';
import panda9 from '../../assets/panda/panda9.png';
import panda92 from '../../assets/panda/panda9-2.png';
import facebook from '../../assets/icons/facebook.png';
import google from '../../assets/icons/google.png';
import Input from '../../components/input';
import CustomButton from '../../components/customButton';
import { mainStyle } from '../../mainstyle';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/AuthContext';
import * as Google from "expo-auth-session/providers/google";
import * as Facebook from "expo-auth-session/providers/facebook";
import { EXPO_PUBLIC_GOOGLE_ANDROID_CLIENT_ID, EXPO_PUBLIC_GOOGLE_WEB_CLIENT_ID, EXPO_PUBLIC_FACEBOOK_APP_ID, EXPO_PUBLIC_GOOGLE_IOS_CLIENT_ID } from '@env';
import * as WebBrowser from 'expo-web-browser';
import CustomLoader from "../../components/CustomLoader";
import { WebBackgroundTopColor } from '../../components/WebBackground';
import CustomAlert from '../../components/customAlert';


WebBrowser.maybeCompleteAuthSession();

const LoginScreen = ({ navigation }) => {
    const { t } = useTranslation();
    const { currentTheme, currentThemeColor, lang, login } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [alertData, setAlertData] = useState(null);

    const showAlert = useCallback((message, type) => { setAlertData({ message, type }); }, []);
    const hideAlert = useCallback(() => { setAlertData(null); }, []);

    const [formData, setFormData] = useState({
        username: '',
        password: '',
    });

    const [error, setError] = useState({
        allData: false,
        authentication: false,
        validEmail: false,
        somethingWentWrong: false
    });

    const [googleRequest, googleResponse, googlePromptAsync] = Google.useAuthRequest({
        androidClientId: EXPO_PUBLIC_GOOGLE_ANDROID_CLIENT_ID,
        webClientId: EXPO_PUBLIC_GOOGLE_WEB_CLIENT_ID,
        iosClientId: EXPO_PUBLIC_GOOGLE_IOS_CLIENT_ID,
    });
    
    const [facebookRequest, facebookResponse, facebookPromptAsync] = Facebook.useAuthRequest({
        clientId: EXPO_PUBLIC_FACEBOOK_APP_ID,
    });

    const handleOnPressFacebook = async () => {
        const result = await facebookPromptAsync();
        if(result?.type !== 'success') {
            return
        }
    }

    const handleOnPressGoogle = async () => {
        const result = await googlePromptAsync();
        if(result?.type !== 'success') {
            return
        }
    }

    useEffect(() => {
        if(facebookResponse?.type == 'success') {
            getFacebookUserInfo(facebookResponse?.authentication?.accessToken)    
        }
    }, [facebookResponse])

    const getFacebookUserInfo = async (token) => {
        try {
            setIsLoading(true)
            const userInfoResponse = await fetch(
                `https://graph.facebook.com/me?access_token=${token}&fields=id,name,picture.type(large)`
            );
    
            const user = await userInfoResponse.json();
    
            const body = {
                facebook_id: user?.id,
                name: user?.name,
                email: user?.email ? user?.email : `f${user?.id}@facebookId.com`,
            }
        
            const res = await login(body, true)
            navigateTo(res)
        } catch (error) {
            console.log(
                "Failed to fetch facebook user data:",
                error
              );
        } finally {
            setIsLoading(false)
        }
    }

    const getGoogleUserInfo = async (token) => {
        if (!token) return;
        try {
            setIsLoading(true)
          const response = await fetch(
            "https://www.googleapis.com/userinfo/v2/me",
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          const user = await response.json();
            const body = {
                google_id: user?.id,
                name: user?.name,
                email: user?.email,
            }

            const res = await login(body, true)
            navigateTo(res)
        } catch (error) {
          console.log(
            "Failed to fetch google user data:",
            error
          );
        } finally {
            setIsLoading(false)
        }
      };

    const handleSignInWithGoogle = async () => {
        if(googleResponse?.type === "success") {
            getGoogleUserInfo(googleResponse.authentication.accessToken);
        }
    }

    useEffect(() => {
        handleSignInWithGoogle();
    }, [googleResponse]);


    const handleInputChange = (field, value) => {
        setFormData({ ...formData, [field]: value });
    };

    const navigateTo = (res) => {
        if (res && res.isVerifyEmail && res.accountType === 'individual') {
            navigation.navigate('SetCourseStack', {checkRoute: true});
        } else if (res && res.isVerifyEmail && res.accountType === 'family') {
            navigation.navigate('MultiUserStack');
        } else if (res && !res.isVerifyEmail) {
            navigation.navigate('EmailVerify', { res: res, pre: 'log' });
        }
    }

    const handleSubmit = async () => {
        clearErrors();
        const { username, password } = formData;
        if (!username || !password) {
            setError((prevErrors) => ({ ...prevErrors, allData: true }));
        } else {
            try {
                const res = await login({email: username, password});
                navigateTo(res)
            } catch (error) {
                console.log('Login Error:', error?.message);
                if(error?.message === 'Invalid credentials') {
                    setError({authentication: true });
                // showAlert(t("Invalid_credentials"), 'error');
                } else if(error?.message === 'The email must be a valid email address.') {
                    setError({validEmail: true });
                    // showAlert(t("The_email_must_be_a_valid_email_address"), 'error');
                } else {
                    setError({somethingWentWrong: true });
                    // showAlert(t("Something_Went_Wrong"), 'error');
                }
                // throw error
            }
        }
    };

    const clearErrors = () => {
        setError({
            allData: false,
            authentication: false,
            validEmail: false,
            somethingWentWrong: false
        });
    };

    if(isLoading) {
        return (
            <CustomLoader text={t('Loading')} />
        )
    }

    const pageContent = () => {
        return (<KeyboardAvoidingView style={currentPlatform === 'web' ? {
            width: screenWidth / 2,
            alignSelf: 'center'
        } :{}}>
            <View style={styles.inputsContainer}>
                <Input
                    lable={t('Email_Address')}
                    placeholder={t('Email_Address')}
                    onChange={(text) => handleInputChange('username', text)}
                    value={formData.username}
                />
                <Input
                    lable={t('Password')}
                    placeholder={t('Password')}
                    secureTextEntry
                    onChange={(text) => handleInputChange('password', text)}
                    type="password"
                    mainDivStyle={{ marginBottom: 0 }}
                    value={formData.password}
                />
                {error.allData && <Text style={styles.error}>{t('Fill_All_Data')}</Text>}
                {error.authentication && <Text style={styles.error}>{t('Invalid_credentials')}</Text>}
                {error.validEmail && <Text style={styles.error}>{t('The_email_must_be_a_valid_email_address')}</Text>}
                {error.somethingWentWrong && <Text style={styles.error}>{t('Something_Went_Wrong')}</Text>}


                <TouchableOpacity
                    style={{ ...styles.forgotBtn, alignSelf: lang == 'ar' ? 'flex-end' : 'flex-start' }}
                    onPress={() => navigation.navigate('ForgotPasswordScreen')}>
                    <Text style={{ ...styles.forgotBtnText, color: currentThemeColor.primaryTextColor }}>
                        {t('Forgot_Password')}
                    </Text>
                </TouchableOpacity>
                <CustomButton
                    btnStyle={{ marginTop: 15 }}
                    text={t('login')}
                    onPress={handleSubmit}
                />
                <View style={styles.socialMediaDiv}>
                    <TouchableOpacity onPress={handleOnPressFacebook}>
                        <Image source={facebook} style={styles.socialMediaImage} />
                    </TouchableOpacity>
                    <TouchableOpacity onPress={handleOnPressGoogle}>
                        <Image source={google} style={styles.socialMediaImage} />
                    </TouchableOpacity>
                </View>
                <TouchableOpacity
                    style={styles.createAccount}
                    onPress={() => navigation.navigate('SignupScreen')}>
                    <Text style={styles.createAccountText}>
                        {t('Dont_have_an_account')}
                    </Text>
                </TouchableOpacity>
            </View>
            <CustomAlert title={alertData?.message} type={alertData?.type} isVisible={!!alertData} onHide={hideAlert} />

        </KeyboardAvoidingView>)
    }

    return currentPlatform === 'web' ? 
    (
        <WebBackgroundTopColor>
            {pageContent()}
            {lang == 'ar' ? (
                <Image source={panda92} style={mainStyle.panda92} />
            ) : (
                <Image source={panda9} style={mainStyle.panda9} />
            )}
            <Image source={panda1} style={mainStyle.panda1} />
        </WebBackgroundTopColor>
    )
    : (
        <View style={mainStyle.mainContainer}>
            <ImageBackground
                source={currentTheme == 'dark' ? back2Dark : back2}
                style={{ width: screenWidth, height: screenHeight, justifyContent: 'center' }}
                resizeMode='cover'>
                <ScrollView>
                    {pageContent()}
                </ScrollView>
            </ImageBackground>
            <Image source={panda1} style={mainStyle.panda1} />
            {lang == 'ar' ? (
                <Image source={panda92} style={mainStyle.panda92} />
            ) : (
                <Image source={panda9} style={mainStyle.panda9} />
            )}
        </View>
    );
};

const styles = StyleSheet.create({
    inputsContainer: {
        width: '90%',
        justifyContent: 'center',
        height: screenHeight,
        alignSelf: 'center',
        paddingVertical: 20,
    },
    forgotBtn: { alignSelf: 'flex-start', marginHorizontal: 20, marginTop: 3 },
    forgotBtnText: { color: '#000', fontFamily: FONTFAMILY },
    socialMediaDiv: { width: '40%', alignSelf: 'center', marginTop: 50, flexDirection: 'row', justifyContent: 'space-between' },
    socialMediaBtn: {},
    socialMediaImage: { width: 50, height: 50, resizeMode: 'contain' },
    createAccount: { alignSelf: 'center', marginTop: 35 },
    createAccountText: { color: Colors.btnColor, fontFamily: FONTFAMILY },
    error: { color: 'red', fontFamily: FONTFAMILY, fontSize: 16, textAlign: 'center', marginTop: 10 },
});

export default LoginScreen;
