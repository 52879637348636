import { StyleSheet } from 'react-native';
import { Colors, FONTFAMILY, HalloweenFont, currentPlatform, isIos, isWeb, screenHeight, screenWidth } from './constants/Colors';

const webWarpStyle = isWeb ? {whiteSpace: 'normal'} : {};

export const sharingGameStyle = StyleSheet.create({
    backgroundImage: {
        width: isWeb ? screenWidth : screenHeight,
        height: isWeb ? screenHeight : screenWidth,
        justifyContent: 'center',
        overflow: 'hidden',
    },
    close: {
        fontFamily: HalloweenFont, 
        fontSize: 35,
        color: 'black'
    },
    closeTouchable: {
        position: 'absolute',
        right: isIos ? '8%' : isWeb ? '2%' : '1%',
        top: isWeb ? '1%' : '2%',
    },
    closeHover: {
        fontSize: 40,
        color: 'red'
    },
    playerCharacter: {
        position: 'absolute',
        width: isWeb ? 150 : 130,
        height: isWeb ? 350 : 200,
        resizeMode: 'contain',
        left: isWeb ? '15%' : '20%',
        bottom: isWeb ? '5%' : '10%',
        zIndex: 10,
    },
    playerPanda: {
        width: isWeb ? 300 : 200,
        height: isWeb ? 350 : 200,
    },
    levelText: {
        fontFamily: HalloweenFont, 
        fontSize: 40, 
        position: 'absolute',
        left: '1%',
        top: isWeb ? '1%' : '2%',
        color: 'black'
    },
    girlCharacter: {
        position: 'absolute',
        width: isWeb ? 150 : 130,
        height: isWeb ? 300 : 200,
        resizeMode: 'contain',
        left: isWeb ? '30%' : '20%',
        bottom: isWeb ? '5%' : '3%',
        zIndex: 10,
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#000',
        width: isWeb ? screenWidth - 600 : screenHeight - 300,
        height: 'auto',
        maxHeight: isWeb ? screenHeight - 120 : screenWidth - 100,
        opacity: 0.7,
        alignSelf: 'center',
        alignContent: 'center',
        borderRadius: 20,
        padding: 20,
        // marginTop: isWeb ? 0 : 0,
        paddingHorizontal: isWeb ? 20 : 0,
    },
    modalContent: {
        alignItems: 'center',
        textAlign: 'center',
        width: '100%'
    },
    modalScroll: {
        paddingHorizontal: isWeb ? 20 : 15,
        paddingVertical: isWeb ? 20 : 0,
    },
    modalText: {
        fontFamily: HalloweenFont, 
        fontSize: isWeb ? 35 : 25, 
        color: '#fff',
        textAlign: 'justify',
        ...webWarpStyle
    },
    modelHeader: {
        fontFamily: HalloweenFont, 
        fontSize: isWeb ? 35 : 25, 
        color: '#fff',
        textAlign: 'center',
        marginBottom: isWeb ? 10 : 5,
    },
    arrowContainer: {
        // top: isWeb ? '5%' : '5%',
        // left: isWeb ? '40%' : '30%',
        alignSelf: 'flex-end',
        marginRight: 0,
        marginBottom: 0,
    },
    arrow:{
        width: 90,
        height: 90,
        resizeMode: 'contain',
    }
});