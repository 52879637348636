import React, { useState, useEffect } from 'react';
import { View, StyleSheet, ActivityIndicator } from 'react-native';
import { Fallback } from '../../../skia/Fallback';
import { AsyncSkia } from '../../../skia/AsyncSkia';
import { currentPlatform } from '../../../constants/Colors';

const HomeScreen = ({navigation}) => {
  const [SkiaHomeScreen, setSkiaHomeScreen] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (currentPlatform !== 'web') {
      const SkiaHomeScreenMobile = require('./SkiaHomeScreen').default;
      setSkiaHomeScreen(() => SkiaHomeScreenMobile);
      setIsLoading(false); 
    }
  }, []);

  return (
    <>
      {currentPlatform === 'web' ? (
          <AsyncSkia
            getComponent={() => import('./SkiaHomeWebScreen')}
            fallback={<Fallback />} 
          />
      ) : (
        <>
          {isLoading && <ActivityIndicator size="large" color="#0000ff" />}
          {!isLoading && <SkiaHomeScreen navigation={navigation} />}
        </>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: 'white',
    flex: 1,
  },
});

export default HomeScreen;
