import React, { useCallback, useMemo, useState } from "react";
import { View, StyleSheet, Text, Image, Dimensions, TouchableOpacity, ImageBackground, TextInput, ScrollView, KeyboardAvoidingView } from "react-native";
import Header from "../../../components/header";
import { Colors, isWeb, screenHeight, screenWidth } from "../../../constants/Colors";
import CustomButton from "../../../components/customButton";
import { mainStyle } from "../../../mainstyle";
import panda2 from '../../../assets/panda/panda2.png';
import check from '../../../assets/icons/check.png';
import branch from '../../../assets/panda/branch.png';
import back5 from '../../../assets/background/back5.png';
import back5Dark from '../../../assets/background/back5Dark.png';
import { useAuth } from "../../../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import Moment from 'moment';
import CustomAlert from "../../../components/customAlert";
import { post } from "../../../WebService/RequestBuilder";
import globalConstants from "../../../config/globalConstants";
import { WebBackgroundTopColor } from "../../../components/WebBackground";

const SubscriptionScreen2 = ({ navigation, route }) => {

    const { currentTheme, lang, currentThemeColor } = useAuth();
    const { t } = useTranslation();

    const item = route?.params?.item || {};
    const ActivisionDate = new Date().toISOString();
    const durationMonths = parseInt(item.duration) || 1;
    const expirationDate = Moment().add(durationMonths, 'months').format('YYYY / M / D');

    const [promoCode, setpromoCode] = useState('')
    const [discountType, setdiscountType] = useState('')
    const [discountAmount, setdiscountAmount] = useState(0)

    const tax = 14;
    let TotalPrice = useMemo(() => item.price_per_month * 1, [item.price, item.duration]);

    if (discountAmount > 0) {
        TotalPrice = TotalPrice - discountAmount;
    }


    const TotalPriceAfterTax = useMemo(() => TotalPrice * (14 / 100) + TotalPrice, [TotalPrice]);
    const flexDirection = lang == 'ar' ? 'row-reverse' : 'row';


    const [alertData, setAlertData] = useState(null);
    const showAlert = useCallback((message, type) => { setAlertData({ message, type }); }, []);
    const hideAlert = useCallback(() => { setAlertData(null); }, []);

    const applyPromo = () => {
        if (promoCode) {
            const body = { promo_code: promoCode };
            post(globalConstants.user.checkPromoCode, body).then((res) => {
                if (res.status) {
                    showAlert(t("Promo_Code_Applied_successfully"), 'success');
                    setdiscountType(res.data.type);
                    if (res.data.type == 'fixed') {
                        setdiscountAmount(res.data.value)
                    } else if (res.data.type == 'percentage') {
                        let DisAmu = (res.data.value / 100) * TotalPrice;
                        setdiscountAmount(DisAmu)
                    }
                }
            }).catch((error) => {
                console.log('error', error);
                showAlert(t("invalid_promo_code"), 'error');
            });
        } else {
            showAlert(t("promo_code_must_be_entered"), 'error');
        }
    }

    const pageContent = () => {
        return (
                  <>
                  <KeyboardAvoidingView>
                  {!isWeb&&<Header title={t('Subscription')} />}
                    <View style={styles.inputsContainer}>
                        <View style={styles.headerView}>
                            <Image source={check} style={styles.check} />
                            <View style={styles.titleContainer}>
                                <Text style={styles.title}>{item.title}</Text>
                                <Text style={[styles.subtitle, {color: currentThemeColor.headerText }]}>{item.subtitle} Plan</Text>
                            </View>
                        </View>

                        <View style={styles.card}>
                            <View style={{ ...styles.cardRow, flexDirection }}>
                                <Text style={styles.label}>{t("Monthly_Price")}</Text>
                                <Text style={styles.value}>$ {parseInt(item?.price_per_month)}</Text>
                            </View>
                            <View style={{ ...styles.cardRow, flexDirection }}>
                                <Text style={styles.label}>{t("Number_of_Months")}</Text>
                                <Text style={styles.value}>1</Text>
                            </View>
                            <View style={{ ...styles.cardRow, flexDirection }}>
                                <Text style={styles.label}>{t("discount")}</Text>
                                <Text style={styles.value}>$ {discountAmount}</Text>
                            </View>

                            <View style={{ ...styles.cardRow, flexDirection }}>
                                <Text style={styles.label}>{t("Subscription_Price")}</Text>
                                <Text style={styles.value}>$ {TotalPrice}</Text>
                            </View>
                            <View style={{ ...styles.cardRow, flexDirection }}>
                                <Text style={styles.label}>{t("Tax")} 14%</Text>
                                <Text style={styles.value}>$ {tax}</Text>
                            </View>
                            <View style={styles.divider} />

                            <View style={{ ...styles.cardRow, flexDirection }}>
                                <Text style={styles.label}>{t("Activision_date")}</Text>
                                <Text style={styles.value}>{Moment(ActivisionDate).format('YYYY / M / D')}</Text>

                            </View>
                            <View style={{ ...styles.cardRow, flexDirection }}>
                                <Text style={styles.label}>{t("Expiration_date")}</Text>
                                <Text style={styles.value}>{expirationDate}</Text>
                            </View>

                            <View style={{ ...styles.discountContainer, flexDirection }}>
                                <TextInput style={styles.discountInput} placeholder={t("Discount_Code")} placeholderTextColor="#999" onChangeText={(text) => { setpromoCode(text); }} />
                                <TouchableOpacity style={styles.discountButton} onPress={applyPromo}>
                                    <Text style={styles.discountButtonText}>{t("Submit")}</Text>
                                </TouchableOpacity>
                            </View>

                            <View style={{ ...styles.cardRow, flexDirection }}>
                                <Text style={styles.label}>{t("Total_Price")}</Text>
                                <Text style={styles.value}>$ {TotalPriceAfterTax}</Text>
                            </View>
                        </View>

                        <CustomButton btnStyle={styles.continueButton} text={t('Continue')} onPress={() => navigation.navigate('SubscriptionScreen3', { item, TotalPriceAfterTax, promoCode })} />
                    </View>
                    <Image source={branch} style={mainStyle.branch} />
                    <Image source={panda2} style={mainStyle.panda2} />
                    </KeyboardAvoidingView></>
        )
    }

    return (
      isWeb ? <WebBackgroundTopColor >
      <Header title={t('Subscription')} />

      {pageContent()}
  </WebBackgroundTopColor> : <View style={mainStyle.mainContainer}>
            <ImageBackground source={currentTheme == 'dark' ? back5Dark : back5} style={styles.imageBackground} resizeMode='cover'>
                <ScrollView>
                {pageContent()}
                </ScrollView>
            </ImageBackground>
            <CustomAlert title={alertData?.message} type={alertData?.type} isVisible={!!alertData} onHide={hideAlert} />

        </View>
    );
};

const styles = StyleSheet.create({
    imageBackground: {
        width: screenWidth,
        height: screenHeight
      },
      inputsContainer: {
        width: '90%',
        alignSelf: 'center',
        paddingVertical: 40,
        paddingBottom: 100
      },
      headerView: {
        width: isWeb ? (screenWidth / 2) : '100%',
        height: '12%',
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'center'
      },
      check: {
        width: 65,
        height: 65,
        resizeMode: 'contain'
      },
      titleContainer: {
        paddingHorizontal: 15,
      },
      title: {
        color: '#fff',
        fontSize: 22
      },
      subtitle: {
        color: '#fff',
        fontSize: 19,
        marginBottom: isWeb ? 0 : 30
      },
      card: {
        zIndex: 2,
        width: isWeb ? (screenWidth / 2) : '100%',
        height: '68',
        backgroundColor: Colors.tertiary,
        alignSelf: 'center',
        borderRadius: 12,
        marginTop: 15,
        padding: 20,
        paddingTop: 30,
        position: 'relative',
        zIndex: 10,
      },
      cardRow: {
        justifyContent: 'space-between',
        marginBottom: 13
      },
      label: {
        fontSize: 19,
        color: Colors.pTxt
      },
      value: {
        fontSize: 19,
        color: Colors.pTxt
      },
      divider: {
        width: '90%',
        height: 1,
        backgroundColor: '#000',
        borderRadius: 99,
        alignSelf: 'center',
        marginVertical: 10
      },
      discountContainer: {
        height: 50,
        marginBottom: 13,
        width: '100%',
        borderRadius: 8,
        flexDirection: 'row',
        borderWidth: 1,
        borderColor: Colors.secondary
      },
      discountInput: {
        width: '60%',
        height: '100%',
        padding: 7,
        color: '#000'
      },
      discountButton: {
        width: '40%',
        height: '100%',
        backgroundColor: Colors.secondary,
        borderRadius: 8,
        justifyContent: 'center',
        alignItems: 'center'
      },
      discountButtonText: {
        color: '#fff',
        fontWeight: '700',
        fontSize: 18
      },
      continueButton: {
        width: isWeb ? (screenWidth / 2) : '96%',
        backgroundColor: Colors.secondary,
        marginTop: 10,
        alignSelf: 'center'
      }
});

export default SubscriptionScreen2;