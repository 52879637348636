import React from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import { Colors, FONTFAMILY, currentPlatform, screenWidth } from '../constants/Colors';
import { useAuth } from '../contexts/AuthContext';
import { useTranslation } from 'react-i18next';

const ResultView = ({ question, isQuickExam }) => {
  const { currentThemeColor } = useAuth();
  const { t } = useTranslation();

  const getAnswerColor = (questionType, userAnswer, correctAnswers, index) => {
    if (questionType === 'drag_drop') {
      return userAnswer.map((answer) => {
        return  correctAnswers[index] == answer ? { color: Colors.greenColor } : { color: 'red' }});
    } else if (questionType === 'check_all_correct') {
      return userAnswer.map(answer => correctAnswers.includes(answer) ? { color: Colors.greenColor } : { color: 'red' });
    } else {
      const isCorrect = userAnswer.map(ua => ua?.toString()).includes(correctAnswers[0]?.toString());
      return isCorrect ? { color: Colors.greenColor } : { color: 'red' };
    }
  };

  return (
    <View style={styles.questionContainer}>
      <View style={styles.pointsView}>
        {!isQuickExam&&<Text style={styles.points}>{t("Earned_Points")} {question.earned_points}</Text>}
        <Text style={styles.questionType}>{t(question.question_type)}</Text>
      </View>

      <Text style={[styles.question, {color: currentThemeColor.primaryTextColor}]}>{question.question}</Text>

      <Text style={[styles.label, {color: currentThemeColor.primaryTextColor}]}>{t("Your_Answer")}</Text>
      {question.user_answer?.map((item, index) => (
        <Text
          style={[
            styles.text,
            getAnswerColor(question.question_type, [item], question.correct_answers, index),
          ]}
          key={`user-${index}`}
        >
          {item}
        </Text>
      ))}
      <Text style={[styles.label, {color: currentThemeColor.primaryTextColor}]}>{t("Correct_Answer")}</Text>
      {question?.correct_answers?.map((item, index) => (
        <Text style={{...styles.text ,...styles.correctAnswer}} key={`correct-${index}`}>
          {item}
        </Text>
      ))}
      <Text style={[styles.label, {color: currentThemeColor.primaryTextColor}]}>{t("Answer_Explanation")}</Text>
      <Text style={[styles.text, {color: currentThemeColor.primaryTextColor}]}>{question.answer_explanation}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
    questionContainer: {
      marginBottom: 30,
      width: currentPlatform === 'web' ? screenWidth / 2 : '98%',
      padding: 20,
    },
    pointsView: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 20
    },
    question: {
        fontSize: 20,
        fontWeight: '400',
        marginBottom: 5,
    },
    userAnswer: {
        marginBottom: 5,
    },
    correctAnswer: {
        marginBottom: 5,
        color: Colors.greenColor,
    },
    text:{
        fontSize: 18,
        fontWeight: '400',
        marginBottom: 5,
    },
    label: {
        fontSize: 16,
        fontWeight: 'bold',
        marginTop: 20,
    }, 
    points: {
      fontSize: 16,
      color: Colors.btnColor,
      marginTop: 20,
      fontWeight: 'bold',
    },
    questionType: {
      backgroundColor: Colors.btnColor,
      color: Colors.mainBackground,
      marginTop: 20,
      borderRadius: 2,
      paddingHorizontal: 6,
      paddingVertical: 5,
      fontSize: 12,
    },
});

export default ResultView;
