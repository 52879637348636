import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Colors } from '../constants/Colors';
import BottomTabNavigator from './BottomTabNavigator';
import { StatusBar } from 'react-native';
import EditProfile from '../screens/App/SettingsStack/editProfile';
import FeedBackScreen from '../screens/App/SettingsStack/feebackScreen';
import SettingsScreen from '../screens/App/SettingsStack/SettingsScreen';
import SubscriptionScreen from '../screens/App/SettingsStack/SubscriptionScreen';
import SubscriptionScreen2 from '../screens/App/SettingsStack/SubscriptionScreen2';
import SubscriptionScreen3 from '../screens/App/SettingsStack/SubscriptionScreen3';
import TopRatedScreen from '../screens/App/HomeStack/TopRatedScreen';
import MultiUsersSelect from '../screens/Auth/multiUsersSelect';
import SubUserLogin from '../screens/Auth/subUserLogin';
import AddNewSubUser from '../screens/Auth/AddNewSubUser';
import ChangePasswordScreen from '../screens/App/SettingsStack/ChangePasswordScreen';
import UserTutorialsScreen from '../screens/setCourse/UserTutorialsScreen';
import ExamScreen from '../screens/setCourse/ExamScreen';
import QuestionsResultsScreen from '../screens/setCourse/QuestionsResultsScreen';


const MainStack = () => {
    const Stack = createNativeStackNavigator();

    return (
        <>
            <StatusBar barStyle="default" backgroundColor={Colors.secondary2} />
            <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName={'BottomTabNavigator'}>
                <Stack.Screen name="MultiUsersSelect" component={MultiUsersSelect} />
                <Stack.Screen name="SubUserLogin" component={SubUserLogin} />
                <Stack.Screen name="AddNewSubUser" component={AddNewSubUser} />
                <Stack.Screen name="BottomTabNavigator" component={BottomTabNavigator} />
                <Stack.Screen name="TopRatedScreen" component={TopRatedScreen} />
                <Stack.Screen name="EditProfile" component={EditProfile} />
                <Stack.Screen name="FeedBackScreen" component={FeedBackScreen} />
                <Stack.Screen name="SettingsScreen" component={SettingsScreen} />
                <Stack.Screen name="SubscriptionScreen" component={SubscriptionScreen} />
                <Stack.Screen name="SubscriptionScreen2" component={SubscriptionScreen2} />
                <Stack.Screen name="SubscriptionScreen3" component={SubscriptionScreen3} />
                <Stack.Screen name="ChangePasswordScreen" component={ChangePasswordScreen} />
                <Stack.Screen name="UserTutorialsScreen" component={UserTutorialsScreen} />
                <Stack.Screen name="ExamScreen" component={ExamScreen} />
                <Stack.Screen name="QuestionsResultsScreen" component={QuestionsResultsScreen} />
            </Stack.Navigator>
        </>
    );
};

export default MainStack;