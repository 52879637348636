import { Image, Text, TouchableOpacity, View } from "react-native";
import { mainStyle } from "../mainstyle";
import { Colors } from "../constants/Colors";

const CustomButton = ({ btnStyle, text = '', textStyle, onPress, icon, enable=true, isSubmit=false }) => {
    return (
        <TouchableOpacity disabled={!enable} style={[isSubmit ? mainStyle.CustomButtonSubmit : mainStyle.CustomButton, {backgroundColor: enable ? Colors.btnColor : Colors.lightGray}, btnStyle ]} onPress={onPress}>
            {text && <Text style={{ ...mainStyle.CustomButtonText, ...textStyle, }}>{text}</Text>}
            {icon && <Image source={icon} style={{ width: '100%', height: '100%', resizeMode: 'cover' }} />}
        </TouchableOpacity>
    )
}

export default CustomButton;