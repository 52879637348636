import React, { useState } from 'react';
import { View, StyleSheet, TouchableWithoutFeedback, Image, Animated } from 'react-native';
import mDark from '../assets/icons/mDark.png';
import mLight from '../assets/icons/mLight.png';

const SwitchWithImageAndAnimation = ({ Value = false, onPress }) => {

    const position = new Animated.Value(Value ? 1 : 0);
    const toggleSwitch = () => {
        onPress(previousState => !previousState)
        Animated.timing(position, {
            toValue: Value ? 0 : 1,
            duration: 300,
            useNativeDriver: false
        }).start();
    };
    const interpolateX = position.interpolate({
        inputRange: [0, 0],
        outputRange: [0, 33],
    });
    return (
        <TouchableWithoutFeedback onPress={toggleSwitch}>
            <View style={styles.switchContainer}>
                <Animated.View style={[styles.switchButton, { transform: [{ translateX: interpolateX }] }]}>
                    <Image
                        source={Value ? mLight : mDark}
                        style={styles.switchImage}
                    />
                </Animated.View>
            </View>
        </TouchableWithoutFeedback>
    )
}

export default SwitchWithImageAndAnimation


const styles = StyleSheet.create({
    switchContainer: {
        width: 100,
        height: 36,
        padding: 2,
        borderWidth: 1,
        borderRadius: 13,
        borderColor: "#ccc",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: '#C6C5C5'
    },
    switchButton: {
        position: 'absolute',
        left: 2,
        width: 60,
        height: '100%',
        borderRadius: 10,
        backgroundColor: 'white',
        justifyContent: 'center',
        alignItems: 'center'
    },
    switchImage: {
        width: 24,
        height: 24,
        resizeMode: 'contain'
    }
})
