import React, { Fragment, useEffect, useState } from 'react';
import { View, StyleSheet, ImageBackground, Image, Text, TouchableOpacity, SafeAreaView, ScrollView } from 'react-native';
import { currentPlatform, screenHeight, screenWidth } from '../../constants/Colors';
import back2 from '../../assets/background/back2.png';
import back2Dark from '../../assets/background/back2Dark.png';
import panda1 from '../../assets/panda/panda1.png';
import Input from '../../components/input';
import CustomButton from '../../components/customButton';
import { mainStyle } from '../../mainstyle';
import Header from '../../components/header';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/AuthContext';
import CustomAlert from '../../components/customAlert';
import { post } from '../../WebService/RequestBuilder';
import globalConstants from '../../config/globalConstants';
import panda3 from '../../assets/panda/panda3.png';
import { WebBackgroundTopColor } from '../../components/WebBackground';

const SetNewPasswordScreen = ({ navigation, route }) => {
    const { t } = useTranslation();

    const {email, otpCode} = route?.params || ''

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const { currentTheme } = useAuth();
    const [showAlertError, setShowAlertError] = useState(false);
    
    const [showAlert, setShowAlert] = useState(false);
    const handleHideAlert = () => {
        setShowAlertError(false)
        if(showAlert) {
            setShowAlert(false)
            navigation.navigate('LoginScreen');
        }
    };

    const handleSubmit = async () => {
        try {
            if(password !== confirmPassword) {
                setShowAlertError(true)
            } else {
                const res = await post(globalConstants.auth.changeForgotPassword, {
                    otp: otpCode,
                    email,
                    password
                });
                if (res.status) setShowAlert(true) 
            }
        } catch (error) {
            console.log('handleSubmit OTP Error:', error);
        }
    };

    const pageContent = () => {
        return (
            <Fragment>
                <Header title={t('Reset_Password')} />
                <Image source={panda3} style={mainStyle.pandaHeader} />

                <View style={styles.inputsContainer}>
                    <Input
                        lable={t('Password')}
                        placeholder={t('Enter_Password')}
                        onChange={setPassword}
                        subText={t('Enter_Password_Again')}
                        type="password"
                    />

                    <Input
                        lable={t('Confirm_Password')}
                        placeholder={t('Confirm_Password')}
                        onChange={setConfirmPassword}
                        subText={t('')}
                        type="password"
                    />

                    <CustomButton btnStyle={styles.button} text={t('Submit')} onPress={handleSubmit} />
                </View>

            <CustomAlert title={t("Password_should_match")} type='error' isVisible={showAlertError} onHide={handleHideAlert} />
            <CustomAlert title={t("Password_reset_Successfully")} isVisible={showAlert} onHide={handleHideAlert} />

            </Fragment>
        )
    }

    return (
        currentPlatform === 'web' ? (
                <WebBackgroundTopColor>
                    {pageContent()}
                    <View style={styles.imageContainer}>
                        <Image source={panda1} style={mainStyle.panda1} />
                    </View>
                </WebBackgroundTopColor>
            ) :(<View style={styles.container}>
                <ImageBackground source={currentTheme === 'dark' ? back2Dark : back2} style={styles.backgroundImage} resizeMode='cover'>
                    <ScrollView>
                    {pageContent()}
                    </ScrollView>
                    <View style={styles.imageContainer}>
                        <Image source={panda1} style={mainStyle.panda1} />
                    </View>
                </ImageBackground>
                
            </View>)
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    backgroundImage: {
        width: screenWidth,
        height: screenHeight,
    },
    inputsContainer: {
        width: currentPlatform === 'web' ? screenWidth / 2 : '90%',
        alignSelf: 'center',
        paddingVertical: 20,
        paddingTop: currentPlatform === 'web' ? 60 : 80
    },
    error: {
        color: 'red',
        fontFamily: 'FONTFAMILY',
        fontSize: 16,
        textAlign: 'center',
        marginVertical: 10,
    },
    resendText: {
        fontFamily: 'FONTFAMILY',
        fontSize: 15,
        fontWeight: '600',
        marginTop: 15,
    },
    button: {
        marginTop: 15,
    },
    imageContainer: {
        flex: 1,
    },
});

export default SetNewPasswordScreen;
