import React, { Fragment, useEffect, useState } from "react";
import { View, ImageBackground, StyleSheet, ScrollView, Image, Text } from "react-native";
import back6Dark from '../../../assets/background/back6Dark.png';
import back6 from '../../../assets/background/back6.png';
import { Colors, currentPlatform, isWeb, screenHeight, screenWidth } from "../../../constants/Colors";
import { mainStyle } from "../../../mainstyle";
import { useAuth } from "../../../contexts/AuthContext";
import userP from '../../../assets/background/userP.png';
import star from '../../../assets/icons/star.png';
import giftPoints from '../../../assets/icons/giftPoints.png';
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import { customGet, get } from "../../../WebService/RequestBuilder";
import globalConstants from "../../../config/globalConstants";
import CustomLoader from "../../../components/CustomLoader";
import { WebBackgroundTopColor } from "../../../components/WebBackground";
import { useTranslation } from "react-i18next";


const UserProgressScreen = () => {
    const { t } = useTranslation();
    const days = [t('SUN'), t('MON'), t('TUE'), t('WED'), t('THU'), t('FRI'), t('SAT')];

    const { currentTheme, currentThemeColor, subUserImage, currentCourseName, subUser, currentSubUserCourse, points, lang } = useAuth();
    const [dailyPoints, setDailyPoints] = useState([]);
    const [userProgress, setUserProgress] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchData = async () => {
        try {
            setIsLoading(true);

            const {data} = await customGet(globalConstants.user.weeklyPoints.replace('{userInfoId}', subUser.id))

            setDailyPoints(data);

            const response = await customGet(globalConstants.user.userCourseProgress, {
                user_course_id: currentSubUserCourse.id
            });

            setUserProgress(response.data)
        } catch (error) {
            console.log('error fetchData', error);
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchData();
    }, [subUser, currentCourseName, currentSubUserCourse?.current_level, points])

    if(isLoading) {
        return (
            <CustomLoader text={t('Loading')} />
        )
    }

    const pageContent = () => {
        return (
            <Fragment>
                    <View style={styles.contentContainer}>
                        {currentPlatform !== 'web' && <View style={styles.userImage}>
                            <Image source={subUserImage ? { uri: subUserImage } : userP} style={styles.image} />
                        </View>}
                        <Text style={[styles.username, {color: currentThemeColor.primaryTextColor}]}>{subUser?.name}</Text>
                        <Text style={[styles.level, {color: currentThemeColor.primaryTextColor}]}>{currentCourseName} . {t("Level")} {currentSubUserCourse.current_level}</Text>

                        <View style={styles.pointsContainer}>
                            <Image source={star} style={styles.starImage} />
                            <Text style={[styles.points, {color: currentThemeColor.primaryTextColor}]}>{points} Pts</Text>
                        </View>
                        
                        <Text style={[styles.dailyPoints, {color: currentThemeColor.primaryTextColor}]}>{t("Daily_Points")}</Text>
                        <View style={styles.daysContainer}>
                            {days.map((day, index) => (
                                <View key={index} style={styles.dayContainer}>
                                    {
                                        dailyPoints[index].earned_points != 0 ? <View style={styles.giftView} /> : <Image source={giftPoints} style={styles.giftImage} />
                                    }
                                    <Text style={[styles.dayText, {color: currentThemeColor.primaryTextColor}]}>{day}</Text>
                                </View>
                            ))}
                        </View>

                        <View style={[styles.progressContainer, lang === 'ar' && {flexDirection: 'row-reverse'}]}>
                            <AnimatedCircularProgress
                                size={150}
                                width={10}
                                fill={userProgress?.current_level_percentage}
                                padding={10}
                                lineCap={'round'}
                                tintColor={Colors.btnColor}
                                tintTransparency={true}
                                rotation={360}
                                backgroundColor={isWeb ? currentThemeColor.webProgressBackground : Colors.mainBackground}
                                style={styles.progress}
                                >
                                {(fill) => (
                                    <Text style={styles.progressText}>
                                        {Number.isInteger(fill) ? fill.toString() : fill.toFixed(2)}%
                                    </Text>
                                )}
                            </AnimatedCircularProgress>
                            <Text style={styles.progressLabelText}>{t("Current_Level_Progress")}</Text>
                        </View>

                        <View style={[styles.progressContainer, lang === 'ar' && {flexDirection: 'row-reverse'}]}>
                            <AnimatedCircularProgress
                                size={150}
                                width={10}
                                fill={userProgress?.course_percentage}
                                padding={10}
                                lineCap={'round'}
                                tintColor={Colors.btnColor}
                                tintTransparency={true}
                                rotation={360}
                                backgroundColor={isWeb ? currentThemeColor.webProgressBackground : Colors.mainBackground}
                                style={styles.progress}
                                >
                                {(fill) => (
                                    <Text style={styles.progressText}>
                                        {Number.isInteger(fill) ? fill.toString() : fill.toFixed(3)}%
                                    </Text>
                                )}
                            </AnimatedCircularProgress>
                            <Text style={styles.progressLabelText}>{currentCourseName} {t("Course_Progress")}</Text>
                        </View>
                    </View>
            </Fragment>
        )
    }

    return (
        currentPlatform === 'web' ? (
            <View style={mainStyle.mainContainer}>
            <ScrollView style={{backgroundColor: currentThemeColor.webMainBackground}} showsVerticalScrollIndicator={false} contentContainerStyle={styles.scrollContainer}>
                <View style={styles.imageBackground}>
                <WebBackgroundTopColor customHightHeader={300} elementOnImage={<View style={[styles.userImage, {alignSelf: 'center'}]}>
                            <Image source={subUserImage ? { uri: subUserImage } : userP} style={[styles.image, {alignSelf: 'center'}]} />
                        </View>}>
                {pageContent()}
            </WebBackgroundTopColor>
                </View>
            </ScrollView></View>
        ) : <View style={mainStyle.mainContainer}>
            <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={styles.scrollContainer}>
                <ImageBackground source={currentTheme == 'dark' ? back6Dark : back6} style={styles.imageBackground} resizeMode='cover'>
                    {pageContent()}
                </ImageBackground>
            </ScrollView>
        </View>
    );
};

const styles = StyleSheet.create({
    scrollContainer: {
        flexGrow: 1,
    },
    imageBackground: {
        width: screenWidth,
        height: currentPlatform === 'web' ? screenHeight + 400 : screenHeight + 200,
    },
    contentContainer: {
        alignItems: 'center',
    },
    userImage: {
        width: 160,
        height: 160,
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 30,
    },
    image: {
        width: '100%',
        height: '100%',
        borderRadius: 100
    },
    username: {
        fontSize: 20,
        marginTop: 10,
    },
    level: {
        fontSize: 16,
        marginTop: 5,
    },
    pointsContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 5,
        justifyContent: 'center',
    },
    points: {
        fontSize: 16,
        marginLeft: 10
    },
    starImage: {
        width: 20,
        height: 20,
        marginLeft: 5,
    },
    daysContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 15,
    },
    dayContainer: {
        alignItems: 'center',
        marginHorizontal: 10,
    },
    giftImage: {
        width: 30,
        height: 30,
    },
    giftView: {
        width: 30,
        height: 30,
        borderRadius: 100,
        borderWidth: 5,
        borderColor: Colors.greenColor
    },
    dayText: {
        marginTop: 5,
    },
    dailyPoints: {
        fontSize: 20,
        marginTop: 30,
    },
    progressContainer: {
        alignItems: 'center',
        marginTop: 40,
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: currentPlatform === 'web' ? screenWidth / 3 : screenWidth
    },
    progressText: {
        fontSize: 18,
        marginTop: 10,
        color: Colors.btnColor,
    },
    progressLabelText: {
        marginTop: 10,
        fontSize: 16,
        color: Colors.btnColor,
        maxWidth: screenWidth - 180,
        width: screenWidth,
    },
    progress: {
        width: 170,
    }
});


export default UserProgressScreen;
