import React, { Fragment, useCallback, useState } from 'react';
import { View, StyleSheet, ImageBackground, Image, ScrollView, Text } from 'react-native';
import { Colors, FONTFAMILY, isWeb, screenHeight, screenWidth } from '../../constants/Colors';
import back2 from '../../assets/background/back2.png';
import back2Dark from '../../assets/background/back2Dark.png';
import panda9 from '../../assets/panda/panda9.png';
import panda92 from '../../assets/panda/panda9-2.png';
import CustomButton from '../../components/customButton';
import { mainStyle } from '../../mainstyle';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/AuthContext';
import Header from '../../components/header';
import Input from '../../components/input';
import CustomAlert from '../../components/customAlert';
import { WebBackgroundTopColor } from '../../components/WebBackground';
import panda3 from '../../assets/panda/panda3.png';

const SubUserLogin = ({ navigation, route }) => {

    const { currentTheme, loginSubUser, lang, currentThemeColor } = useAuth();
    const {subUserId, subUserName} = route?.params;
    const { t } = useTranslation();
    const backgroundImageSource = currentTheme == 'dark' ? back2Dark : back2;
    const pandaImageSource = lang == 'ar' ? panda92 : panda9;

    const [alertData, setAlertData] = useState(null);
    const [password, setpassword] = useState('');
    const showAlert = useCallback((message, type) => { setAlertData({ message, type }); }, []);
    const hideAlert = useCallback(() => { setAlertData(null); }, []);

    const handleSubmit = async () => {
        if (!password) return showAlert(t("Please_enter_your_password"), 'error');
        try {
            const login = await loginSubUser(password, subUserId);
            navigation.navigate('SetCourseStack', {checkRoute:true});
        } catch (error) {
            console.log('error handleSubmit loginSubUser', error.message);
            if (error.message == 'Incorrect credentials') {
                showAlert(t("Password_is_Wrong"), 'error');
            }
        }
    };

    const pageContent = () => {
        return (
            <Fragment>
                <Header title={t('login')} />
                <Image source={panda3} style={mainStyle.pandaHeader} />

                    <View style={styles.inputsContainer}>
                        <Text style={{ fontFamily: FONTFAMILY, fontSize: 25, marginBottom: 35, color: currentThemeColor.primaryTextColor }}>{t("Welcome_Back")} {subUserName}</Text>
                        <Input
                            lable={t('Password')} placeholder={t('Password')}
                            secureTextEntry type="password" value={password}
                            onChange={(text) => setpassword(text)}
                            mainDivStyle={{ marginBottom: 0 }} keyboardType='decimal-pad'
                            subText={t('Enter_Your_Four_digits_Password_for_this_account')}
                            maxLength={4}
                        />
                        <CustomButton btnStyle={{ marginTop: 15, }} text={t('login')} onPress={handleSubmit} />
                    </View>
                    <Image source={pandaImageSource} style={{ ...styles.pandaImage, alignSelf: lang == 'ar' ? 'flex-start' : 'flex-end' }} />
                    <CustomAlert title={alertData?.message} type={alertData?.type} isVisible={!!alertData} onHide={hideAlert} />
            </Fragment>
        )
    }


    return (
        isWeb ? (
            <WebBackgroundTopColor>
                {pageContent()}
            </WebBackgroundTopColor>
        ) : <View style={mainStyle.mainContainer}>
            <ImageBackground source={backgroundImageSource} style={styles.backgroundImage} resizeMode='cover'>
                <ScrollView contentContainerStyle={styles.scrollViewContent}>
                    {pageContent()}
                </ScrollView>
            </ImageBackground>

        </View >
    );

};

const styles = StyleSheet.create({
    backgroundImage: {
        width: screenWidth,
        height: screenHeight,
        justifyContent: 'center'
    },
    scrollViewContent: {
        flexGrow: 1
    },
    inputsContainer: {
        width: isWeb ? screenWidth / 3 : '90%',
        height: '50%',
        alignSelf: 'center',
        paddingVertical: 20,
        justifyContent: 'center'
    },
    userContainer: {
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    userItem: {
        width: 150,
        height: 150,
        backgroundColor: Colors.secondary,
        marginBottom: 15,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'space-around',
        paddingTop: 15
    },
    userImage: {
        width: 70,
        height: 70,
        resizeMode: 'contain',
        borderRadius: 99
    },
    userName: {
        fontSize: 18,
        fontFamily: FONTFAMILY,
        color: '#ffff'
    },
    addUserButton: {
        width: 150,
        height: 150,
        backgroundColor: Colors.lightGray,
        marginBottom: 15,
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center'
    },
    pandaImage: {
        ...mainStyle.panda9,
    }
});


export default SubUserLogin;
