import { StyleSheet, Text, View, Modal, Image, TouchableOpacity, Animated } from 'react-native';
import React, { useEffect, useRef } from 'react';
import { colors } from '../../constants/Colors';
import winImg from '../../assets/win.png';
import loseImg from '../../assets/lose.png';

const StatusPopup = ({ status, onPress }) => {
  const scaleAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (status !== '') {
      Animated.spring(scaleAnim, {
        toValue: 1,
        friction: 6,
        useNativeDriver: true,
      }).start();
    }
  }, [status]);

  const src = status === 'win' || status === 'completed' ? winImg : loseImg;
  const message = status === 'win'
    ? 'Congrats you won'
    : status === 'completed'
      ? 'Congratulations you completed the puzzle'
      : 'Oops you lost';

  const buttonText = status === 'win' ? 'Next word' : status === 'completed' ? 'Replay' : 'Retry';

  return (
    <Modal visible={status !== ''} animationType="fade" transparent={true}>
      <View style={styles.modalContainer}>
        <Animated.View style={[styles.popup, { transform: [{ scale: scaleAnim }] }]}>
          <Image source={src} style={styles.img} />
          <Text style={styles.text}>{message}</Text>
          <TouchableOpacity onPress={onPress} style={styles.btn}>
            <Text style={styles.btnText}>{buttonText}</Text>
          </TouchableOpacity>
        </Animated.View>
      </View>
    </Modal>
  );
};

export default StatusPopup;

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    backgroundColor: colors.darkOverlayColor,
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 20,
  },
  popup: {
    width: '80%',
    padding: 20,
    borderRadius: 10,
    backgroundColor: colors.Modal,
    alignItems: 'center',
  },
  text: {
    fontSize: 20,
    fontWeight: '700',
    color: 'white',
  },
  img: {
    width: 100,
    height: 100,
  },
  btn: {
    padding: 10,
    marginTop: 20,
    borderRadius: 8,
    backgroundColor: colors.shapeColor,
  },
  btnText: {
    color: 'white',
    fontSize: 18,
  },
});
