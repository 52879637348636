import { useState, useRef, useEffect } from "react";
import { View, StyleSheet, Text, Image, Dimensions, FlatList, TouchableOpacity, ScrollView, KeyboardAvoidingView } from "react-native";
import Header from "../../../components/header";
import { Colors, FONTFAMILY, currentPlatform, isWeb, screenHeight, screenWidth } from "../../../constants/Colors";
import check from '../../../assets/icons/check.png';
import CustomButton from "../../../components/customButton";
import { useAuth } from "../../../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { customGet, get } from "../../../WebService/RequestBuilder";
import globalConstants from "../../../config/globalConstants";
import moment from "moment";
import { WebBackgroundTopColor } from "../../../components/WebBackground";

const SubscriptionScreen = ({ navigation }) => {

    const { currentThemeColor, lang, user } = useAuth();
    const { t } = useTranslation();

    const flatListRef = useRef(null);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 1;


    const [data, setData] = useState([])

    const getData = async () => {
        await customGet(globalConstants.user.subscriptions).then((res) => {
            if (res && res.data) setData(res.data)
        }).catch((error) => { console.log('error', error); })
    }
    useEffect(() => {
        getData();
    }, []);

    const CarouselCardItem = ({ item }) => (
        <View style={styles.itemstyle}>
            <View style={styles.card}>
                <View style={{ width: '100%', height: '88%', alignItems: 'center' }}>
                    <Text style={styles.cardHeader}>{item.type} Account</Text>
                    <Text style={styles.cardSubHeader}>{item.renew_period}</Text>
                    <Text style={styles.cardPrice}>${parseInt(item?.price_per_month)} / Mon</Text>
                    {item?.plan_advantages?.slice(0, 4).map((fItem, findex) => (
                        <View key={findex} style={styles.cardRows}>
                            <View style={{ ...styles.cardRow, flexDirection: lang == 'ar' ? 'row-reverse' : 'row', }}>
                                <Text style={styles.cardRowText}>{fItem.description}</Text>
                                <View style={{ width: '15%' }}>
                                    <Image source={check} style={styles.check} />
                                </View>
                            </View>
                        </View>
                    ))}
                </View>
                <View style={{ width: '100%', height: '12%', paddingBottom: 15 }}>
                    <CustomButton btnStyle={{ width: '96%', backgroundColor: '#fff' }} textStyle={{ color: Colors.pTxt }} text={t('Continue')} onPress={() => { navigation.navigate('SubscriptionScreen2', { item: item }) }} />
                </View>
            </View >
        </View >
    );

    const renderPaginationDots = () => {
        return (
            <View style={styles.pagenView}>
                {Array.from(Array(Math.ceil(data?.length / itemsPerPage)).keys()).map((index) => (
                    <TouchableOpacity key={index} onPress={() => scrollToPage(index)}>
                        <View style={{ ...styles.pagen, width: currentPage === index ? 50 : 15 }} />
                    </TouchableOpacity>
                ))}
            </View>
        );
    };

    const scrollToPage = (pageIndex) => {
        setCurrentPage(pageIndex);
        flatListRef.current.scrollToIndex({ index: pageIndex * itemsPerPage });
    };

    const pageContent = () => {
        return (
            <>
            <KeyboardAvoidingView>
            {!isWeb&&<Header title={t('Subscription')} />}
                {!user.user_subscription.in_trial_period && user.user_subscription.subscription_start_date != null && (
                    <View style={styles.userSubCard}>
                        <Text style={{ ...styles.userSubCardText, marginBottom: 15 }}>{t('Subscription_info')}</Text>
                        <Text style={styles.userSubCardText}>{t('Subscription_Type')} {user?.user_subscription?.family_account ? t('Family_Account') : t('Individual_Account')}</Text>
                        <Text style={styles.userSubCardText}>{t("Start_Date")} {moment(user?.user_subscription?.subscription_start_date).format('YYYY / M / D')}</Text>
                        <Text style={styles.userSubCardText}>{t("End_Date")} {moment(user?.user_subscription?.subscription_expiration_date).format('YYYY / M / D')}</Text>
                    </View>
                )}

                {user.user_subscription.in_trial_period && user.user_subscription.subscription_start_date == null && (
                    <View style={styles.userSubCard}>
                        <Text style={{ ...styles.userSubCardText, marginBottom: 15 }}>{t('Subscription_info')}</Text>
                        <Text style={styles.userSubCardText}>{t('Subscription_Type')} {t('FreeFree_Trial_Account')} </Text>
                        <Text style={styles.userSubCardText}>{t("Start_Date")} {moment(user?.user_subscription?.trial_start_at).format('YYYY / M / D')}</Text>
                        <Text style={styles.userSubCardText}>{t("End_Date")} {moment(user?.user_subscription?.trial_end_at).format('YYYY / M / D')}</Text>
                    </View>
                )}

                {renderPaginationDots()}
                <FlatList
                    ref={flatListRef}
                    data={data || []}
                    renderItem={({ item }) => <CarouselCardItem item={item} />}
                    pagingEnabled
                    showsHorizontalScrollIndicator={false}
                    horizontal={true}
                    scrollEventThrottle={16}
                    keyExtractor={(item, index) => index.toString()}
                    getItemLayout={(data, index) => ({
                        length: screenWidth,
                        offset: screenWidth * index,
                        index,
                    })}
                    onScroll={(event) => {
                        const slideIndex = Math.round(event.nativeEvent.contentOffset.x / screenWidth);
                        if (currentPage !== slideIndex) {
                            setCurrentPage(slideIndex);
                        }
                    }}
                />
                </KeyboardAvoidingView>
            </>
        )
    }

    return (
        isWeb ? <WebBackgroundTopColor >
        <Header title={t('Subscription')} />

        {pageContent()}
    </WebBackgroundTopColor> : <View style={{ backgroundColor: currentThemeColor.mainBackground, flex: 1, }}>
            <ScrollView>
                {pageContent()}
            </ScrollView >
        </View >
    );
};

export default SubscriptionScreen;

const styles = StyleSheet.create({
    pagen: {
        width: 50,
        height: 10,
        backgroundColor: Colors.secondary,
        alignSelf: 'center',
        borderRadius: 12,
        marginHorizontal: 2
      },
      pagenView: {
        width: isWeb ? screenWidth / 2 : '90%',
        marginBottom: 15,
        alignSelf: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: currentPlatform == 'ios' ? 50 : 0
      },
      card: {
        width: isWeb ? screenWidth / 2 : screenWidth - 50,
        height: 550,
        backgroundColor: Colors.secondary,
        alignSelf: 'center',
        borderRadius: 12,
        paddingVertical: 30,
        paddingHorizontal: 15,
        alignItems: 'center'
      },
      cardHeader: {
        fontSize: 21,
        color: '#fff',
        marginBottom: 5
      },
      cardSubHeader: {
        fontSize: 23,
        color: '#fff',
        marginBottom: 15
      },
      cardPrice: {
        fontSize: 23,
        color: '#fff',
        marginBottom: 25
      },
      cardRows: {
        width: '100%',
        marginBottom: 15
      },
      cardRow: {
        justifyContent: 'space-between',
        alignItems: 'center'
      },
      check: {
        width: 40,
        height: 40,
        resizeMode: 'contain'
      },
      cardRowText: {
        width: "85%",
        color: '#fff',
        fontSize: 18
      },
      itemstyle: {
        width: screenWidth,
        height: 550,
        alignSelf: 'center',
        marginBottom: screenHeight > 585 ? 50 : 0
      },
      userSubCard: {
        width: isWeb ? screenWidth / 2 : '90%',
        alignSelf: 'center',
        backgroundColor: Colors.secondary2,
        marginBottom: 20,
        borderTopLeftRadius: 22,
        borderTopRightRadius: 25,
        borderBottomLeftRadius: 50,
        borderBottomRightRadius: 77,
        padding: 10,
        paddingBottom: 25
      },
      userSubCardText: {
        color: '#fff',
        fontFamily: FONTFAMILY,
        fontSize: 17,
        marginBottom: 7
      }      
});
