import { useCallback, useEffect, useState } from 'react';
import { View, StyleSheet, ImageBackground, Image, TouchableOpacity, ScrollView, KeyboardAvoidingView } from 'react-native';
import { Colors, isWeb, screenHeight, screenWidth } from '../../../constants/Colors';
import Input from '../../../components/input';
import CustomButton from '../../../components/customButton';
import { mainStyle } from '../../../mainstyle';
import Header from '../../../components/header';
import userP from '../../../assets/background/userP.png';
import back4 from '../../../assets/background/back4.png';
import back4Dark from '../../../assets/background/back4Dark.png';
import panda9 from '../../../assets/panda/panda9.png';
import panda92 from '../../../assets/panda/panda9-2.png';

import * as ImagePicker from 'expo-image-picker';
import { useAuth } from '../../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import CustomAlert from '../../../components/customAlert';
import { post, uploadFile } from '../../../WebService/RequestBuilder';
import globalConstants from '../../../config/globalConstants';
import { storeJsonData } from '../../../helper/GeneralStorage';
import { WebBackgroundTopColor } from '../../../components/WebBackground';

const EditProfile = ({ navigation }) => {
    const { currentTheme, currentThemeColor, lang, user, updateProfile, subUser, updateSubProfile, setSubUserImage, subUserImage } = useAuth();
    const { t } = useTranslation();
    const [image, setImage] = useState(subUserImage ? subUserImage : null);
    const [username, setUsername] = useState(subUser?.name);
    const [phoneNumber, setPhoneNumber] = useState(user?.phone_number);
    const [emailAddress, setEmailAddress] = useState(user?.email);
    const [alertData, setAlertData] = useState(null);
    const [newImageData, setNewImageData] = useState(null);

    const showAlert = useCallback((message, type) => { setAlertData({ message, type }); }, []);
    const hideAlert = useCallback(() => { setAlertData(null); }, []);

    useEffect(() => {
        if (!subUser || subUser?.is_main_profile) return;

        setUsername(subUser.name);
        setPhoneNumber(user?.phone_number);
        setEmailAddress(user?.email);
    }, [user, subUser]);

    const pickImage = async () => {
        try {
            const result = await ImagePicker.launchImageLibraryAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                allowsEditing: true,
                aspect: [4, 3],
                quality: 1,
            });

            const data = result.assets[0];

            if (isWeb) {
                const responseImage = await fetch(data.uri);
                const blob = await responseImage.blob();
    
                const imageWeb = {
                    uri: data.uri,
                    type: blob.type,
                    name: data.fileName || 'profile.jpg',
                    blob: blob,
                }

                console.log("imageWeb", imageWeb);
    
                setNewImageData(imageWeb);
            } else {
                setNewImageData(data);
            }

            if (!result.canceled) {
                setImage(data.uri);
            }
        } catch (error) {
            console.log('EditProfile ~~ ImagePicker ~~ error => ', error);
        }
    };

    const update = async () => {
        try {
            const updateUser = subUser?.is_main_profile
                ? updateProfile(phoneNumber, username, emailAddress)
                : updateSubProfile(username, subUser.id);
            
            if(newImageData) {
                const {data} = await uploadFile(globalConstants.user.uploadUserImage, newImageData, subUser.id);

                setSubUserImage(data?.attachment);
                storeJsonData(globalConstants.subUserImage, data?.attachment);
            }

            if (updateUser) {
                showAlert(t("Profile_Updated_Successfully"), 'success');
                setTimeout(() => {
                    navigation.goBack();
                }, 2000);
            }
        } catch (error) {
            console.log("Error Upload Image", error.message);
            showAlert(t("Something Went Wrong"), 'error');
        }
    };

    const pageContent = () => {
        return (
            <>
                <KeyboardAvoidingView>

                {!isWeb&&<Header title={t('Edit_Profile')} />}
                    <View style={styles.inputsContainer}>
                        <TouchableOpacity style={styles.imageDiv} onPress={pickImage}>
                            <Image source={image ? { uri: image } : userP} resizeMode='cover' style={styles.image} />
                        </TouchableOpacity>
                        <Input lable={t('Username')} placeholder={t('Username')} onChange={setUsername} value={username} />
                        {subUser?.is_main_profile && <Input lable={t('Phone_Number')} placeholder={t('Phone_Number')} onChange={setPhoneNumber} keyboardType={'phone-pad'} value={phoneNumber} />}
                        {subUser?.is_main_profile && <Input lable={t('Email_Address')} placeholder={t('Email_Address')} onChange={setEmailAddress} keyboardType={'email-address'} value={emailAddress} />}
                        <CustomButton btnStyle={{ marginTop: 15, backgroundColor: Colors.secondary }} text={t('Submit')} onPress={update} />
                    </View>
            <CustomAlert title={alertData?.message} type={alertData?.type} isVisible={!!alertData} onHide={hideAlert} />
            </KeyboardAvoidingView></>
        )
    }

    return (
        isWeb ? <WebBackgroundTopColor >
            <Header title={t('Edit_Profile')} />
        {pageContent()}
        
                    
        {lang === 'ar'
                ? <Image source={panda92} style={{ ...mainStyle.panda92, top: 0 }} />
                : <Image source={panda9} style={mainStyle.panda9} />
            }
    </WebBackgroundTopColor>
    : <View style={mainStyle.mainContainer}>
            <ImageBackground 
                source={currentTheme === 'dark' ? back4Dark : back4}
                style={{ width: screenWidth, height: screenHeight }}
                resizeMode='cover'
            >
                <ScrollView>
                   {pageContent()}
                </ScrollView>
            </ImageBackground>

                    
            {lang === 'ar'
                ? <Image source={panda92} style={{ ...mainStyle.panda92, top: 0 }} />
                : <Image source={panda9} style={mainStyle.panda9} />
            }
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.mainBackground
    },
    inputsContainer: {
        width: isWeb ? (screenWidth / 2) - 100 : '90%',
        alignSelf: 'center',
        paddingVertical: 20,
        paddingBottom: screenHeight > 585 ? 70 : 0
    },
    imageDiv: {
        width: 180,
        height: 180,
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
    },
    image: {
        width: '100%',
        height: '100%',
        borderRadius: 100,
        // borderColor: Colors.secondary,
        // borderWidth: 1,    
    },
    panda1: {
        position: 'absolute',
        bottom: 0,
        left: '5%',
        width: 100,
        height: 100,
        resizeMode: 'contain'
    },
});

export default EditProfile;
