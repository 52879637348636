import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { View, StyleSheet, ImageBackground, Image, Text, TouchableOpacity, ScrollView, SafeAreaView } from 'react-native';
import { FONTFAMILY, currentPlatform, isWeb, screenHeight, screenWidth } from '../../constants/Colors';
import back2 from '../../assets/background/back2.png';
import back2Dark from '../../assets/background/back2Dark.png';
import box1 from '../../assets/background/box1.png';
import box2 from '../../assets/background/box2.png';
import boxAr1 from '../../assets/background/boxAr1.png';
import boxAr2 from '../../assets/background/boxAr2.png';
import { mainStyle } from '../../mainstyle';
import { useAuth } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { customGet, get, post } from '../../WebService/RequestBuilder';
import globalConstants from '../../config/globalConstants';
import CustomLoader from '../../components/CustomLoader';
import { WebBackgroundTopColor } from '../../components/WebBackground';
import { storeJsonData } from '../../helper/GeneralStorage';

const SetCourseScreen = ({ navigation }) => {
    const { t } = useTranslation();
    const { currentTheme, currentThemeColor, lang, subUser, setCurrentSubUserCourse, setCurrentSubUserCourseImage, setCurrentCourseName } = useAuth();
    const [select, setSelect] = useState(null);
    const [courses, setCourses] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const backgroundCourse = { selectedBox: lang === 'ar' ? boxAr2 : box2, unSelectedBox: lang === 'ar' ? boxAr1 : box1 };

    useEffect(() => {
        const getCourses = async () => {
            try {
                const { data } = await customGet(globalConstants.general.courses);

                setCourses(data);
            } catch (error) {
                console.log("error getCourses: ", error);
            } finally {
                setIsLoading(false);
            }
        };

        getCourses();
    }, []);

    const submit = async (select) => {
        try {
            if (select) {
                const selected = courses.filter(item => item.id === select);
                if (selected.length > 0) {
                    setCurrentSubUserCourseImage(selected[0].image);
                    await storeJsonData(globalConstants.currentSubUserCourseImage, selected[0].image);
                    setCurrentCourseName(lang === 'ar' ? selected[0].name_ar : selected[0].name_en);

                    await storeJsonData(globalConstants.currentCourseName, lang === 'ar' ? selected[0].name_ar : selected[0].name_en);

                    const existCourse = subUser?.user_course?.filter(item => item.course_id === select);

                    if (existCourse.length > 0) {
                        const userCourse = existCourse[0];
                        setCurrentSubUserCourse(userCourse);

                        await storeJsonData(globalConstants.currentSubUserCourse, userCourse);

                        if (userCourse.reached_page === 'quick_exam' && +userCourse.current_question === 1) {
                            navigation.navigate('EnrollCourseScreen', { selected, descriptionCourse: selected[0][langKey], newCourse: false });
                        } else if (userCourse.reached_page === 'quick_exam' && +userCourse.current_question > 1) {
                            await post(globalConstants.chatGPT.userQuickExam, {
                                user_course_id: userCourse.id
                            }).then(({ data }) => {
                                navigation.navigate('QuickExamScreen', { userCourse: userCourse, questions: data });
                            });
                            return;
                        } else {
                            navigation.navigate('MainStack');
                        }
                        return;
                    } else {
                        navigation.navigate('EnrollCourseScreen', { selected, newCourse: true, descriptionCourse: selected[0][langKey], newTitle: 'Enter_New_Course' });
                        return;
                    }
                }
            }
        } catch (error) {
            console.log("🚀 ~ file: SetCourseScreen.js ~ line 24 ~ submit ~ error", error);
        }
    };

    const langKey = useMemo(() => {
        switch (lang) {
            case 'ar':
                return 'description_ar';
            case 'es':
                return 'description_es';
            case 'fr':
                return 'description_fr';
            default:
                return 'description_en';
        }
    }, [lang]);

    const renderUserItem = (item, index) => (
        <TouchableOpacity key={`${index}`} onPress={() => submit(item.id)} style={styles.bocBtn}>
            <ImageBackground source={select == item.id ? backgroundCourse.selectedBox : backgroundCourse.unSelectedBox} style={[styles.box]} resizeMode='contain'>
                <View style={styles.boxLogoDiv}>
                    <Image source={{ uri: item.image }} style={{ width: 100, height: 100, resizeMode: 'contain' }} />
                </View>
                <View style={styles.boxTextDiv}>
                    <Text style={[{ ...styles.boxTitle, color: select == item.id ? '#fff' : '#000' }, lang === 'ar'&&styles.boxAr]}>{lang == 'ar' && item?.name_ar ? item.name_ar : item?.name_en ? item.name_en : item.name}</Text>
                    <View style={{ width: isWeb ? 150 : 180 }}>
                        <Text style={[{ ...styles.boxDesc, color: select == item.id ? '#fff' : '#000' }, lang === 'ar'&&styles.boxAr]} numberOfLines={4}>{item[langKey]}</Text>
                    </View>
                </View>
            </ImageBackground>
        </TouchableOpacity>
    );

    if (isLoading) {
        return (
            <CustomLoader text={t('Loading_courses')} />
        );
    }

    const pageContent = () => {
        const rows = [];
        for (let i = 0; i < courses.length; i += 2) {
            rows.push(
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', maxWidth: screenWidth / 2, width: screenWidth / 2 }} key={i}>
                    {currentPlatform === 'web' && renderUserItem(courses[i], i)}
                    {currentPlatform === 'web' && courses[i + 1] && renderUserItem(courses[i + 1], i + 1)}
                    {currentPlatform !== 'web' && (
                        <Fragment>
                            {renderUserItem(courses[i], i)}
                            {courses[i + 1] && renderUserItem(courses[i + 1], i + 1)}
                        </Fragment>
                    )}
                </View>
            );
        }

        return (
            <Fragment>
                <View style={styles.Div1}>
                    <Text style={{ ...styles.Div1Text, color: currentThemeColor.primaryTextColor }}>{t("Choose_the_programming_language_you")}</Text>
                </View>
                <View style={styles.Div2}>
                    {currentPlatform === 'web' ? rows : courses.map(renderUserItem)}
                </View>
            </Fragment>
        );
    };

    return (
        currentPlatform === 'web' ? (
            <WebBackgroundTopColor>
                {pageContent()}
            </WebBackgroundTopColor>
        ) : (
            <View style={mainStyle.mainContainer}>
                <ImageBackground source={currentTheme == 'dark' ? back2Dark : back2} style={{ width: screenWidth, height: screenHeight }} resizeMode='cover'>
                    <ScrollView>
                        {pageContent()}
                    </ScrollView>
                </ImageBackground>
            </View>
        )
    );
}

const styles = StyleSheet.create({
    Div1: {
        width: '100%',
        height: 170,
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center'
    },
    Div2: {
        width: currentPlatform === 'web' ? '100%' : '90%',
        alignSelf: 'center',
    },
    Div1Text: {
        textAlign: 'center',
        width: '80%',
        fontSize: 24,
        color: '#000',
        fontFamily: FONTFAMILY
    },
    bocBtn: {
        width: '98%',
        height: 230,
        // resizeMode: 'contain'
    },
    box: {
        width: currentPlatform === 'web' ? screenWidth / 2 : '100%',
        height: '100%',
        resizeMode: 'contain',
        flexDirection: 'row',
        marginBottom: 15,
        paddingHorizontal: 5,
        width: currentPlatform === 'web' ? 300 : '100%',
        alignSelf: 'center'
    },
    boxLogoDiv: {
        width: 125,
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    boxTextDiv: {
        width: '65%',
        height: '100%',
        justifyContent: 'center'
    },
    boxTitle: {
        fontSize: 27,
        marginBottom: 5,
        color: '#000',
        fontFamily: FONTFAMILY,
        width: isWeb ? 145 : 170,
    },
    boxDesc: {
        fontSize: 12,
        color: '#000',
        width: isWeb ? 300 : 170,
        alignSelf: 'center',
        fontFamily: FONTFAMILY,
    },
    boxAr: {
        marginRight: 10,
        textAlign: 'right'
    }
});

export default SetCourseScreen;
