import { Dimensions, Platform } from 'react-native'

export const screenHeight = Dimensions.get('window').height;
export const screenWidth = Dimensions.get('window').width;

export const currentPlatform = Platform.OS;
export const isIos = currentPlatform === 'ios' || currentPlatform === 'macos';

export const HeaderHeight = currentPlatform == 'ios' ? 95 : 75;
export const BOTTOMBARHEIGHT = currentPlatform == 'ios' ? 100 : 70;
export const FONTFAMILY = 'EBGaramond-VariableFont_wght'
export const SuperPixelFont = 'SuperPixel'
export const HalloweenFont = 'HalloweenSlimePersonalUse'

export const isWeb = currentPlatform == 'web'

export const Colors = {
    primary: '#C2FD21',
    primary2: '#bbeb38',
    secondary: '#3B8EDE',
    secondary2: '#3D89D2',
    tertiary: '#F9F1E9',
    btnColor: "#8C52FF",
    mainBackground: '#fff',
    pTxt: '#0C243E',
    sTxt: '#888888',
    ellipseColor: '#FAF8BD',
    ellipseColor2: '#C1FD21',
    black: '#000000',
    lightGray: '#8F8F8F',
    greenColor: '#5cb85c',
};


export const darkTheme = {
    primaryTextColor: '#ffff',
    headerText: '#fff',
    textColor: 'red',
    box: '#2B2F30',
    mainBackground: '#2B2F30',
    mainBackground2: '#2B2F30',
    backgroundToggleRank: 'black',
    webBackground: '#3B8EDE',
    webMainBackground: '#2b2f30',
    webProgressBackground: '#fff',
};

export const lightTheme = {
    primaryTextColor: '#000',
    headerText: '#000',
    textColor: 'black',
    box: '#ffff',
    mainBackground: Colors.tertiary,
    mainBackground2: Colors.secondary,
    backgroundToggleRank: 'gray',
    webBackground: '#c2fd21',
    webMainBackground: '#fff',
    webProgressBackground: '#c2fd21',
};

export const colors = {
    key: '#606B83',
    inputBox: '#6B718044',
    Modal: '#82a7c9',
    darkOverlayColor: 'rgba(0, 0, 0, 0.4)',
    light: '#EEEEEE',
    FrameColor: '#455',
    shapeColor: '#ff9945',
    wordBox: 'powderblue',
}