import { View, StyleSheet, ImageBackground, Image, TouchableOpacity, ScrollView, Text } from 'react-native';
import { Colors, FONTFAMILY, isWeb, screenHeight, screenWidth } from '../../constants/Colors';
import back2 from '../../assets/background/back2.png';
import back2Dark from '../../assets/background/back2Dark.png';
import panda9 from '../../assets/panda/panda9.png';
import panda92 from '../../assets/panda/panda9-2.png';
import { mainStyle } from '../../mainstyle';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/AuthContext';
import Feather from 'react-native-vector-icons/Feather';
import userP from '../../assets/background/userP.png';
import { Fragment, useEffect, useState } from 'react';
import CustomLoader from '../../components/CustomLoader';
import plus from '../../assets/icons/plus.png';
import { WebBackgroundTopColor } from '../../components/WebBackground';

const MultiUsersSelect = ({ navigation }) => {
    const { t } = useTranslation();
    const { currentTheme, lang, user , adminConfig, isLoading} = useAuth();

    const users = user.user_info;
    const [maxProfiles, setMaxProfiles] = useState(adminConfig?.max_profiles ? adminConfig?.max_profiles : 5)

    useEffect(() => {
        if(!isLoading) setMaxProfiles(adminConfig?.max_profiles ? adminConfig?.max_profiles : 5)
    }, [adminConfig])

    const backgroundImageSource = currentTheme == 'dark' ? back2Dark : back2;
    const pandaImageSource = lang == 'ar' ? panda92 : panda9;

    if(isLoading) {
        return (
            <CustomLoader text={t('Loading')} />
        )
    }

    const renderUserItem = (item, index) => (
        <TouchableOpacity key={index} style={styles.userItem} onPress={() => { Submit(item) }}>
            <Image source={item?.attachment ? {uri: item.attachment} : userP} style={styles.userImage} />
            <Text style={styles.userName}>{item.name}</Text>
        </TouchableOpacity>
    );

    const Submit = (item) => {
        navigation.navigate('SubUserLogin', { subUserId: item.id, subUserName: item.name });
    }

    const pageContent = () => {
        return (
            <Fragment>
                <View style={styles.inputsContainer}>
                        <View style={styles.userContainer}>
                            {users.map(renderUserItem)}
                            {users.length < maxProfiles &&
                                <TouchableOpacity style={styles.addUserButton} onPress={() => { navigation.navigate('AddNewSubUser') }}>
                                    {!isWeb&&<Feather name="plus" size={60} />}
                                    {isWeb&&<Image source={plus} style={styles.plus} />}
                                </TouchableOpacity>
                            }
                        </View>
                    </View>
            </Fragment>
        )
    }

    return (
        isWeb ? (
            <WebBackgroundTopColor>
                {pageContent()}
            </WebBackgroundTopColor>
        ) :<View style={mainStyle.mainContainer}>
            <ImageBackground
                source={backgroundImageSource}
                style={styles.backgroundImage}
                resizeMode='cover'>
                <ScrollView contentContainerStyle={styles.scrollViewContent}>
                    {pageContent()}
                </ScrollView>
            </ImageBackground>
            <Image source={pandaImageSource} style={{ ...styles.pandaImage, alignSelf: lang == 'ar' ? 'flex-start' : 'flex-end' }} />
        </View >
    );
};

const styles = StyleSheet.create({
    backgroundImage: {
        width: screenWidth,
        height: screenHeight,
        justifyContent: 'center'
    },
    scrollViewContent: {
        flexGrow: 1
    },
    inputsContainer: {
        width: isWeb ? (screenWidth / 3) + 60 : '90%',
        height: '100%',
        justifyContent: 'center',
        alignSelf: 'center',
        paddingVertical: 20
    },
    userContainer: {
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    userItem: {
        width: 150,
        height: 150,
        backgroundColor: Colors.secondary,
        marginBottom: 15,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'space-around',
        paddingTop: 15
    },
    userImage: {
        width: 70,
        height: 70,
        resizeMode: 'cover',
        borderRadius: 100,
        zIndex: 10
    },
    userName: {
        fontSize: 18,
        fontFamily: FONTFAMILY,
        color: '#ffff'
    },
    addUserButton: {
        width: 150,
        height: 150,
        backgroundColor: Colors.lightGray,
        marginBottom: 15,
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center'
    },
    pandaImage: {
        ...mainStyle.panda9,

    },
    plus: {
        width: 50,
        height: 50,

    }
});

export default MultiUsersSelect;
