import React, { useCallback, useState } from "react";
import { View, StyleSheet, Text, Image, Dimensions, ImageBackground, ScrollView, Modal, KeyboardAvoidingView } from "react-native";
import Header from "../../../components/header";
import { Colors, FONTFAMILY, isWeb, screenHeight, screenWidth } from "../../../constants/Colors";
import CustomButton from "../../../components/customButton";
import { mainStyle } from "../../../mainstyle";
import check from '../../../assets/icons/check.png';
import back5 from '../../../assets/background/back5.png';
import back5Dark from '../../../assets/background/back5Dark.png';
import Input from "../../../components/input";
import DatePicker from 'react-native-modern-datepicker';
import { useAuth } from "../../../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import CustomAlert from "../../../components/customAlert";
import { WebBackgroundTopColor } from "../../../components/WebBackground";

const SubscriptionScreen3 = ({ route, navigation }) => {
    const { currentTheme, lang, userAddsubscription, currentThemeColor } = useAuth();
    const { t } = useTranslation();
    const item = route ? route?.params?.item : false;
    const TotalPriceAfterTax = route ? route?.params?.TotalPriceAfterTax : false;
    const promoCode = route ? route?.params?.promoCode : false;

    const [modalVisible, setModalVisible] = useState(false);
    const [selectedDate, setSelectedDate] = useState('');
    const [card_number, setCard_number] = useState('');
    const [holder_name, setHolder_name] = useState('');
    const [cvc, setCvc] = useState('');
    const [alertData, setAlertData] = useState(null);

    const handleDateChange = useCallback((date) => {
        setSelectedDate(date);
        setModalVisible(false);
    }, []);

    const flexDirection = lang === 'ar' ? 'row-reverse' : 'row';

    const showAlert = useCallback((message, type) => {
        setAlertData({ message, type });
    }, []);

    const hideAlert = useCallback(() => {
        setAlertData(null);
    }, []);

    const submit = useCallback(async () => {
        if (!card_number || card_number.length !== 12) return showAlert(t("Please_enter_a_valid_card_number"), 'error');
        if (!holder_name) return showAlert(t("Please_enter_the_card_holder_name"), 'error');
        if (!cvc) return showAlert(t("Please_enter_the_CVC"), 'error');
        if (!selectedDate) return showAlert(t("Please_select_the_expiry_date"), 'error');

        try {
            await userAddsubscription(item.id, selectedDate, card_number, holder_name, cvc, promoCode);
            showAlert(t("Subscriptions_Form_was_Submitted_Successfully"), 'success');
            setTimeout(() => {
                navigation.navigate('MainSettingsScreen')
            }, 500);
        } catch (error) {
            console.log('Login Error:', error);
        }
    }, [item.id, selectedDate, card_number, holder_name, cvc, showAlert]);

    const pageContent = () => {
        return (
            <>
            <KeyboardAvoidingView>
                        {!isWeb&&<Header title={t('Subscription')} />}
                        <View style={styles.inputsContainer}>
                            <View style={styles.headerView}>
                            <Image source={check} style={styles.check} />
                            <View style={styles.titleContainer}>
                                <Text style={styles.title}>{item.title}</Text>
                                <Text style={[styles.subtitle, {color: currentThemeColor.headerText }]}>{item.subtitle} {"Plan"}</Text>
                            </View>
                            </View>
                            <View style={styles.card}>
                                <View style={{ ...styles.cardRow, flexDirection }}>
                                    <Text style={styles.label1}>{t("Total_Price")}</Text>
                                    <Text style={styles.label2}>$ {TotalPriceAfterTax}</Text>
                                </View>

                                <View style={{ width: '90%', height: 1, backgroundColor: '#000', borderRadius: 99, alignSelf: 'center', marginVertical: 10 }} />

                                <Input lable={t('Card_Number')} placeholder={t('Card_Number')} keyboardType='decimal-pad' maxLength={12} lableStyle={{ color: '#000' }} onChange={setCard_number} />
                                <Input lable={t('Card_Holder_Name')} placeholder={t('Card_Holder_Name')} lableStyle={{ color: '#000' }} onChange={setHolder_name} />
                                <View style={{ width: '100%', flexDirection, justifyContent: 'space-between' }}>
                                    <View style={{ width: '48%' }}>
                                        <Text style={{ fontSize: 22, color: '#000', fontFamily: FONTFAMILY }}>{t("Expiry_Date")}</Text>
                                        <CustomButton btnStyle={{ width: '100%', backgroundColor: '#D9D9D9', height: 55 }} textStyle={{ fontSize: 17, color: '#6d6d6d', textAlign: 'left', marginRight: 20 }} text={selectedDate ? selectedDate : t("Expiry_Date")} onPress={() => setModalVisible(true)} />
                                    </View>
                                    <Input lable='CVC' placeholder='***' type='short' keyboardType='decimal-pad' maxLength={5} onChange={setCvc} lableStyle={{ color: '#000' }} />
                                </View>
                            </View>

                            <View style={{ height: '20%', justifyContent: 'flex-end', marginVertical: 15,  }}>
                                <CustomButton btnStyle={{ width: isWeb ? (screenWidth / 2) : '96%', backgroundColor: Colors.secondary }} textStyle={{}} text={t('Continue')} onPress={submit} />
                            </View>
                        </View>
                    </KeyboardAvoidingView>
                    <Modal
                visible={modalVisible}
                animationType="slide"
                transparent={true}
                onRequestClose={() => setModalVisible(false)}
            >
                <View style={{ flex: 2, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <View style={{ backgroundColor: 'white', padding: 20, borderRadius: 10, width: '90%', height: 400 }}>
                        <DatePicker
                            style={{ height: '100%', width: '100%' }}
                            mode="monthYear"
                            options={{
                                defaultFont: 'Shabnam-Light',
                                headerFont: 'Shabnam-Medium'
                            }}
                            selectorStartingYear={2000}
                            onMonthYearChange={handleDateChange}
                        />
                    </View>
                </View>
            </Modal>
            <CustomAlert title={alertData?.message} type={alertData?.type} isVisible={!!alertData} onHide={hideAlert} />
                    </>
        )
    }

    return (
        isWeb ? <WebBackgroundTopColor >
        <Header title={t('Subscription')} />

        {pageContent()}
    </WebBackgroundTopColor> : <View style={mainStyle.mainContainer}>
            <ImageBackground source={currentTheme == 'dark' ? back5Dark : back5} style={{ width: screenWidth, height: screenHeight }} resizeMode='cover'>
                <ScrollView>
                    {pageContent()}
                </ScrollView>
            </ImageBackground>
            
        </View>
    );
};

export default SubscriptionScreen3;


const styles = StyleSheet.create({
    inputsContainer: {
        width: '90%',
        alignSelf: 'center',
        paddingVertical: 40,
        paddingBottom: 100
      },
      headerView: {
        width: isWeb ? (screenWidth / 2) : '100%',
        height: '12%',
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'center'
      },
      check: {
        width: 65,
        height: 65,
        resizeMode: 'contain'
      },
      titleContainer: {
        paddingHorizontal: 15,
      },
      title: {
        color: '#fff',
        fontSize: 22
      },
      subtitle: {
        color: '#fff',
        fontSize: 19,
        marginBottom: isWeb ? 0 : 30
      },
      card: {
        zIndex: 99,
        width: isWeb ? (screenWidth / 2) : '100%',
        height: '68',
        backgroundColor: Colors.tertiary,
        alignSelf: 'center',
        borderRadius: 12,
        marginTop: 15,
        padding: 20,
        paddingTop: 30,
        position: 'relative',
        zIndex: 999
      },
      cardRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 13
      },
      label1: {
        fontSize: 19,
        color: Colors.pTxt
      },
      label2: {
        fontSize: 19,
        color: Colors.pTxt
      }      
})
