import React, { useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Modal, TextInput, Image, ActivityIndicator } from 'react-native';
import { FONTFAMILY } from '../constants/Colors';
import CustomButton from './customButton';
import panda1 from '../assets/panda/panda1.png';
import { t } from 'i18next';
import { post } from '../WebService/RequestBuilder';
import globalConstants from '../config/globalConstants';
const AskMrCode = ({ currentCourseName, lang }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [question, setQuestion] = useState('');
    const [loading, setLoading] = useState(false);
    const [answer, setAnswer] = useState('');

    const handleAsk = async () => {
        setLoading(true);
        setAnswer('');

        const { data } = await post(globalConstants.chatGPT.askMrcode, {
            question: question,
            subject: currentCourseName,
            language: lang
        });
        if (data) {
            setAnswer(data.answer);
        } else {
            setAnswer(t('not-related'));
        }
        setLoading(false);
    };

    const handleAskAnother = () => {
        setQuestion('');
        setAnswer('');
        setLoading(false);
    };

    return (
        <View style={styles.container}>
            <TouchableOpacity
                style={styles.askButton}
                onPress={() => setModalVisible(true)}
            >
                <Image source={panda1} style={styles.panda1} />
                <Text style={styles.askButtonText}>{t('ask-mr-code')}</Text>
            </TouchableOpacity>

            <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
                onRequestClose={() => setModalVisible(false)}
            >
                <View style={styles.modalContainer}>
                    <View style={styles.modalView}>
                        <Text style={styles.modalTitle}>{t('ask-mr-code')}</Text>

                        {loading ? (
                            <ActivityIndicator size="large" color="#3da2f5" />
                        ) : answer ? (
                            <>
                                <Text style={styles.answerText}>{answer}</Text>
                                <CustomButton
                                    text={t("Ask Another Question")}
                                    onPress={handleAskAnother}
                                    btnStyle={styles.askAnotherButton}
                                />
                                <CustomButton
                                    text={t("back")}
                                    onPress={() => setModalVisible(false)}
                                    btnStyle={styles.backButton}
                                />
                            </>
                        ) : (
                            <>
                                <TextInput
                                    style={styles.input}
                                    placeholder={t("What's your question")}
                                    value={question}
                                    onChangeText={setQuestion}
                                />
                                <CustomButton
                                    isSubmit
                                    text={t("ask")}
                                    onPress={handleAsk}
                                    btnStyle={styles.submitButton}
                                />
                                <CustomButton
                                    text={t("Cancel")}
                                    onPress={() => setModalVisible(false)}
                                    btnStyle={styles.cancelButton}
                                />
                            </>
                        )}
                    </View>
                </View>
            </Modal>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        top: 20,
        right: 20,
        zIndex: 1000,
    },
    askButton: {
        backgroundColor: '#3da2f5',
        padding: 10,
        borderRadius: 20,
        alignItems: 'center',
        justifyContent: 'center',
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 11 },
        shadowOpacity: 0.57,
        shadowRadius: 15.19,
        elevation: 19,
    },
    askButtonText: {
        color: '#fff',
        fontFamily: FONTFAMILY,
        fontSize: 14,
        marginTop: 5,
    },
    panda1: {
        width: 40,
        height: 40,
        resizeMode: 'contain',
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#0707078c',
    },
    modalView: {
        backgroundColor: "white",
        borderRadius: 20,
        paddingVertical: 40,
        paddingHorizontal: 30,
        alignItems: "center",
        justifyContent: 'center',
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        width: '80%',
    },
    modalTitle: {
        fontFamily: FONTFAMILY,
        fontSize: 20,
        marginBottom: 20,
        color: '#000',
    },
    input: {
        width: '100%',
        padding: 10,
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 10,
        marginBottom: 20,
        fontFamily: FONTFAMILY,
    },
    submitButton: {
        width: '100%',
        backgroundColor: '#3da2f5',
        paddingVertical: 10,
        borderRadius: 10,
        marginBottom: 10,
    },
    cancelButton: {
        width: '100%',
        backgroundColor: '#ccc',
        paddingVertical: 10,
        borderRadius: 10,
    },
    answerText: {
        fontFamily: FONTFAMILY,
        fontSize: 18,
        marginBottom: 20,
        color: '#000',
        textAlign: 'center',
    },
    askAnotherButton: {
        width: '100%',
        backgroundColor: '#3da2f5',
        paddingVertical: 10,
        borderRadius: 10,
    },
    backButton: {
        width: '100%',
        backgroundColor: '#ccc',
        paddingVertical: 10,
        borderRadius: 10,
        marginTop: 10,
    }
});

export default AskMrCode;
