import React, { useEffect, useCallback, useState, useRef } from 'react';
import { ActivityIndicator, I18nManager, View, StatusBar, Image } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import AuthStack from './navigation/AuthStack';
import SetCourseStack from './navigation/setCourseStack';
import MainStack from './navigation/MainStack';
import { Colors, currentPlatform, screenHeight, screenWidth } from './constants/Colors';
import * as Font from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import splash from '../assets/splash.png'
import logo from '../assets/logo.jpg'
import MultiUserStack from './navigation/MultiUserStack';
import GameStack from './navigation/GameStack';
import * as Linking from "expo-linking";

const Stack = createStackNavigator();


export default function AppContent() {
  const prefix = Linking.createURL("/");

  const linking = {
    prefixes: [prefix],
    config: {
      screens: {
        AuthStack: {
          screens: {
            OnboardingScreen: 'onboarding',
            LoginScreen: 'login',
            SignupScreen: 'signup',
            ForgotPasswordScreen: 'forgot-password',
            EmailVerify: 'email-verify',
            CheckUserExperienceScreen: 'check-user-experience',
            VerifyOtpScreen: 'verify-otp',
            SetNewPasswordScreen: 'set-new-password',
          },
        },
        SetCourseStack: {
          screens: {
            CheckUserExperienceScreen: 'check-experience',
            SetCourseScreen: 'set-course',
            EnrollCourseScreen: 'enroll-course',
            QuickExamScreen: 'quick-exam',
            StartCodingIntroductionScreen: 'start-coding-introduction',
            AlgorithmsScreen: "AlgorithmsScreen",
            CodingIntroductionScreen: 'coding-introduction',
            FirstGameScreen: 'panda-home-game',
            UserTutorialsScreen: 'tutorials',
            ExamScreen: 'exam',
            QuestionsResultsScreen: 'questions-results'
          }
        },
        MainStack: {
          screens: {
            BottomTabNavigator: {
              screens: {
                HomeScreen: 'home',
                TopRatedScreen: 'top-rated',
                UserProgressScreen: 'user-progress',
                MainSettingsScreen: 'settings',
              },
            },
            EditProfile: 'edit-profile',
            FeedBackScreen: 'feedback',
            SettingsScreen: 'settings-screen',
            SubscriptionScreen: 'subscription',
            SubscriptionScreen2: 'subscription2',
            SubscriptionScreen3: 'subscription3',
            ChangePasswordScreen: 'change-password',
          },
        },
        MultiUserStack: {
          screens: {
            MultiUsersSelect: 'multi-users-select',
            SubUserLogin: 'sub-user-login',
            AddNewSubUser: 'add-new-sub-user',
          },
        },
        GameStack: {
          screens: {
            ChoseYourCharacter: 'choose-your-character',
            GardenLevelScreen: 'garden-level',
            KitchenGameScreen: 'kitchen-game',
          },
        },
      },
    },
  };
  const [fontLoaded, setFontLoaded] = useState(false);
  const { token, isLoggedIn, checkLang, isLoading, subUser, accountType, isLogout } = useAuth();
  const [appIsReady, setAppIsReady] = useState(false);
  const [loadingSetRoute, setLoadingSetRoute] = useState(true);
  const [initialRoute, setInitialRoute] = useState('AuthStack');

  useEffect(() => {
    const setRoute = () => {
      try {
        if (isLoggedIn && token && subUser) {
          setInitialRoute('SetCourseStack')
        } else if (isLoggedIn && token && !subUser && accountType === 'family') {
          setInitialRoute('MultiUserStack')
        } else {
          setInitialRoute('AuthStack');
        }
      } catch (error) {
        console.log("error appContent: ", error);
      } finally {
        setLoadingSetRoute(false)
      }
    }

    if (!isLoading && !isLogout) setRoute();
    if (isLogout) setInitialRoute('AuthStack');
  }, [isLoggedIn, token, subUser, accountType, isLoading]);

  const rootViewRef = useRef(null);

  useEffect(() => {
    I18nManager.forceRTL(false);
    checkLang();
  }, [checkLang]);

  useEffect(() => {
    async function loadFont() {
      try {
        await Font.loadAsync({
          'EBGaramond-VariableFont_wght': require('./assets/fonts/EBGaramond-VariableFont_wght.ttf'),
          'SuperPixel': require('./assets/fonts/SuperPixel-m2L8j.ttf'),
          'HalloweenSlimePersonalUse': require('./assets/fonts/HalloweenSlimePersonalUse-4B80D.otf'),
        });
        setFontLoaded(true);
      } catch (error) {
        console.log("App error: ", error);
      } finally {
        setAppIsReady(true);
      }
    }

    loadFont();
  }, []);

  const onLayoutRootView = useCallback(async () => {
    if (appIsReady) {
      await SplashScreen.hideAsync();
    }
  }, [appIsReady]);

  if (!fontLoaded) {
    return null;
  }

  if (!appIsReady || isLoading || loadingSetRoute) {
    return (
      <View style={{ flex: 1, justifyContent: 'flex-end', alignItems: 'center' }} ref={rootViewRef} onLayout={onLayoutRootView}>
        <Image source={currentPlatform === 'web' ? logo : splash} style={{ width: screenWidth, height: screenHeight, resizeMode: 'cover' }} />
      </View>
    );
  }

  return (
    <NavigationContainer linking={linking}>
      <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName={initialRoute}>
        <Stack.Screen name="AuthStack" component={AuthStack} />
        <Stack.Screen name="SetCourseStack" initialParams={{ checkRoute: true }} component={SetCourseStack} />
        <Stack.Screen name="MainStack" component={MainStack} />
        <Stack.Screen name="MultiUserStack" component={MultiUserStack} />
        <Stack.Screen name="GameStack" component={GameStack} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
