import { useEffect, useRef } from "react";
import { Text, TouchableOpacity, View, Animated, Modal, StyleSheet, Easing } from "react-native";
import { colors } from "../constants/Colors";
import { t } from "i18next";

const CheatModal = ({ cheatVisible, toggleHint, wordData }) => {
    const fadeAnim = useRef(new Animated.Value(0)).current;
    const scaleAnim = useRef(new Animated.Value(0.9)).current;

    const getEmoji = () => {
        return '🤔';
    };

    useEffect(() => {
        if (cheatVisible) {
            Animated.timing(fadeAnim, {
                toValue: 1,
                duration: 500,
                easing: Easing.out(Easing.exp),
                useNativeDriver: true,
            }).start();
            Animated.spring(scaleAnim, {
                toValue: 1,
                friction: 3,
                tension: 40,
                useNativeDriver: true,
            }).start();
        } else {
            Animated.timing(fadeAnim, {
                toValue: 0,
                duration: 500,
                easing: Easing.in(Easing.exp),
                useNativeDriver: true,
            }).start();
            Animated.spring(scaleAnim, {
                toValue: 0.9,
                friction: 3,
                tension: 40,
                useNativeDriver: true,
            }).start();
        }
    }, [cheatVisible]);

    return (
        <Modal
            visible={cheatVisible}
            animationType="none"
            onRequestClose={toggleHint}
            transparent={true}
        >
            <View style={styles.modalBackdrop}>
                <Animated.View style={[styles.modalContainer, { opacity: fadeAnim, transform: [{ scale: scaleAnim }] }]}>
                    <Text style={styles.emoji}>{getEmoji()}</Text>
                    <Text style={styles.modalText}>{wordData?.func}</Text>
                    <TouchableOpacity onPress={toggleHint} style={styles.closeButton}>
                        <Text style={styles.closeButtonText}>{t('CLOSE')}</Text>
                    </TouchableOpacity>
                </Animated.View>
            </View>
        </Modal>
    );
};

export default CheatModal;

const styles = StyleSheet.create({
    modalBackdrop: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContainer: {
        width: '80%',
        backgroundColor: colors.hintBox,
        padding: 20,
        borderRadius: 10,
        alignItems: 'center',
    },
    emoji: {
        fontSize: 50,
        marginBottom: 20,
    },
    modalText: {
        fontSize: 20,
        fontWeight: '600',
        color: 'white',
        marginBottom: 20,
        textAlign: 'center',
    },
    closeButton: {
        paddingVertical: 10,
        paddingHorizontal: 20,
        backgroundColor: colors.hintButton,
        borderRadius: 5,
    },
    closeButtonText: {
        color: 'white',
        fontWeight: '600',
    },
});
