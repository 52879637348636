import { FONTFAMILY } from "../constants/Colors";
import { useEffect } from "react";
import { Text, StyleSheet, TouchableOpacity } from "react-native";

const CustomAlert = ({ title = "", isVisible, onHide, type = 'success' }) => {
 
    useEffect(() => {
        let timeoutId;
        if (isVisible) {
            timeoutId = setTimeout(() => {
                onHide();
            }, 3000);
        }
        return () => {
            clearTimeout(timeoutId);
        };
    }, [isVisible, onHide]);

    if (!isVisible) return null;

    const typeColor = () => {
        if (type == "success") return '#5cb85c'
        if (type == "error") return '#F32013'
    }

    return (
        <TouchableOpacity style={{ ...styles.container, backgroundColor: typeColor(), }} onPress={() => { onHide(); }}>
            <Text style={styles.title}>{title}</Text>
        </TouchableOpacity>
    )
}
export default CustomAlert;

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        width: '90%',
        height: 60,
        zIndex: 99,
        bottom: 5,
        alignSelf: 'center',
        borderRadius: 10,
        justifyContent: 'center',
        paddingHorizontal: 10
    },
    title: {
        color: '#fff',
        fontSize: 17,
        fontFamily: FONTFAMILY
    }
})