import React, { useEffect, useRef } from 'react';
import { StyleSheet, Text, TextInput, View, TouchableOpacity, Dimensions, Modal, Animated, Easing, ScrollView } from 'react-native';
import { colors } from '../../constants/Colors';
import { t } from 'i18next';
const { width } = Dimensions.get('window');
const scale = width / 375;

const FunctionEditor = ({ wordData, onSubmit, inputValue, setInputValue, hintVisible, toggleHint }) => {
    const fadeAnim = useRef(new Animated.Value(0)).current;
    const scaleAnim = useRef(new Animated.Value(0.9)).current;

    const getEmoji = () => {
        switch (wordData.status) {
            case 'happy':
                return '😊';
            case 'neutral':
                return '😐';
            case 'sad':
                return '😢';
            default:
                return '🤔';
        }
    };

    useEffect(() => {
        if (hintVisible) {
            Animated.timing(fadeAnim, {
                toValue: 1,
                duration: 500,
                easing: Easing.out(Easing.exp),
                useNativeDriver: true,
            }).start();
            Animated.spring(scaleAnim, {
                toValue: 1,
                friction: 3,
                tension: 40,
                useNativeDriver: true,
            }).start();
        } else {
            Animated.timing(fadeAnim, {
                toValue: 0,
                duration: 500,
                easing: Easing.in(Easing.exp),
                useNativeDriver: true,
            }).start();
            Animated.spring(scaleAnim, {
                toValue: 0.9,
                friction: 3,
                tension: 40,
                useNativeDriver: true,
            }).start();
        }
    }, [hintVisible]);

    const handleInputChange = (text) => {
        setInputValue(text);
    };

    const handleSubmit = () => {
        onSubmit(inputValue);
    };

    return (
        <ScrollView contentContainerStyle={styles.scrollContainer}>
            <View style={styles.container}>
                <Text style={styles.question}>{wordData.question}</Text>
                <View style={styles.editorContainer}>
                    <TextInput
                        style={styles.input}
                        placeholder={t('Type your function here')}
                        placeholderTextColor="#888"
                        value={inputValue}
                        onChangeText={handleInputChange}
                        multiline
                        autoCapitalize="none"
                        autoCorrect={false}
                    />
                </View>
                <TouchableOpacity style={styles.submitButton} onPress={handleSubmit}>
                    <Text style={styles.submitButtonText}>{t('Submit')}</Text>
                </TouchableOpacity>
            </View>

            <Modal
                visible={hintVisible}
                animationType="none"
                onRequestClose={toggleHint}
            >
                <View style={styles.modalBackdrop}>
                    <Animated.View style={[styles.modalContainer, { opacity: fadeAnim, transform: [{ scale: scaleAnim }] }]}>
                        <Text style={styles.emoji}>{getEmoji()}</Text>
                        <Text style={styles.modalText}>{wordData.hint}</Text>
                        <TouchableOpacity onPress={toggleHint} style={styles.closeButton}>
                            <Text style={styles.closeButtonText}>{t('CLOSE')}</Text>
                        </TouchableOpacity>
                    </Animated.View>
                </View>
            </Modal>
        </ScrollView>
    );
};

export default FunctionEditor;

const styles = StyleSheet.create({
    scrollContainer: {
        flexGrow: 1,
        padding: 15,
    },
    container: {
        backgroundColor: colors.wordBox,
        padding: 15,
        borderRadius: 10,
        marginVertical: 10,
        flexGrow: 1,
    },
    question: {
        fontSize: 16,
        fontWeight: '700',
        marginBottom: 10,
        color: colors.primaryText,
    },
    editorContainer: {
        backgroundColor: 'black',
        padding: 10,
        borderRadius: 8,
        marginBottom: 10,
        minHeight: 50,
    },
    input: {
        color: 'white',
        fontSize: 14,
        fontFamily: 'monospace',
        minHeight: 50,
        textAlignVertical: 'top',
        padding: 5,
    },
    submitButton: {
        backgroundColor: '#4CAF50',
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10,
    },
    submitButtonText: {
        color: 'white',
        fontSize: 16,
        fontWeight: '700',
    },
    modalBackdrop: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContainer: {
        width: '80%',
        backgroundColor: colors.hintBox,
        padding: 20,
        borderRadius: 10,
        alignItems: 'center',
    },
    emoji: {
        fontSize: 50,
        marginBottom: 20,
    },
    modalText: {
        fontSize: 20,
        fontWeight: '600',
        color: colors.hintText,
        marginBottom: 20,
        textAlign: 'center',
    },
    closeButton: {
        paddingVertical: 10,
        paddingHorizontal: 20,
        backgroundColor: colors.hintButton,
        borderRadius: 5,
    },
    closeButtonText: {
        color: colors.hintButtonText,
        fontWeight: '600',
    },
});
