import React, { Fragment, useEffect, useState, useRef } from 'react';
import { View, ImageBackground, Image, Text, ScrollView, TextInput } from 'react-native';
import { Colors, currentPlatform, isWeb, screenHeight, screenWidth } from '../../constants/Colors';
import { mainStyle, preventOutlineOnWeb } from '../../mainstyle';
import CustomButton from '../../components/customButton';
import back2 from '../../assets/background/back2.png';
import back2Dark from '../../assets/background/back2Dark.png';
import panda1 from '../../assets/panda/panda1.png';
import { useAuth } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { post } from '../../WebService/RequestBuilder';
import globalConstants from '../../config/globalConstants';
import { DraggableGrid } from 'react-native-draggable-grid';
import { WebBackgroundTopColor } from '../../components/WebBackground';
import drag from '../../assets/drag.png';
import circleFull from '../../assets/circleFull.png';
import circleEmpty from '../../assets/circleEmpty.png';
import { storeJsonData } from '../../helper/GeneralStorage';

const ExamScreen = ({ navigation, route }) => {
    const { t } = useTranslation();
    const { currentTheme, currentThemeColor, currentTutorials, setCurrentTutorials, currentSubUserCourse, setCurrentSubUserCourse, currentSubUserCourseImage, saveDailyPoints, lang } = useAuth();
    const { questions, current_question, user_course_id } = route?.params;

    const [qIndex, setQIndex] = useState(current_question - 1);
    const [currentQuestion, setCurrentQuestion] = useState({});
    const [userAnswer, setUserAnswer] = useState([]);
    const [buttonClickable, setButtonClickable] = useState(false);
    const [gridData, setGridData] = useState([]);
    const [enableScroll, setEnableScroll] = useState(true);
    const scrollViewRef = useRef(null);

    useEffect(() => {
        setQIndex(current_question - 1);
        setCurrentQuestion(questions[current_question - 1]);
    }, [questions, currentTutorials.current_tutorial, current_question]);

    useEffect(() => {
        if (currentQuestion && currentQuestion?.question_type === 'drag_drop') {
            const choicesArray = currentQuestion?.choices?.map((item, index) => {
                return { item, key: `${index}` };
            });
            setGridData(choicesArray);
        }
    }, [currentQuestion?.choices]);

    useEffect(() => {
        if (userAnswer.length > 0) {
            setButtonClickable(true);
        } else {
            setButtonClickable(false);
        }
    }, [userAnswer, currentQuestion]);

    const nextQ = async () => {
        try {
            await saveAnswer();

            let qCount = questions.length;
            if (qIndex + 1 == qCount && currentTutorials.tutorials.length == currentTutorials.current_tutorial) {
                const nextLevelData = await post(globalConstants.userCourses.nextLevel, {
                    user_course_id: user_course_id
                }).then((data) => {
                    setCurrentSubUserCourse(prev => ({
                        ...prev,
                        current_level: data.current_level,
                        reached_page: data.reached_page
                    }));

                    return data;
                });
                await storeJsonData(globalConstants.currentSubUserCourse, { ...currentSubUserCourse, current_level: nextLevelData.current_level, reached_page: nextLevelData.reached_page });

                navigation.navigate('QuestionsResultsScreen', { tutorialId: currentQuestion?.user_level_tutorial_id, action: 'GameStack', isQuickExam: false, message: nextLevelData?.message });
                return;
            } else if (qIndex + 1 == qCount && currentTutorials.tutorials.length != currentTutorials.current_tutorial) {
                await post(globalConstants.userCourses.nextTutorial, {
                    tutorial_id: currentQuestion.user_level_tutorial_id,
                    user_course_id: user_course_id
                }).then(() => {
                    setCurrentTutorials(prev => ({
                        ...prev,
                        current_tutorial: prev.current_tutorial + 1
                    }));
                    setQIndex(1);
                    navigation.navigate('QuestionsResultsScreen', { tutorialId: currentQuestion?.user_level_tutorial_id, action: 'UserTutorialsScreen' });
                });
                return;
            } else if (userAnswer && qIndex + 1 < qCount) {
                setQIndex(qIndex + 1);
                await post(globalConstants.userCourses.nextQuestion, {
                    user_course_id: user_course_id
                });
            }
            if (isWeb) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            } else {
                scrollViewRef.current?.scrollTo({ y: 0, animated: true });
            }
        } catch (error) {
            console.log("error: ", error);
        }
    };

    useEffect(() => {
        let qCount = questions.length;
        if (qIndex < qCount) {
            let Question = questions[qIndex];
            setCurrentQuestion(Question);
        }
    }, [qIndex]);

    const onClickCheckAllCorrect = (input) => {
        setUserAnswer((previous) => {
            const updatedAnswers = [...previous];
            const index = updatedAnswers.indexOf(input);
            if (index !== -1) {
                updatedAnswers.splice(index, 1);
            } else {
                updatedAnswers.push(input);
            }
            return updatedAnswers;
        });
    };

    const saveAnswer = async () => {
        try {
            const response = await post(globalConstants.userCourses.saveAnswer, {
                user_tutorial_question_id: currentQuestion.id,
                answers: userAnswer,
                is_quick_exam: false
            });

            await saveDailyPoints();
            console.log("response in save answer", response);
        } catch (error) {
            console.log("saveAnswer error: ", error);
        } finally {
            setUserAnswer([]);
        }
    };

    const renderItem = (item) => {
        const imageSize = isWeb ? 30 : 40;
        const paddingLeftRight = lang === 'ar' ? { paddingRight: imageSize + 10 } : { paddingLeft: imageSize + 10 };

        return (
            <View style={{ position: 'relative' }} key={`${item.key}-v`}>
                <View style={{
                    flexDirection: 'row',
                    ...mainStyle.CustomButtonSubmit,
                    borderRadius: 5,
                    backgroundColor: Colors.btnColor,
                    width: isWeb ? (screenWidth / 2) : screenWidth - 60,
                    minHeight: isWeb ? 70 : 100
                }} key={item.key}>
                    <Text style={{ ...mainStyle.CustomButtonText, fontSize: 20, padding: 20, ...paddingLeftRight }}>{item.item}</Text>
                </View>
                <Image
                    source={drag}
                    style={[{
                        position: 'absolute',
                        top: '25%',
                        height: imageSize,
                        width: imageSize,
                        zIndex: 10,
                        margin: 0,
                    }, lang === 'ar' ? { right: 10 } : { left: 10 }]}
                />
            </View>
        );
    };

    const onDragRelease = (data) => {
        setGridData(data);
        const result = data?.map((item) => item.item);
        setUserAnswer(result);
        setEnableScroll(true);
    };

    const pageContent = () => {
        return (
            <Fragment>
                <View style={mainStyle.qmainDiv}>
                    {currentSubUserCourseImage && <Image source={{ uri: currentSubUserCourseImage }} style={{ width: 150, height: 150, resizeMode: 'contain', top: 40, marginBottom: 40, alignSelf: 'center' }} />}

                    <View style={{ ...mainStyle.questionsDiv1 }}>
                        < Text style={{ ...mainStyle.qDiv1Style, color: currentThemeColor.primaryTextColor }}>{t("Question")} {qIndex + 1}</Text>
                        <Text style={{ ...mainStyle.qDiv1Style, color: currentThemeColor.primaryTextColor }}>{t(currentQuestion.question_type)}</Text>
                        <Text style={[mainStyle.qDiv1Style, { color: currentThemeColor.primaryTextColor, fontSize: 18 }]}>{t(`${currentQuestion.question_type}_description`)}</Text>
                    </View>

                    <View style={{ ...mainStyle.questionsDiv2 }}>
                        <Text style={{ ...mainStyle.qDiv2Style, color: currentThemeColor.primaryTextColor }}>{currentQuestion.question ? currentQuestion.question : null}</Text>
                    </View>

                    <View style={{ ...mainStyle.questionsDiv3, height: '40%' }}>
                        {currentQuestion.question_type == 'true_false' && <View style={mainStyle.qDiv3Sub} >
                            <CustomButton btnStyle={{ width: '48%', backgroundColor: userAnswer == currentQuestion.choices[0] ? Colors.secondary : Colors.btnColor }} text={t(currentQuestion.choices[0]?.toLowerCase())} onPress={() => { setUserAnswer([currentQuestion.choices[0]]) }} />
                            <CustomButton btnStyle={{ width: '48%', backgroundColor: userAnswer == currentQuestion.choices[1] ? Colors.secondary : Colors.btnColor }} text={t(currentQuestion.choices[1]?.toLowerCase())} onPress={() => { setUserAnswer([currentQuestion.choices[1]]) }} />
                        </View>}

                        {currentQuestion.question_type == 'numeric' && <TextInput
                            style={{
                                color: '#000',
                                fontSize: 30,
                                backgroundColor: '#d9dddf',
                                ...mainStyle.CustomButtonSubmit,
                                borderRadius: 5,
                                width: 150,
                                fontSize: 20,
                                textAlign: 'center',
                                ...preventOutlineOnWeb
                            }}
                            onChangeText={(text) => { setUserAnswer([text]) }}
                            placeholder={t("Number")}
                            placeholderTextColor="black"
                            keyboardType='numeric'
                        />}

                        {currentQuestion.question_type === 'multiple_choice' && (
                            <View style={mainStyle.choices}>
                                {currentQuestion?.choices?.map((choice, index) => {
                                    const isSelected = userAnswer[0] === choice;

                                    return (
                                        <CustomButton
                                            textStyle={{ fontSize: 20, padding: 20 }}
                                            key={`${index}`}
                                            btnStyle={{
                                                width: currentPlatform === 'web' ? screenWidth / 2 : '98%',
                                                marginBottom: 20,
                                                backgroundColor: isSelected ? Colors.secondary : Colors.btnColor
                                            }}
                                            text={choice}
                                            onPress={() => { setUserAnswer([choice]) }}
                                        />
                                    );
                                })}
                            </View>
                        )}

                        {currentQuestion.question_type == 'check_all_correct' && (
                            <View style={mainStyle.choices}>
                                {currentQuestion?.choices?.map((choice, index) => {
                                    const isSelected = userAnswer.includes(choice);

                                    return (
                                        <View style={{ position: 'relative' }} key={`${index}-v`}>
                                            <CustomButton
                                                textStyle={{ fontSize: 20, paddingVertical: 20, paddingHorizontal: 40 }}
                                                key={`${index}`}
                                                btnStyle={{
                                                    width: currentPlatform === 'web' ? screenWidth / 2 : '98%',
                                                    marginBottom: 20,
                                                    backgroundColor: isSelected ? Colors.secondary : Colors.btnColor
                                                }}
                                                text={choice}
                                                onPress={() => { onClickCheckAllCorrect(choice) }}
                                            />
                                            <Image
                                                source={isSelected ? circleFull : circleEmpty}
                                                style={[{
                                                    position: 'absolute',
                                                    top: '30%',
                                                    right: 20,
                                                    height: 10,
                                                    width: 10,
                                                    zIndex: 10,
                                                    margin: 0,
                                                }, lang === 'ar' ? { right: isWeb ? '22%' : 30 } : { left: isWeb ? '22%' : 30 }]}
                                            />
                                        </View>
                                    );
                                })}
                            </View>
                        )}

                        {gridData?.length > 0 && currentQuestion.question_type == 'drag_drop' && <View style={[{ minHeight: currentQuestion.choices.length * (currentPlatform === 'web' ? 120 : 150) }]}><DraggableGrid
                            numColumns={1}
                            renderItem={renderItem}
                            data={gridData}
                            onDragRelease={onDragRelease}
                            itemHeight={currentPlatform === 'web' ? 120 : 150}
                            onDragItemActive={() => { setEnableScroll(false) }}
                            style={mainStyle.choices}
                        /></View>}

                        <CustomButton isSubmit enable={buttonClickable} btnStyle={{ width: currentPlatform === 'web' ? screenWidth / 2 : '98%', marginTop: 50, marginBottom: 50, padding: 20 }} text={t('Submit')} onPress={nextQ} />
                    </View>

                </View>
                <View style={{ flex: 1, zIndex: 10 }}>
                    <Image source={panda1} style={mainStyle.panda1} />
                </View>
            </Fragment>
        );
    };

    return (
        currentPlatform === 'web' ? (
            <WebBackgroundTopColor>
                {pageContent()}
            </WebBackgroundTopColor>
        ) : (
            <View style={mainStyle.mainContainer}>
                <ImageBackground source={currentTheme == 'dark' ? back2Dark : back2} style={{ width: screenWidth, height: screenHeight }} resizeMode='cover'>
                    <ScrollView ref={scrollViewRef} scrollEnabled={enableScroll}>
                        {pageContent()}
                    </ScrollView>
                </ImageBackground>
            </View>
        )
    );
};


export default ExamScreen;
