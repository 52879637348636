import { View, StyleSheet, ImageBackground, Image, Text, TouchableOpacity, Share, ScrollView } from 'react-native';
import { BOTTOMBARHEIGHT, Colors, currentPlatform, isWeb, screenHeight, screenWidth } from '../../../constants/Colors';
import back4 from '../../../assets/background/back4.png';
import back4Dark from '../../../assets/background/back4Dark.png';
import userP from '../../../assets/background/userP.png';
import panda9 from '../../../assets/panda/panda9.png';
import panda92 from '../../../assets/panda/panda9-2.png';
import CustomButton from '../../../components/customButton';
import { mainStyle } from '../../../mainstyle';
import { useAuth } from '../../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { WebBackgroundTopColor } from '../../../components/WebBackground';

const MainSettingsScreen = ({ navigation, route }) => {

    const { t } = useTranslation();
    const { currentTheme, lang, logout, subUserImage, accountType, logoutSubAccount, setIsLogout, currentThemeColor } = useAuth();

    const onShare = async () => {
        try {
            const result = await Share.share({
                message: t('Share Mr.Code With Your Friends'),
            });
            if (result.action === Share.sharedAction) {
                if (result.activityType) {
                    // shared with activity type of result.activityType
                } else {
                    // shared
                }
            } else if (result.action === Share.dismissedAction) {
                // dismissed
            }
        } catch (error) {
            Alert.alert(error.message);
        }
    };

    const logoutSubmit = async () => {
        try {
            setIsLogout(true);
            await logout()
        } catch (error) {
            console.log('error logoutSubmit', error);
        } finally {
            if (isWeb) {
                window.location.href = '/login';
            } else {
                navigation.navigate('AuthStack');
            }
        }
    }

    const pageContent = () => {
        return (
            <>
                <View style={styles.inputsContainer}>
                        <View style={styles.ImageDiv}>
                            <Image source={subUserImage ? { uri: subUserImage } : userP} style={styles.Image} />
                        </View>
                        <View style={{}}>
                            <CustomButton btnStyle={styles.buttons} text={t('Edit_Profile')} onPress={() => { navigation.navigate('EditProfile') }} />
                            <CustomButton btnStyle={styles.buttons} text={t('Send_Feedback')} onPress={() => { navigation.navigate('FeedBackScreen') }} />
                            <CustomButton btnStyle={styles.buttons} text={t('Share_With_Friends')} onPress={onShare} />
                            <CustomButton btnStyle={styles.buttons} text={t('Settings')} onPress={() => { navigation.navigate('SettingsScreen') }} />
                            <CustomButton btnStyle={styles.buttons} text={t('Subscription')} onPress={() => { navigation.navigate('SubscriptionScreen') }} />
                            {/* <CustomButton btnStyle={styles.buttons} text={t('Play_The_Game')} onPress={() => { navigation.navigate('GameStack', {checkRoute:true}) }} /> */}
                            <CustomButton btnStyle={styles.buttons} text={t('Go_To_Courses')} onPress={() => { 
                                if(isWeb) {
                                    window.location.href = '/set-course';
                                } else {
                                    navigation.navigate('SetCourseScreen', {checkRoute:true}) 
                                }
                            }} />

                            <TouchableOpacity onPress={logoutSubmit} style={{ width: '50%', padding: 10, marginBottom: accountType == 'family' ? 0 : 60, alignSelf: 'center', justifyContent: 'center', alignItems: 'center', marginTop: 30 }}>
                                <Text style={{ color: '#FF0000', fontSize: 20 }}>{t("Logout")}</Text>
                            </TouchableOpacity>

                            {accountType == 'family' && <TouchableOpacity onPress={async() => { 
                                await logoutSubAccount() 
                                navigation.navigate('MultiUserStack')
                                }} style={{ width: '100%', padding: 10, marginBottom: 60, alignSelf: 'center', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
                                <Text style={{ color: '#FF0000', fontSize: 20 }}>{t("Logout_Sub_Account")}</Text>
                            </TouchableOpacity>}
                        </View>
                    </View>
                    {lang == 'ar'
                ? <Image source={panda92} style={mainStyle.panda92} />
                : <Image source={panda9} style={mainStyle.panda9} />
            }
            </>
        )
    }

    return (
        currentPlatform === 'web' ? (
            <View style={mainStyle.mainContainer}>
            <ScrollView style={{backgroundColor: currentThemeColor.webMainBackground}} showsVerticalScrollIndicator={false} contentContainerStyle={styles.scrollContainer}>
                <View style={styles.imageBackground}>
                <WebBackgroundTopColor >
                {pageContent()}
            </WebBackgroundTopColor>
                </View>
            </ScrollView></View>
        ) :<View style={mainStyle.mainContainer}>
            <ImageBackground source={currentTheme == 'dark' ? back4Dark : back4} style={{ width: screenWidth, height: screenHeight, flex: 1 }} resizeMode='cover' >
            <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={styles.scrollViewContent}>
                    {pageContent()}
                </ScrollView>
            </ImageBackground >

        </View >
    );
}

const styles = StyleSheet.create({
    scrollViewContent: {
        flexGrow: 1,
    },
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.mainBackground
    },
    inputsContainer: {
        width: '100%',
        flex: 1,
        paddingTop: currentPlatform == 'ios' ? 60 : (screenHeight > 585 ? 20 : 0),
        paddingBottom: screenHeight > 585 ? 70 : 0
    },
    panda1: {
        position: 'absolute',
        bottom: BOTTOMBARHEIGHT,
        right: '5%',
        width: 100,
        height: 100,
        resizeMode: 'contain'
    },
    ImageDiv: {
        width: 180,
        height: 180,
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        marginTop: isWeb ? -100 : 0,
    },
    Image: {
        width: '100%',
        height: '100%',
        borderRadius: 100,
        // borderColor: Colors.secondary,
        // borderWidth: 1,
    },
    buttons: { width: currentPlatform === 'web' ? '50%' : '90%', height: 45, marginTop: 15, backgroundColor: Colors.secondary, justifyContent: 'center', alignItems: 'center' }
})

export default MainSettingsScreen