import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { View, StyleSheet, ImageBackground, Image, ScrollView, Text } from 'react-native';
import { Colors, FONTFAMILY, isWeb, screenHeight, screenWidth } from '../../constants/Colors';
import back2 from '../../assets/background/back2.png';
import back2Dark from '../../assets/background/back2Dark.png';
import panda9 from '../../assets/panda/panda9.png';
import panda92 from '../../assets/panda/panda9-2.png';
import CustomButton from '../../components/customButton';
import { mainStyle } from '../../mainstyle';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/AuthContext';
import Header from '../../components/header';
import Input from '../../components/input';
import CustomAlert from '../../components/customAlert';
import panda3 from '../../assets/panda/panda3.png';
import { WebBackgroundTopColor } from '../../components/WebBackground';

const AddNewSubUser = ({ navigation, route }) => {
    const { t } = useTranslation();
    const { currentTheme, lang, AddNewSubUser, token } = useAuth();
    const [clickable, setClickable] = useState(false)

    const [alertData, setAlertData] = useState(null);
    const [errors, setErrors] = useState({
        allData: false,
    });

    const showAlert = useCallback((message, type) => {
        setAlertData({ message, type });
    }, []);
    const hideAlert = useCallback(() => {
        setAlertData(null);
    }, []);

    const [formData, setFormData] = useState({
        username: '',
        password: '',
        birthdayDate: '',
        language: lang
    });

    const renderError = (error) => {
        return error && <Text style={styles.error}>{t(error)}</Text>;
    };

    useEffect(() => {
        const isFormDataValid = () => {
            const { username, password, birthdayDate } = formData;
            return username && password && birthdayDate && Object.values(errors).every(value => !value);
        };

        setClickable(isFormDataValid());
    }, [formData, errors]);

    const backgroundImageSource = currentTheme == 'dark' ? back2Dark : back2;
    const pandaImageSource = lang == 'ar' ? panda92 : panda9;

    const handleInputChange = (field, value) => {
        if (field === 'birthdayDate' && value) {
            const dateRegex = /^(\d{4})-(\d{1,2})-(\d{1,2})$/;
            if (!dateRegex.test(value)) {
                setErrors({ ...errors, birthdayFormat: true });
            } else {
                const dateParts = value.split('-');
                const year = parseInt(dateParts[0]);
                const month = parseInt(dateParts[1]);
                const day = parseInt(dateParts[2]);
        
                const currentDate = new Date();
                const currentYear = currentDate.getFullYear();
        
                if (isNaN(year) || year < 1900 || year > currentYear || month < 1 || month > 12 || day < 1 || day > 31) {
                    setErrors({ ...errors, birthdayFormat: true });
                } else {
                    setErrors({ ...errors, birthdayFormat: false });
                }
            }
        }
        setFormData({ ...formData, [field]: value });
    };

    const submit = async () => {
        if (!formData.username && !formData.password) return showAlert(t("Fill_All_Data"), 'error');
        try {
            const addUser = await AddNewSubUser(formData.username, formData.password, formData?.language, formData?.birthdayDate);
            if (addUser) {
                showAlert(t("User_Add_Successfully"), 'success');
                navigation.goBack()
            }
        } catch (error) {
            showAlert(t("Something_Went_Wrong"), 'error');
        }
    }

    const pageContent = () => {
        return (
            <Fragment>
                <Header title={t('Add_user')} />
                    <Image source={panda3} style={mainStyle.pandaHeader} />

                    <View style={styles.inputsContainer}>
                        <Input
                            lable={t('Username')}
                            placeholder={t('Username')}
                            onChange={(text) => handleInputChange('username', text)}
                            value={formData.username}
                        />
                        <Input
                            lable={t('date_of_birth')}
                            placeholder={t('format_date')}
                            onChange={(text) => handleInputChange('birthdayDate', text)}
                            keyboardType='decimal-pad'
                            value={formData.birthdayDate}
                        />
                        {renderError(errors.birthdayFormat && 'Invalid_Date_Format')}
                        <Input
                            lable={t('Password')}
                            placeholder={t('Password')}
                            secureTextEntry
                            onChange={(text) => handleInputChange('password', text)}
                            keyboardType='decimal-pad'
                            type="password"
                            mainDivStyle={{ marginBottom: 0 }}
                            value={formData.password}
                            subText={t('Enter_Your_Four_digits_Password_for_this_account')}
                            maxLength={4}
                        />
                        <CustomButton enable={clickable} btnStyle={{ marginTop: 15, }} text={t('Submit')} onPress={submit} />
                    </View>
                    <CustomAlert title={alertData?.message} type={alertData?.type} isVisible={!!alertData} onHide={hideAlert} />

            </Fragment>
        )
    }

    return (
        isWeb ? (
            <WebBackgroundTopColor>
                {pageContent()}
            </WebBackgroundTopColor>
        ) : <View style={mainStyle.mainContainer}>
            <ImageBackground
                source={backgroundImageSource}
                style={styles.backgroundImage}
                resizeMode='cover'>
                <ScrollView contentContainerStyle={styles.scrollViewContent}>
                    {pageContent()}
                </ScrollView>
            </ImageBackground>
            <Image source={pandaImageSource} style={{ ...styles.pandaImage, alignSelf: lang == 'ar' ? 'flex-start' : 'flex-end' }} />
        </View >
    );
};

const styles = StyleSheet.create({
    backgroundImage: {
        width: screenWidth,
        height: screenHeight,
        justifyContent: 'center'
    },
    scrollViewContent: {
        flexGrow: 1
    },
    inputsContainer: {
        width: isWeb ? screenWidth / 3 : '90%',
        height: '50%',
        alignSelf: 'center',
        paddingVertical: 20,
        justifyContent: 'center',
    },
    userContainer: {
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    userItem: {
        width: 150,
        height: 150,
        backgroundColor: Colors.secondary,
        marginBottom: 15,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'space-around',
        paddingTop: 15
    },
    userImage: {
        width: 70,
        height: 70,
        resizeMode: 'contain',
        borderRadius: 99
    },
    userName: {
        fontSize: 18,
        fontFamily: FONTFAMILY,
        color: '#ffff'
    },
    addUserButton: {
        width: 150,
        height: 150,
        backgroundColor: Colors.lightGray,
        marginBottom: 15,
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center'
    },
    pandaImage: {
        ...mainStyle.panda9,
    },
    error: {
        color: 'red',
        fontFamily: FONTFAMILY,
        fontSize: 16,
        textAlign: 'center',
    },
});

export default AddNewSubUser;
