import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView, TouchableOpacity, Image } from 'react-native';
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import { useAuth } from '../../contexts/AuthContext';
import { t } from 'i18next';

const allSteps = {
    "en": [
        {
            "title": "What is an Algorithm?",
            "content": "An algorithm is like a recipe! It's a step-by-step guide to solve a problem or complete a task. In programming, algorithms help computers do things like sort your favorite cartoons or find the shortest path in a maze."
        },
        {
            "title": "Example of an Algorithm: Sorting",
            "content": "Imagine you have a box of mixed-up toy cars, and you want to line them up from the smallest to the biggest. That's what a sorting algorithm does! It organizes things in a specific order."
        },
        {
            "title": "Example: Bubble Sort",
            "content": "Bubble Sort is like bubbling up the biggest toy cars to the top! It compares two cars at a time and swaps them if they're out of order, doing this until everything is in the right spot."
        },
        {
            "title": "Bubble Sort Algorithm Steps",
            "content": "1. Start at the beginning of the list.\n2. Compare the first two elements.\n3. If the first element is greater, swap them!\n4. Move to the next pair and repeat.\n5. Continue until the list is sorted!"
        },
        {
            "title": "What is a Data Structure?",
            "content": "A data structure is a way to store and organize data so that it can be used efficiently. Examples include arrays, linked lists, stacks, and queues. Understanding data structures helps in choosing the best way to manage data for different applications."
        },
        {
            "title": "Example of a Data Structure: Stack",
            "content": "A stack is like a stack of plates where you can only take the top plate off or put a new one on top. It's a Last In, First Out (LIFO) structure. You push elements onto the stack and pop them off when needed."
        },
        {
            "title": "Example: Stack Operations",
            "content": "1. Push: Add an item to the top of the stack.\n2. Pop: Remove the item from the top of the stack.\n3. Peek: View the item on the top of the stack without removing it.\n4. IsEmpty: Check if the stack is empty."
        },
        {
            "title": "What is a Loop?",
            "content": "A loop is a programming construct that allows you to repeat a block of code multiple times. There are different types of loops like for-loops, while-loops, and do-while loops. Loops are used to iterate over data or perform repetitive tasks."
        },
        {
            "title": "Example of a Loop: For Loop",
            "content": "A for loop is used to repeat a block of code a specific number of times. For example, if you want to print numbers from 1 to 5, you can use a for loop to iterate through these numbers and print each one."
        },
        {
            "title": "Example: For Loop Syntax",
            "content": "for (initialization; condition; increment) {\n    // Code to be executed\n}\n\nExample:\nfor (let i = 1; i <= 5; i++) {\n    console.log(i);\n}"
        }
    ],
    "ar": [
        {
            "title": "ما هي الخوارزمية؟",
            "content": "الخوارزمية تشبه الوصفة! إنها دليل خطوة بخطوة لحل مشكلة أو إكمال مهمة. في البرمجة، تساعد الخوارزميات أجهزة الكمبيوتر في القيام بأشياء مثل ترتيب الرسوم المتحركة المفضلة لديك أو العثور على أقصر طريق في متاهة."
        },
        {
            "title": "مثال على خوارزمية: الترتيب",
            "content": "تخيل أن لديك صندوقًا من السيارات المبعثرة، وتريد ترتيبها من الأصغر إلى الأكبر. هذا ما تفعله خوارزمية الترتيب! إنها تنظم الأشياء بترتيب محدد."
        },
        {
            "title": "مثال: خوارزمية الفقاعة",
            "content": "خوارزمية الفقاعة تشبه رفع أكبر السيارات إلى الأعلى! إنها تقارن بين سيارتين في كل مرة وتبدلهما إذا كانا في غير ترتيب، وتستمر في ذلك حتى تكون كل الأشياء في المكان الصحيح."
        },
        {
            "title": "خطوات خوارزمية الفقاعة",
            "content": "1. ابدأ من بداية القائمة.\n2. قارن بين العنصرين الأولين.\n3. إذا كان العنصر الأول أكبر، بدلهما!\n4. انتقل إلى الزوج التالي وكرر.\n5. استمر حتى يتم ترتيب القائمة!"
        },
        {
            "title": "ما هي بنية البيانات؟",
            "content": "بنية البيانات هي طريقة لتخزين وتنظيم البيانات بحيث يمكن استخدامها بكفاءة. تشمل الأمثلة المصفوفات، القوائم المرتبطة، المكدسات، والأق queues. فهم بنى البيانات يساعد في اختيار أفضل طريقة لإدارة البيانات لتطبيقات مختلفة."
        },
        {
            "title": "مثال على بنية البيانات: المكدس",
            "content": "المكدس يشبه كومة من الأطباق حيث يمكنك فقط إزالة الطبق العلوي أو وضع طبق جديد على القمة. إنه هيكل 'الأخير يدخل أولاً يخرج' (LIFO). تقوم بدفع العناصر إلى المكدس وسحبها عند الحاجة."
        },
        {
            "title": "مثال: عمليات المكدس",
            "content": "1. Push: إضافة عنصر إلى قمة المكدس.\n2. Pop: إزالة العنصر من قمة المكدس.\n3. Peek: عرض العنصر على قمة المكدس دون إزالته.\n4. IsEmpty: التحقق مما إذا كان المكدس فارغًا."
        },
        {
            "title": "ما هو التكرار؟",
            "content": "التكرار هو بنية برمجية تتيح لك تكرار كتلة من التعليمات البرمجية عدة مرات. هناك أنواع مختلفة من الحلقات مثل الحلقات for و while و do-while. تُستخدم الحلقات للتكرار عبر البيانات أو لأداء المهام المتكررة."
        },
        {
            "title": "مثال على التكرار: حلقة for",
            "content": "تُستخدم حلقة for لتكرار كتلة من التعليمات البرمجية عددًا محددًا من المرات. على سبيل المثال، إذا كنت ترغب في طباعة الأرقام من 1 إلى 5، يمكنك استخدام حلقة for للتكرار عبر هذه الأرقام وطباعة كل واحدة."
        },
        {
            "title": "مثال: صيغة حلقة for",
            "content": "for (initialization; condition; increment) {\n    // الكود الذي سيتم تنفيذه\n}\n\nمثال:\nfor (let i = 1; i <= 5; i++) {\n    console.log(i);\n}"
        }
    ],
    "es": [
        {
            "title": "¿Qué es un Algoritmo?",
            "content": "¡Un algoritmo es como una receta! Es una guía paso a paso para resolver un problema o completar una tarea. En programación, los algoritmos ayudan a las computadoras a hacer cosas como ordenar tus caricaturas favoritas o encontrar el camino más corto en un laberinto."
        },
        {
            "title": "Ejemplo de un Algoritmo: Ordenamiento",
            "content": "Imagina que tienes una caja de coches de juguete mezclados, y quieres alinearlos desde el más pequeño hasta el más grande. ¡Eso es lo que hace un algoritmo de ordenamiento! Organiza las cosas en un orden específico."
        },
        {
            "title": "Ejemplo: Ordenamiento por Burbuja",
            "content": "El Ordenamiento por Burbuja es como hacer que los coches de juguete más grandes suban a la cima. Compara dos coches a la vez y los intercambia si están desordenados, haciendo esto hasta que todo esté en el lugar correcto."
        },
        {
            "title": "Pasos del Algoritmo de Ordenamiento por Burbuja",
            "content": "1. Comienza desde el principio de la lista.\n2. Compara los dos primeros elementos.\n3. Si el primer elemento es mayor, ¡intercámbialos!\n4. Pasa al siguiente par y repite.\n5. ¡Continúa hasta que la lista esté ordenada!"
        },
        {
            "title": "¿Qué es una Estructura de Datos?",
            "content": "Una estructura de datos es una forma de almacenar y organizar datos para que se puedan usar de manera eficiente. Ejemplos incluyen arreglos, listas enlazadas, pilas y colas. Entender las estructuras de datos ayuda a elegir la mejor manera de gestionar datos para diferentes aplicaciones."
        },
        {
            "title": "Ejemplo de una Estructura de Datos: Pila",
            "content": "Una pila es como una pila de platos donde solo puedes sacar el plato superior o poner uno nuevo en la cima. Es una estructura de 'Último en Entrar, Primero en Salir' (LIFO). Agregas elementos a la pila y los quitas cuando los necesitas."
        },
        {
            "title": "Ejemplo: Operaciones de la Pila",
            "content": "1. Push: Agregar un elemento a la cima de la pila.\n2. Pop: Quitar el elemento de la cima de la pila.\n3. Peek: Ver el elemento en la cima de la pila sin quitarlo.\n4. IsEmpty: Verificar si la pila está vacía."
        },
        {
            "title": "¿Qué es un Bucle?",
            "content": "Un bucle es una estructura de programación que permite repetir un bloque de código múltiples veces. Hay diferentes tipos de bucles como el for, while y do-while. Los bucles se usan para iterar sobre datos o realizar tareas repetitivas."
        },
        {
            "title": "Ejemplo de un Bucle: Bucle For",
            "content": "Un bucle for se usa para repetir un bloque de código un número específico de veces. Por ejemplo, si quieres imprimir números del 1 al 5, puedes usar un bucle for para iterar sobre estos números e imprimir cada uno."
        },
        {
            "title": "Ejemplo: Sintaxis del Bucle For",
            "content": "for (initialization; condition; increment) {\n    // Código a ejecutar\n}\n\nEjemplo:\nfor (let i = 1; i <= 5; i++) {\n    console.log(i);\n}"
        }
    ],
    "fr": [
        {
            "title": "Qu'est-ce qu'un Algorithme ?",
            "content": "Un algorithme est comme une recette ! C'est un guide étape par étape pour résoudre un problème ou accomplir une tâche. En programmation, les algorithmes aident les ordinateurs à faire des choses comme trier vos dessins animés préférés ou trouver le chemin le plus court dans un labyrinthe."
        },
        {
            "title": "Exemple d'Algorithme : Tri",
            "content": "Imaginez que vous avez une boîte de voitures miniatures mélangées, et vous voulez les aligner du plus petit au plus grand. C'est ce que fait un algorithme de tri ! Il organise les choses dans un ordre spécifique."
        },
        {
            "title": "Exemple : Tri à Bulles",
            "content": "Le Tri à Bulles est comme faire remonter les plus grandes voitures miniatures au sommet ! Il compare deux voitures à la fois et les échange si elles sont dans le mauvais ordre, faisant cela jusqu'à ce que tout soit à la bonne place."
        },
        {
            "title": "Étapes de l'Algorithme de Tri à Bulles",
            "content": "1. Commencez au début de la liste.\n2. Comparez les deux premiers éléments.\n3. Si le premier élément est plus grand, échangez-les !\n4. Passez à la paire suivante et répétez.\n5. Continuez jusqu'à ce que la liste soit triée !"
        },
        {
            "title": "Qu'est-ce qu'une Structure de Données ?",
            "content": "Une structure de données est une façon de stocker et d'organiser les données afin qu'elles puissent être utilisées efficacement. Les exemples incluent les tableaux, les listes chaînées, les piles et les files d'attente. Comprendre les structures de données aide à choisir la meilleure façon de gérer les données pour différentes applications."
        },
        {
            "title": "Exemple de Structure de Données : Pile",
            "content": "Une pile est comme une pile d'assiettes où vous pouvez seulement retirer l'assiette du dessus ou en ajouter une nouvelle sur le dessus. C'est une structure 'Dernier Entré, Premier Sorti' (LIFO). Vous empilez les éléments et les retirez lorsque vous en avez besoin."
        },
        {
            "title": "Exemple : Opérations de la Pile",
            "content": "1. Push : Ajouter un élément sur le dessus de la pile.\n2. Pop : Retirer l'élément du dessus de la pile.\n3. Peek : Voir l'élément en haut de la pile sans l'enlever.\n4. IsEmpty : Vérifier si la pile est vide."
        },
        {
            "title": "Qu'est-ce qu'une Boucle ?",
            "content": "Une boucle est une structure de programmation qui permet de répéter un bloc de code plusieurs fois. Il existe différents types de boucles comme les boucles for, while et do-while. Les boucles sont utilisées pour itérer sur des données ou effectuer des tâches répétitives."
        },
        {
            "title": "Exemple de Boucle : Boucle For",
            "content": "Une boucle for est utilisée pour répéter un bloc de code un nombre spécifique de fois. Par exemple, si vous voulez imprimer les nombres de 1 à 5, vous pouvez utiliser une boucle for pour itérer à travers ces nombres et imprimer chacun d'eux."
        },
        {
            "title": "Exemple : Syntaxe de la Boucle For",
            "content": "for (initialization; condition; increment) {\n    // Code à exécuter\n}\n\nExemple :\nfor (let i = 1; i <= 5; i++) {\n    console.log(i);\n}"
        }
    ]
};


const AlgorithmsScreen = ({ navigation }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const { lang } = useAuth();

    const [steps, setSteps] = useState([]);

    useEffect(() => {
        setSteps(allSteps[lang] || allSteps['en']);
    }, [lang]);

    const opacity = useSharedValue(1);
    const translateY = useSharedValue(0);

    const animatedStyle = useAnimatedStyle(() => {
        return {
            opacity: withTiming(opacity.value, { duration: 500 }),
            transform: [{ translateY: withTiming(translateY.value, { duration: 500 }) }],
        };
    });

    const handleNext = () => {
        opacity.value = 0;
        translateY.value = 20;

        setTimeout(() => {
            if (currentStep < steps.length - 1) {
                setCurrentStep(currentStep + 1);
            } else {
                navigation.navigate('StartCodingIntroductionScreen');
            }

            opacity.value = 1;
            translateY.value = 0;
        }, 500);
    };

    useEffect(() => {
        opacity.value = 1;
        translateY.value = 0;
    }, []);

    return (
        <ScrollView style={styles.container}>
            <Text style={styles.header}>{t("Let's Learn Algorithms!")}</Text>
            <Image source={require('../../assets/background/algorithm.jpg')} style={styles.image} />

            <Animated.View style={animatedStyle}>
                <Text style={styles.sectionHeader}>{steps[currentStep]?.title}</Text>
                <Text style={styles.text}>{steps[currentStep]?.content}</Text>
            </Animated.View>

            <TouchableOpacity style={styles.button} onPress={handleNext}>
                <Text style={styles.buttonText}>
                    {currentStep < steps.length - 1 ? t("next") : t('Go to Introduction')}
                </Text>
            </TouchableOpacity>
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 20,
        backgroundColor: '#E0F7FA',
    },
    header: {
        fontSize: 26,
        fontWeight: 'bold',
        marginBottom: 20,
        textAlign: 'center',
        color: '#00796B',
    },
    sectionHeader: {
        fontSize: 22,
        fontWeight: '700',
        marginTop: 20,
        marginBottom: 10,
        color: '#00796B',
    },
    text: {
        fontSize: 18,
        lineHeight: 26,
        color: '#004D40',
        marginBottom: 20,
    },
    button: {
        marginTop: 30,
        paddingVertical: 12,
        paddingHorizontal: 20,
        backgroundColor: '#00796B',
        borderRadius: 25,
        alignItems: 'center',
        alignSelf: 'center',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.2,
        shadowRadius: 2,
    },
    buttonText: {
        color: '#FFFFFF',
        fontSize: 18,
        fontWeight: '600',
    },
    image: {
        width: '100%',
        height: 200,
        resizeMode: 'contain',
        marginBottom: 20,
    },
});

export default AlgorithmsScreen;
