import React, { useEffect, useRef, useState } from "react";
import { Image, ImageBackground, SafeAreaView, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { useTranslation } from "react-i18next";
import { sharingGameStyle } from "../../../sharingGameStyle";
import background4 from '../../../assets/game/background4.jpg';
import helloGirl from '../../../assets/game/characters/helloGirl.png';
import helloBoy from '../../../assets/game/characters/helloBoy.png';
import helloPanda from '../../../assets/game/characters/helloPanda.png';
import { HalloweenFont, isWeb, screenHeight, screenWidth } from "../../../constants/Colors";
import rArrow from '../../../assets/game/rArrow.png';
import { useHover } from "react-native-web-hooks";
import { useAuth } from "../../../contexts/AuthContext";
import globalConstants from "../../../config/globalConstants";
import { post } from "../../../WebService/RequestBuilder";
import CustomLoader from "../../../components/CustomLoader";

const ChoseYourCharacter = ({ navigation }) => {
    const { t } = useTranslation();
    const { 
        currentSubUserCourse, 
        lang } = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [chosenCharacter, setChosenCharacter] = useState('');
    const [isNextHovered, setIsNextHovered] = useState(false);
    const [gameData, setGameData] = useState({});
    const ref = useRef(null);
    const isHovered = useHover(ref);
    const [facts, setFacts] = useState([]);
    const [currentSandwichNumber, setCurrentSandwichNumber] = useState(1);
    const [isChickenBurger, setIsChickenBurger] = useState(false);

    const getCurrentSandwichNumber = (level) => {
        return (level - 1) % 4 + 1;
    };

    const getRandomBinary = () => {
        return Math.floor(Math.random() * 2);
    };

    const getFacts = async () => {
        try {
            setIsLoading(true);
            const { data } = await post(globalConstants.chatGPT.userGameMaterial, {
                user_course_id: currentSubUserCourse?.id,
            });
            
            if (data && data.facts) {
                setFacts(data.facts);
                setGameData(data.user_game);
                const level = data.user_game.level;

                const sandwichNum = getCurrentSandwichNumber(level);

                if (sandwichNum === 4) {
                    const random = getRandomBinary();
                    setIsChickenBurger(random === 1);
                }

                setCurrentSandwichNumber(sandwichNum);
            } else {
                console.error("Invalid data format:", data);
            }
        } catch (error) {
            console.error('Error fetching facts:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (currentSubUserCourse?.id) {
            getFacts();
        } else {
            setIsLoading(false);
        }
    }, [currentSubUserCourse?.id]);

    const handleNextHover = () => {
        setIsNextHovered(true);
    };

    const handleLeaveNextHover = () => {
        setIsNextHovered(false);
    };

    const onPressScreen = () => {
        let screenName = 'KitchenGameScreen';
        if ((currentSandwichNumber == 1 || currentSandwichNumber == 4) && gameData.level > 4) {
            screenName = 'GardenLevelScreen';
        }

        navigation.navigate(screenName, {
            chosenCharacter,
            userCourseId: currentSubUserCourse?.id,
            lang,
            game: gameData,
            isChickenBurger,
            currentSandwichNumber,
            facts,
        });
    };

    if (isLoading) {
        return <CustomLoader text={t('Loading')} />;
    }

    return (
        <SafeAreaView style={{overflow: 'hidden'}}>
            <ImageBackground
                source={background4}
                style={[sharingGameStyle.backgroundImage, { justifyContent: 'flex-start' }]}
                resizeMode='cover'
            >
                <Text style={styles.headerText}>
                    {t("Choose_Your_Main_Character")}
                </Text>
                {chosenCharacter && (
                    <TouchableOpacity
                        onPress={onPressScreen}
                        onPressIn={handleNextHover}
                        onPressOut={handleLeaveNextHover}
                        ref={ref}
                        style={sharingGameStyle.closeTouchable}
                    >
                        <Image source={rArrow} style={[styles.nextImage, (isHovered || isNextHovered) && styles.nextImageHover, isWeb && { cursor: 'pointer' }]} />
                    </TouchableOpacity>
                )}
                <View style={styles.charactersView}>
                    <View>
                        <TouchableOpacity onPress={() => setChosenCharacter('boy')}>
                            <Text style={[styles.headerText, chosenCharacter === 'boy' && styles.clickedText]}>
                                {t("BOY")}
                            </Text>
                        </TouchableOpacity>
                        <Image source={helloBoy} style={styles.characters} />
                    </View>
                    <View>
                        <TouchableOpacity onPress={() => setChosenCharacter('panda')}>
                            <Text style={[styles.headerText, chosenCharacter === 'panda' && styles.clickedText]}>
                                {t("MY_FRIEND_PANDA")}
                            </Text>
                        </TouchableOpacity>
                        <Image source={helloPanda} style={[styles.characters, !isWeb && { width: 250 }]} />
                    </View>
                    <View>
                        <TouchableOpacity onPress={() => setChosenCharacter('girl')}>
                            <Text style={[styles.headerText, chosenCharacter === 'girl' && styles.clickedText]}>
                                {t("GIRL")}
                            </Text>
                        </TouchableOpacity>
                        <Image source={helloGirl} style={styles.characters} />
                    </View>
                </View>
            </ImageBackground>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    charactersView: {
        position: 'absolute',
        bottom: isWeb ? '5%' : '10%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: isWeb ? screenWidth - 200 : screenHeight - 100,
        alignSelf: 'center',
    },
    characters: {
        width: isWeb ? 250 : 150,
        height: isWeb ? 350 : 200,
        resizeMode: 'contain',
    },
    headerText: {
        fontFamily: HalloweenFont,
        fontSize: isWeb ? 40 : 35,
        color: 'black',
        alignSelf: 'center',
        marginTop: '2%',
    },
    clickedText: {
        color: '#e9dbc0',
    },
    nextImage: {
        width: isWeb ? 100 : 80,
        height: isWeb ? 70 : 50,
    },
    nextImageHover: {
        width: isWeb ? 110 : 85,
        height: isWeb ? 80 : 55,
    }
});

export default ChoseYourCharacter;
