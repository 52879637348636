import React, { useCallback, useEffect, useRef, useState } from "react";
import { Image, ImageBackground, SafeAreaView, StyleSheet, Text, TouchableOpacity, View, Animated } from "react-native";
import { useHover } from 'react-native-web-hooks';

import { isIos, isWeb, screenHeight, screenWidth } from "../../../constants/Colors";
import background3 from '../../../assets/game/background3.jpg';
import breadSlice from '../../../assets/game/breadSlice.png';
import cabinet from '../../../assets/game/cabinet.png';
import fridgeClosed from '../../../assets/game/fridgeClosed.png';
import fridgeOpened from '../../../assets/game/fridgeOpen.png';
import gasStove from '../../../assets/game/gasStove.png';
import kitchenHood from '../../../assets/game/kitchenHood.png';
import kitchenTools from '../../../assets/game/kitchenTools.png';
import knife from '../../../assets/game/knife.png';
import lettuce3 from '../../../assets/game/lettuce3.png';
import lettuce from '../../../assets/game/lettuce.png';

import table from '../../../assets/game/table.png';
import tap from '../../../assets/game/tap.png';
import tomatoSlice2 from '../../../assets/game/tomatoSlice2.png';
import breadSlice2 from '../../../assets/game/breadSlice2.png';
import tomato from '../../../assets/game/tomato.png';
import { sharingGameStyle } from "../../../sharingGameStyle";

import helloGirl from '../../../assets/game/characters/helloGirl.png';
import helloBoy from '../../../assets/game/characters/helloBoy.png';
import helloPanda from '../../../assets/game/characters/helloPanda.png';

import rightPanda from '../../../assets/game/characters/rightPanda.png';
import rightBoy from '../../../assets/game/characters/rightBoy.png';
import rightGirl from '../../../assets/game/characters/rightGirl.png';

import frontGirl from '../../../assets/game/characters/frontGirl.png';
import frontPanda from '../../../assets/game/characters/frontPanda.png';
import frontBoy from '../../../assets/game/characters/frontBoy.png';

import backBoy from '../../../assets/game/characters/backBoy.png';
import backPanda from '../../../assets/game/characters/backPanda.png';
import backGirl from '../../../assets/game/characters/backGirl.png';

import leftGirl from '../../../assets/game/characters/leftGirl.png';
import leftPanda from '../../../assets/game/characters/leftPanda.png';
import leftBoy from '../../../assets/game/characters/leftBoy.png';
import { useTranslation } from "react-i18next";
import lettuceHR from '../../../assets/game/lettuceHR.png';
import mustardL2R from '../../../assets/game/mustardL2R.png';
import ketchupLR from '../../../assets/game/ketchupLR.png';
import mustard from '../../../assets/game/mustard.png';
import sausage from '../../../assets/game/sausage.png';
import sausagesLong from '../../../assets/game/sausagesLong.png';
import ketchup from '../../../assets/game/ketchup.png';
import breadSausage from '../../../assets/game/breadSausage.png';
import chips from '../../../assets/game/chips.png';
import chipsOnSandwich from '../../../assets/game/chipsOnSandwich.png';
import sliceMeat1 from '../../../assets/game/sliceMeat1.png';
import friedEgg0 from '../../../assets/game/friedEgg0.png';
import eggsClosed from '../../../assets/game/eggsClosed.png';
import cheese from '../../../assets/game/cheese.png';
import ketchupSpread from '../../../assets/game/ketchupSpread.png';
import tomatoSlice from '../../../assets/game/tomatoSlice.png';
import cheeseSlice from '../../../assets/game/cheeseSlice.png';
import lettuceW from '../../../assets/game/lettuceW.png';
import breadBottom from '../../../assets/game/breadBottom.png';
import chickenBurger from '../../../assets/game/chickenBurger.png';
import topBurgerBread from '../../../assets/game/topBurgerBread.png';
import burger from '../../../assets/game/burger.png';
import GameModalView from "../../../components/GameModalView";
import { get, post } from "../../../WebService/RequestBuilder";
import globalConstants from "../../../config/globalConstants";
import CustomLoader from "../../../components/CustomLoader";
import { CommonActions } from '@react-navigation/native';


const KitchenGameScreen = ({navigation, route}) => {
    const { t } = useTranslation();
    const [fridgeOpen, setFridgeOpen] = useState(false);
    const [isCloseHovered, setIsCloseHovered] = useState(false);
    const ref = useRef(null);
    const [showKnife, setShowKnife] = useState(true);
    const [showKnife2, setShowKnife2] = useState(false);
    const [lettuceClicked, setLettuceClicked] = useState(false);
    const [character, setCharacter] = useState(null);
    const [characterPosition, setCharacterPosition] = useState({ left: 50, bottom: 0 });
    const [currentImage, setCurrentImage] = useState(null);
    const [tomatoClicked, setTomatoClicked] = useState(false);
    const [breadClicked, setBreadClicked] = useState(false);
    const [sausagesClicked, setSausagesClicked] = useState(false);
    const [mustardClicked, setMustardClicked] = useState(false);
    const [ketchupClicked, setKetchupClicked] = useState(false);
    const [eggsClicked, setEggsClicked] = useState(false);
    const [chipsClicked, setChipsClicked] = useState(false);
    const [showKnife3, setShowKnife3] = useState(false);
    const [sliceMeatClicked, setSliceMeatClicked] = useState(false);
    const [burgerClicked, setBurgerClicked] = useState(false);
    const [cheeseClicked, setCheeseClicked] = useState(false);
    // const [isChickenBurger, setIsChickenBurger] = useState(false);
    const {chosenCharacter, userCourseId, lang, game, isChickenBurger, currentSandwichNumber, facts } = route?.params;
    const [position] = useState(new Animated.ValueXY({ x: 0, y: 0 }));
    const [isLoading, setIsLoading] = useState(false);
    const [lastStepDone, setLastStepDone] = useState(false);
    const [finishSandwich, setFinishSandwich] = useState(false);
    const [finishSandwichModel, setFinishSandwichModel] = useState(false);
    const [gameData, setGameData] = useState(game);
    // const [currentSandwichNumber, setCurrentSandwichNumber] = useState(1);

    // const [facts, seFacts] = useState([]);
   
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentFact, setCurrentFact] = useState(facts[0]);
    const [modalVisible, setModalVisible] = useState(false); 

    const changeLocation = useCallback(location => {
        const moveFunctions = {
            right: { x: 50, y: 0 },
            left: { x: -50, y: 0 },
            down: { x: 0, y: 10 },
            up: { x: 0, y: -10 }
        };
    
        const { x, y } = moveFunctions[location];
    
        const newX = characterPosition.left + x;
        const newY = characterPosition.bottom + y;

        // const clampedX = Math.max(0, Math.min(newX, screenWidth - 100));
        // const clampedY = Math.max(0, Math.min(newY, screenHeight - 50));

        switch (location) {
            case 'right':
                setCurrentImage(character?.left);
                break;
            case 'left':
                setCurrentImage(character?.right);
                break;
            case 'up':
                setCurrentImage(character?.back);
                break;
            case 'down':
                setCurrentImage(character?.front);
                break;
            default:
                setCurrentImage(character?.front);
                break;
        }

        const conditionX2 = isWeb ? newX < screenWidth / 2 : newX < screenWidth - 70;
    
        if (newX >= 0 && conditionX2 && newY <= 0 && newY >= -10) {
            setCharacterPosition(prevPosition => ({
                ...prevPosition,
                left: newX,
                bottom: newY
            }));
        }
    }, [characterPosition, currentImage]);

    useEffect(() => {
        if(currentSandwichNumber == 4) setShowKnife(false)
        else setShowKnife(true)
    }, [currentSandwichNumber])
    
    useEffect(() => {
        Animated.spring(position, {
            toValue: { x: characterPosition.left, y: characterPosition.bottom },
            useNativeDriver: false
        }).start();
    }, [characterPosition, position]);
    

    useEffect(() => {
        switch (chosenCharacter) {
            case 'boy':
                setCharacter({
                    front: frontBoy,
                    back: backBoy,
                    right: rightBoy,
                    left: leftBoy,
                    hello: helloBoy,
                })
                setCurrentImage(frontBoy)
                break;
            case 'girl':
                setCharacter({
                    front: frontGirl,
                    back: backGirl,
                    right: rightGirl,
                    left: leftGirl,
                    hello: helloGirl,
                })
                setCurrentImage(frontGirl)
                break;
            default:
                setCharacter({
                    front: frontPanda,
                    back: backPanda,
                    right: rightPanda,
                    left: leftPanda,
                    hello: helloPanda,
                })
                setCurrentImage(frontPanda)
                break;
        }
    }, [chosenCharacter]);

    const isHovered = useHover(ref);
    
    const handleCloseHover = () => {
        setIsCloseHovered(true);
    };

    const handleLeaveCloseHover = () => {
        setIsCloseHovered(false);
    };

    useEffect(() => {
        if(facts.length > 0) {
            setModalVisible(true);
        }
    }, [facts])

    // function getCurrentSandwichNumber(level) {
    //     return (level - 1) % 4 + 1;
    // }

    // function getRandomBinary() {
    //     return Math.floor(Math.random() * 2);
    // }

    // const getFacts = async () => {
    //     try {
    //         setIsLoading(true);
    //         setModalVisible(false)
    //         const {data} = await post(globalConstants.chatGPT.userGameMaterial, {
    //             user_course_id: userCourseId
    //         });

    //         console.log("data getFacts", data?.facts);

    //         if (data && data?.facts) {
    //             setFacts(data.facts);
    //             setGameData(data.user_game);
    //             const level = data.user_game.level;

    //             const sandwichNum = getCurrentSandwichNumber(level);

    //             if(sandwichNum == 4) {
    //                 const random = getRandomBinary();
    //                 setIsChickenBurger(random === 1);
    //             }

    //             setCurrentSandwichNumber(sandwichNum);
    //         } else {
    //             console.log("Invalid data format:", data);
    //         }
            
    //     } catch (error) {
    //         console.log('error getFacts', error);
    //     } finally {
    //         setIsLoading(false);
    //         setModalVisible(true)
    //     }
    // }

    // useEffect(() => {
    //     getFacts();
    // }, [userCourseId]);

    useEffect(() => {
        if (facts.length <= currentIndex) {
            setModalVisible(false);
        } else {
            setCurrentFact(facts[currentIndex]);
        }
    }, [currentIndex, facts]);

    const actionOnClick = async () => {
        try {
            const response = await post(globalConstants.userCourses.setFactAsRead, {
                game_fact_id: currentFact?.id,
            });
            setGameData({...gameData, ...response });
            setCurrentIndex(currentIndex + 1);
        } catch (error) {
            console.log('error actionOnClick', error);
        }
    }

    const finishSandwichFunction = async () => {
        try {
            setFinishSandwich(true)
            setFinishSandwichModel(true)
            const response = await post(globalConstants.userCourses.finishSandwichGame, {
                user_info_game_id: gameData?.id,
            });
            setGameData({...gameData, ...response });
            setCurrentIndex(currentIndex + 1);
        } catch (error) {
            console.log('error actionOnClick', error);
        }
    }

    if(isLoading) {
        return (
            <CustomLoader text={t('Loading')} />
        )
    }

    return (
        <SafeAreaView style={{overflow: 'hidden'}}>
            <ImageBackground
                source={background3}
                style={sharingGameStyle.backgroundImage}
                resizeMode='cover'
            >
                <Text style={sharingGameStyle.levelText}>
                {t('Lv')} {gameData.level} - {t('Pt')} {gameData.points}
                </Text>
                <TouchableOpacity
                     onPress={() => {
                        navigation.dispatch(
                            CommonActions.reset({
                              index: 0,
                              routes: [{ name: 'MainStack', state: { routes: [{ name: 'BottomTabNavigator' }] } }],
                            })
                          );
                    }}
                    onPressIn={handleCloseHover}
                    onPressOut={handleLeaveCloseHover}
                    ref={ref}
                    style={sharingGameStyle.closeTouchable}
                >
                    <Text style={[sharingGameStyle.close, (isHovered || isCloseHovered) && { ...sharingGameStyle.closeHover }, isWeb && {cursor: 'pointer'} ]}>
                    {t('CLOSE')}
                    </Text>
                </TouchableOpacity>                
                <Image source={fridgeOpen ? fridgeOpened : fridgeClosed} style={styles.fridge} />
                <View style={styles.cabinetView}>
                    <Image source={cabinet} style={styles.cabinet} />
                    <Image source={gasStove} style={styles.gasStove} />
                    <Image source={cabinet} style={styles.cabinet} />
                </View>
                <View style={styles.cabinetTap}>
                    <Image source={tap} style={styles.tap} />
                    <Image source={cabinet} style={styles.cabinet} />
                </View>
                <Image source={kitchenHood} style={styles.kitchenHood} />
                {/* <Image source={fryingPan} style={[styles.kitchenHood, {top: '40%'}]} /> */}
                <Image source={kitchenTools} style={styles.kitchenTools} />

                <Animated.Image
                    source={currentImage}
                    style={[
                        sharingGameStyle.playerCharacter,
                        chosenCharacter === 'panda' && sharingGameStyle.playerPanda,
                        { transform: [{ translateX: position.x }, { translateY: position.y }] }
                    ]}
                />

        <View style={styles.tableView}>
            {currentSandwichNumber == 1 && !finishSandwich && (
                <TouchableOpacity onPress={finishSandwichFunction} disabled={!lastStepDone} style={styles.tableItems}>
                    {!showKnife && (
                        <TouchableOpacity
                            disabled={lettuceClicked}
                            onPress={() => {
                                setLettuceClicked(true);
                                setShowKnife2(true);
                            }}
                            style={[styles.itemContainer, {zIndex: 10}]} 
                        >
                            <Image source={lettuce3} style={[styles.lettuce, lettuceClicked&&styles.lettuceAfterClick]} />
                        </TouchableOpacity>
                    )}
                    {showKnife2 && (
                        <TouchableOpacity
                            onPress={() => {
                                setShowKnife2(false);
                            }}
                            style={styles.itemContainer} 
                        >
                            <Image source={knife} style={[styles.knife, styles.knife2]} />
                        </TouchableOpacity>
                    )}
                    {showKnife && (
                        <TouchableOpacity
                            onPress={() => {
                                setShowKnife(false);
                            }}
                            style={styles.itemContainer} 
                        >
                            <Image source={knife} style={styles.knife} />
                        </TouchableOpacity>
                    )}
                    <Image source={showKnife ? breadSlice : breadSlice2} style={styles.breadSlice} />

                    {lettuceClicked && (
                        <TouchableOpacity
                            disabled={tomatoClicked || showKnife2}
                            onPress={() => {
                                setTomatoClicked(true);
                            }}
                            style={[styles.itemContainer, {zIndex: 10}]} 
                        >
                            <Image source={showKnife2 ? tomato : tomatoSlice2} style={[styles.wholeTomato, tomatoClicked&&styles.tomatoAfterClick]} />
                        </TouchableOpacity>
                    )}
                    
                    {tomatoClicked && (
                        <TouchableOpacity
                            disabled={breadClicked}
                            onPress={() => {
                                setBreadClicked(true);
                                setLastStepDone(true);
                            }}
                            style={[styles.itemContainer, {zIndex: 10}]}
                        >
                            {tomatoClicked&&<Image source={breadSlice2} style={[[styles.breadSlice2, {bottom: isWeb ? 0 : 8}], breadClicked&&styles.breadSlice]} />}
                        </TouchableOpacity>
                    )}

                </TouchableOpacity>
            )}

            {currentSandwichNumber == 2 && !finishSandwich && (
                <TouchableOpacity onPress={finishSandwichFunction} disabled={!lastStepDone} style={styles.tableItems}>
                <Image source={breadSausage} style={[styles.breadSausage]} />
                    {showKnife && (
                        <TouchableOpacity
                            onPress={() => {
                                setShowKnife(false);
                            }}
                            style={styles.itemContainer} 
                        >
                            <Image source={knife} style={[styles.knife, {left: isWeb ? '0%' : '0%'}]} />
                        </TouchableOpacity>
                    )}

                    <TouchableOpacity
                        disabled={sausagesClicked || showKnife}
                        onPress={() => {
                            setSausagesClicked(true);
                        }}
                        style={[styles.itemContainer, {zIndex: 10}]} 
                    >
                        <Image source={showKnife ? sausagesLong : sausage} style={[!sausagesClicked ? styles.sausages : styles.sausagesAfterClick]} />
                    </TouchableOpacity>

                    {sausagesClicked && (
                        <TouchableOpacity
                            disabled={mustardClicked}
                            onPress={() => {
                                setMustardClicked(true);
                            }}
                            style={[styles.itemContainer, {zIndex: 10}]} 
                        >
                            <Image source={!mustardClicked ? mustard : mustardL2R} style={[{...styles.breadSlice2, bottom: isWeb ? 5 : 10 }, mustardClicked&&styles.sausagesAfterClick]} />
                        </TouchableOpacity>
                    )}

                    {mustardClicked && (
                        <TouchableOpacity
                            disabled={ketchupClicked}
                            onPress={() => {
                                setKetchupClicked(true);
                                setShowKnife2(true);
                            }}
                            style={[styles.itemContainer, {zIndex: 10}]} 
                        >
                            <Image source={!ketchupClicked ? ketchup : ketchupLR} style={[{...styles.breadSlice2, bottom: isWeb ? 5 : 10 }, ketchupClicked&&styles.sausagesAfterClick]} />
                        </TouchableOpacity>
                    )}

                    {showKnife2 && (
                        <TouchableOpacity
                            onPress={() => {
                                setShowKnife2(false);
                            }}
                            style={styles.itemContainer} 
                        >
                            <Image source={knife} style={[styles.knife, styles.knife2]} />
                        </TouchableOpacity>
                    )}

                    {ketchupClicked&&<TouchableOpacity
                        disabled={lettuceClicked || showKnife2}
                        onPress={() => {
                            setLettuceClicked(true);
                        }}
                        style={[styles.itemContainer, {zIndex: 10}]} 
                    >
                        <Image source={showKnife2 ? lettuce : lettuceHR} style={[!lettuceClicked ? styles.lettuceH : styles.lettuceHAfterClick]} />
                    </TouchableOpacity>}

                    {lettuceClicked && (
                        <TouchableOpacity
                            disabled={breadClicked}
                            onPress={() => {
                                setBreadClicked(true);
                                setLastStepDone(true);
                            }}
                            style={[styles.itemContainer, {zIndex: 10}]} 
                        >
                            <Image source={breadSausage} style={[styles.breadSausageBeforeClick, breadClicked&&styles.breadSausage]} />
                        </TouchableOpacity>
                    )}
                </TouchableOpacity>
            )}

            {currentSandwichNumber == 3 && !finishSandwich && (
                <TouchableOpacity onPress={finishSandwichFunction} disabled={!lastStepDone} style={styles.tableItems}>
                {!showKnife && (
                        <TouchableOpacity
                            disabled={eggsClicked}
                            onPress={() => {
                                setEggsClicked(true);
                            }}
                            style={[styles.itemContainer, {zIndex: 10}]} 
                        >
                            <Image source={!eggsClicked ? eggsClosed : friedEgg0} style={[styles.wholeTomato, eggsClicked&&styles.eggsAfterClick]} />
                        </TouchableOpacity>
                    )}

                    {showKnife && (
                        <TouchableOpacity
                            onPress={() => {
                                setShowKnife(false);
                                // setShowKnife2(true);
                            }}
                            style={styles.itemContainer} 
                        >
                            <Image source={knife} style={styles.knife} />
                        </TouchableOpacity>
                    )}
                    <Image source={showKnife ? breadSlice : breadSlice2} style={styles.breadSlice} />

                    {eggsClicked && (
                        <TouchableOpacity
                        disabled={chipsClicked}
                        onPress={() => {
                            setChipsClicked(true);
                            setShowKnife3(true)
                        }}
                        style={[styles.itemContainer, {zIndex: 10}]} 
                    >
                        <Image source={!chipsClicked ? chips : chipsOnSandwich} style={[styles.lettuce, chipsClicked&&{...styles.lettuceAfterClick, bottom: isWeb ? 1 : 5}]} />
                    </TouchableOpacity>)}

                    {chipsClicked && (
                        <TouchableOpacity
                        disabled={sliceMeatClicked}
                        onPress={() => {
                            setSliceMeatClicked(true);
                            // setShowKnife3(true)
                        }}
                        style={[styles.itemContainer, {zIndex: 10}]} 
                    >
                        <Image source={sliceMeat1} style={[styles.lettuce, sliceMeatClicked&&{...styles.lettuceAfterClick, width: isWeb ? 60 : 50, height: isWeb ? 60 : 50}]} />
                    </TouchableOpacity>)}

                    {sliceMeatClicked && (
                        <TouchableOpacity
                            disabled={breadClicked}
                            onPress={() => {
                                setBreadClicked(true);
                                setLastStepDone(true);
                            }}
                            style={[styles.itemContainer, {zIndex: 10}]} 
                        >
                            <Image source={breadSlice2} style={[styles.breadSlice2, breadClicked&&styles.breadSlice]} />
                        </TouchableOpacity>
                    )}
                </TouchableOpacity>
            )}

            {currentSandwichNumber == 4 && !finishSandwich && (
                <TouchableOpacity onPress={finishSandwichFunction} disabled={!lastStepDone} style={styles.tableItems}>
                <Image source={breadBottom} style={styles.breadBurger} />

                    {<TouchableOpacity
                        onPress={() => {
                            setBurgerClicked(true);
                        }}
                        disabled={burgerClicked}
                        style={styles.itemContainer} 
                    >
                        <Image source={isChickenBurger ? chickenBurger : burger} style={[styles.burgerBeforeClick, burgerClicked&&styles.tomatoSliceAfterClick]} />
                    </TouchableOpacity>}

                    {showKnife && (
                        <TouchableOpacity
                            onPress={() => {
                                setShowKnife(false);
                            }}
                            style={styles.itemContainer} 
                        >
                            <Image source={knife} style={[styles.knife, styles.knife2]} />
                        </TouchableOpacity>
                    )}

                    {burgerClicked && (
                        <TouchableOpacity
                            disabled={ketchupClicked}
                            onPress={() => {
                                setKetchupClicked(true);
                            }}
                            style={[styles.itemContainer, {zIndex: 10}]} 
                        >
                            <Image source={!ketchupClicked ? ketchup : ketchupSpread} style={[styles.ketchupBefore, ketchupClicked&&styles.ketchupAfterClick]} />
                        </TouchableOpacity>
                    )}

                    {ketchupClicked&&<TouchableOpacity
                        disabled={cheeseClicked || showKnife}
                        onPress={() => {
                            setCheeseClicked(true);
                            setShowKnife2(true);
                        }}
                        style={[styles.itemContainer, {zIndex: 10}]} 
                    >
                        <Image source={showKnife ? cheese : cheeseSlice} style={[{...styles.ketchupBefore, top: 0}, cheeseClicked&&{...styles.tomatoSliceAfterClick, top: isWeb ? -8 : -18 }]} />
                    </TouchableOpacity>}


                    {showKnife2 && (
                        <TouchableOpacity
                            onPress={() => {
                                setShowKnife2(false);
                            }}
                            style={styles.itemContainer} 
                        >
                            <Image source={knife} style={[styles.knife, styles.knife2]} />
                        </TouchableOpacity>
                    )}

                    {cheeseClicked&&<TouchableOpacity
                        disabled={tomatoClicked || showKnife2}
                        onPress={() => {
                            setTomatoClicked(true);
                            setShowKnife3(true);
                        }}
                        style={[styles.itemContainer, {zIndex: 10}]} 
                    >
                        <Image source={showKnife2 ? tomato : tomatoSlice} style={[styles[showKnife2 ? 'wholeTomato' : 'ketchupBefore'], !showKnife2&&{top: 0}, tomatoClicked&&{...styles.tomatoSliceAfterClick, top: isWeb ? -14 : -24 }]} />
                    </TouchableOpacity>}


                    {showKnife3 && (
                        <TouchableOpacity
                            onPress={() => {
                                setShowKnife3(false);
                            }}
                            style={styles.itemContainer} 
                        >
                            <Image source={knife} style={[styles.knife, styles.knife2]} />
                        </TouchableOpacity>
                    )}


                    {tomatoClicked&&<TouchableOpacity
                        disabled={lettuceClicked || showKnife3}
                        onPress={() => {
                            setLettuceClicked(true);
                        }}
                        style={[styles.itemContainer, {zIndex: 10}]} 
                    >
                        <Image source={showKnife3 ? lettuce : lettuceW} style={[styles[showKnife3 ? 'wholeTomato' : 'ketchupBefore'], !showKnife3&&{top: 0},lettuceClicked&&{...styles.tomatoSliceAfterClick, top: isWeb ? -18 : -28 }]} />
                    </TouchableOpacity>}

                    
                    {lettuceClicked&&<TouchableOpacity
                        disabled={breadClicked}
                        onPress={() => {
                            setBreadClicked(true);
                            setLastStepDone(true);
                        }}
                        style={[styles.itemContainer, {zIndex: 10}]} 
                    >
                        <Image source={topBurgerBread} style={[styles.breadSlice2,breadClicked&&{...styles.breadBurger, bottom: isWeb ? 35 : 45}]} />
                    </TouchableOpacity>}

                </TouchableOpacity>
            )}
            <Image source={table} style={styles.table} />
            </View>
                <View style={styles.Div2}>
                    <View style={{ width: isWeb ? 150 : 90, height: isWeb ? 150 : 90, flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                        <TouchableOpacity style={{ ...styles.arrowBtn, backgroundColor: '#2B2F30' }} onPress={() => { changeLocation('up') }}>
                            <Text style={styles.arrowBtnText}>▲</Text>
                        </TouchableOpacity>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                            <TouchableOpacity style={{ ...styles.arrowBtn, backgroundColor: '#2B2F30' }} onPress={() => { changeLocation('left') }}>
                                <Text style={styles.arrowBtnText}>◄</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={{ ...styles.arrowBtn, backgroundColor: '#2B2F30' }} onPress={() => { changeLocation('right') }}>
                                <Text style={styles.arrowBtnText}>►</Text>
                            </TouchableOpacity>
                        </View>
                        <TouchableOpacity style={{ ...styles.arrowBtn, backgroundColor: '#2B2F30' }} onPress={() => { changeLocation('down') }}>
                            <Text style={styles.arrowBtnText}>▼</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            <GameModalView
                title={currentFact?.title}
                body={currentFact?.text}
                isOpen={modalVisible}
                action={actionOnClick}
                lang={lang}
            />

            <GameModalView
                title={t("")}
                body={t("Great_job_You_earned_3_points")}
                isOpen={finishSandwichModel}
                action={() => {
                    setFinishSandwichModel(false);
                        navigation.dispatch(
                            CommonActions.reset({
                              index: 0,
                              routes: [{ name: 'MainStack', state: { routes: [{ name: 'BottomTabNavigator' }] } }],
                            })
                          );
                }}
                lang={lang}
                style={{height: 200, maxHeight: 200}}
            />
            </ImageBackground>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    fridge: {
        width: isWeb ? 200 : 160,
        height: isWeb ? 300 : 200,
        resizeMode: 'contain',
        position: 'absolute',
        left: isWeb ? '3%' : '0%',
        bottom: '13%'
    },
    cabinetView: {
        position: 'absolute',
        left: isWeb ? '21.5%' : '25%',
        bottom: isWeb ? '20.5%' : '18%',
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: 294,
        zIndex: 10,
    },
    cabinet: {
        width: isWeb ? 200 : 150,
        height: isWeb ? 150 : 100,
        resizeMode: 'contain',
        marginRight: isWeb ? '-18%' : '0%',
    },
    gasStove: {
        width: isWeb ? 200 : 150,
        height: isWeb ? 150 : 100,
        resizeMode: 'contain',
        marginRight: isWeb ? '-18.5%' : '0%',
        marginLeft: isWeb ? '-0.5%' : '0%',
    }, 
    kitchenHood: {
        width: isWeb ? 70 : 50,
        height: isWeb ? 70 : 50,
        resizeMode: 'contain',
        position: 'absolute',
        left: isWeb ? '37%' : '44%',
        top: isWeb ? '-2%' : '0%',
    },
    kitchenTools: {
        width: isWeb ? 200 : 100,
        height: isWeb ? 100 : 70,
        resizeMode: 'contain',
        position: 'absolute',
        left: '49%',
        top: '20%',
    }, 
    tap:{
        width: isWeb ? 100 : 80,
        height: isWeb ? 80 : 60,
        resizeMode: 'contain',
        left: isWeb ? 50 : 36,
    }, 
    cabinetTap: {
        position: 'absolute',
        left: isWeb ? '53.5%' : '62%',
        bottom: isWeb ? '20.5%' : '18%',
        width: 294,
        zIndex: 10,
    },
    tableView: {
        position: 'absolute',
        resizeMode: 'contain',
        right: isIos ? '13%' : isWeb ? '3%' : '3%',
        bottom: isWeb ? '0%' : '5%',
        zIndex: 10,
    },
    table: {
        width: isWeb ? 300 : 168,
        height: isWeb ? 250 : 163,
        resizeMode: 'contain',
    },
    breadSlice: {
        width: isWeb ? 60 : 50,
        height: isWeb ? 60 : 50,
        resizeMode: 'contain',
        left: isWeb ? 115 : 80,
        bottom: isWeb ? 0 : 7,
    },
    breadBurger: {
        width: isWeb ? 75 : 65,
        height: isWeb ? 80 : 70,
        resizeMode: 'contain',
        left: isWeb ? 115 : 80,
        bottom: isWeb ? 0 : 9,
    },
    breadSausage: {
        width: isWeb ? 80 : 60,
        height: isWeb ? 80 : 60,
        resizeMode: 'contain',
        left: isWeb ? 115 : 80,
        bottom: isWeb ? 25 : 20,
    },
    breadSausageBeforeClick: {
        width: isWeb ? 80 : 60,
        height: isWeb ? 80 : 60,
        resizeMode: 'contain',
        left: isWeb ? 5 : 10,
        bottom: isWeb ? 25 : 20,
    },
    breadSlice2: {
        width: isWeb ? 60 : 50,
        height: isWeb ? 60 : 50,
        resizeMode: 'contain',
        right: isWeb ? 20 : 0,
        bottom: isWeb ? 0 : 0,
    },
    knife: {
        width: isWeb ? 70 : 60,
        height: isWeb ? 60 : 50,
        resizeMode: 'contain',
        left: isWeb ? '20%' : '0%',
        bottom: isWeb ? 20 : 27,
        zIndex: 10,
    },
    knife2: {
        right: isWeb ? 15 : 20,
        bottom: isWeb ? 20 : 27,
    },
    wholeTomato: {
        width: isWeb ? 35 : 25,
        height: isWeb ? 35 : 25,
        resizeMode: 'contain',
        left: isWeb ? 50 : 50,
        top: isWeb ? 15 : 15,
    },
    ketchupBefore: {
        width: isWeb ? 55 : 50,
        height: isWeb ? 55 : 50,
        resizeMode: 'contain',
        left: isWeb ? 25 : 15,
        top: isWeb ? 0 : -10,
    },
    burgerBeforeClick: {
        width: isWeb ? 65 : 55,
        height: isWeb ? 65 : 55,
        resizeMode: 'contain',
        left: isWeb ? 20 : 20,
        top: isWeb ? 10 : 2,
    },
    lettuce: {
        width: isWeb ? 60 : 50,
        height: isWeb ? 60 : 50,
        resizeMode: 'contain',
        right: isWeb ? '40%' : '0%',
        bottom: isWeb ? 5 : 10,
    },
    lettuceAfterClick: {
        left: isWeb ? 115 : 80,
        bottom: isWeb ? 5 : 10,
        width: isWeb ? 50 : 40,
        height: isWeb ? 50 : 40,
    },
    sausages: {
        width: isWeb ? 45 : 35,
        height: isWeb ? 45 : 35,
        resizeMode: 'contain',
        left: isWeb ? '80%' : '80%',
        bottom: 1,
    },
    sausagesAfterClick: {
        left: isWeb ? 115 : 80,
        bottom: isWeb ? 25 : 20,
        width: isWeb ? 80 : 60,
        height: isWeb ? 80 : 60,
    },
    lettuceH: {
        width: isWeb ? 60 : 50,
        height: isWeb ? 60 : 50,
        resizeMode: 'contain',
        left: isWeb ? '80%' : '80%',
        bottom: 1,
    },
    lettuceHAfterClick: {
        left: isWeb ? 115 : 70,
        bottom: isWeb ? 40 : 35,
        width: isWeb ? 110 : 90,
        height: isWeb ? 110 : 90,
    },
    tomatoAfterClick: {
        left: isWeb ? 115 : 80,
        bottom: isWeb ? 10 : 15,
        width: isWeb ? 50 : 40,
        height: isWeb ? 50 : 40,
        top: isWeb ? 0 : 0,
    },
    tomatoSliceAfterClick: {
        left: isWeb ? 115 : 85,
        width: isWeb ? 65 : 55,
        height: isWeb ? 65 : 55,
        top: isWeb ? 1 : -10,
    },
    ketchupAfterClick: {
        left: isWeb ? 115 : 100,
        width: isWeb ? 40 : 35,
        height: isWeb ? 40 : 35,
        top: isWeb ? 8 : -2
    },
    tableItems: {
        position: 'absolute',
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: isWeb ? 200 : 100,
        zIndex: 10,
    },
    itemContainer: {
        position: 'absolute',
    },
    eggsAfterClick: {
        left: isWeb ? 115 : 80,
        // bottom: isWeb ? 30 : 52,
        width: isWeb ? 60 : 45,
        height: isWeb ? 55 : 40,
        top: isWeb ? 0 : 0,
    },
    Div2: {
        position: 'absolute',
        right: isIos ? '10%' : '2%', 
        top: isIos ? '15%' : isWeb ? '18%' : '13%'
    },
    arrowBtn: {
        width: isWeb ? 50 : 30,
        height: isWeb ? 50 : 30,
        backgroundColor: '#fff',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 10
    },
    arrowBtnText: {
        fontSize: isWeb ? 30 : 10,
        color: '#fff',
    }
});

export default KitchenGameScreen;