import { useEffect, useState } from 'react';
import { View, StyleSheet, ImageBackground, Image, ScrollView, Text, KeyboardAvoidingView, Button } from 'react-native';
import { Colors, FONTFAMILY, currentPlatform, screenHeight, screenWidth } from '../../constants/Colors';
import back2 from '../../assets/background/back2.png';
import back2Dark from '../../assets/background/back2Dark.png';
import panda9 from '../../assets/panda/panda9.png';
import panda92 from '../../assets/panda/panda9-2.png';
import Input from '../../components/input';
import CustomButton from '../../components/customButton';
import { mainStyle } from '../../mainstyle';
import Header from '../../components/header';
import { useAuth } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { SelectList } from 'react-native-dropdown-select-list';
import arrowDown from '../../assets/icons/arrowDown.png';
import arrowUp from '../../assets/icons/arrowUp.png';
import { WebBackgroundTopColor } from '../../components/WebBackground';

const SignupScreen = ({ navigation }) => {
    const { t } = useTranslation();
    const { currentTheme, lang, register, currentThemeColor } = useAuth();
    const [clickable, setClickable] = useState(false)

    const Languages = [
        { value: "English", storedValue: 'en' },
        { value: "عربي", storedValue: 'ar' },
        { value: "Española", storedValue: 'es' },
        { value: "française", storedValue: 'fr' }
    ];

    const placeholderLangLabel = Languages.find(item => item.storedValue === lang)?.value;

    const [formData, setFormData] = useState({
        username: '',
        phoneNumber: '',
        emailAddress: '',
        password: '',
        confirmPassword: '',
        birthdayDate: '',
        language: 'en'
    });

    const [errors, setErrors] = useState({
        allData: false,
        emailInUse: false,
        passwordStrength: false,
        confirmPassword: false,
    });

    const invalidEmailFormatError = 'Invalid_Email_Format';

    const handleInputChange = (field, value) => {
        clearErrors();
        if (field === 'phoneNumber' && value.startsWith('00')) {
            value = '+' + value.slice(2);
        } else if (field === 'username') {
            if (value.length < 4) {
                setErrors({ ...errors, usernameLength: true });
            } else {
                setErrors({ ...errors, usernameLength: false });
            }
        } else if (field === 'birthdayDate' && value) {
            const dateRegex = /^(\d{4})-(\d{1,2})-(\d{1,2})$/;
            if (!dateRegex.test(value)) {
                setErrors({ ...errors, birthdayFormat: true });
            } else {
                const dateParts = value.split('-');
                const year = parseInt(dateParts[0]);
                const month = parseInt(dateParts[1]);
                const day = parseInt(dateParts[2]);
        
                const currentDate = new Date();
                const currentYear = currentDate.getFullYear();
        
                if (isNaN(year) || year < 1900 || year > currentYear || month < 1 || month > 12 || day < 1 || day > 31) {
                    setErrors({ ...errors, birthdayFormat: true });
                } else {
                    setErrors({ ...errors, birthdayFormat: false });
                }
            }
        } else if (field === 'password' && value) {
            if (!isStrongPassword(value)) {
                setErrors({ ...errors, passwordStrength: true });
            } else {
                setErrors({ ...errors, passwordStrength: false });
            }
        }
        
        setFormData({ ...formData, [field]: value });
    };
    

    const handleSubmit = async () => {
        clearErrors();
        const { username, phoneNumber, emailAddress, password, confirmPassword, language, birthdayDate } = formData;

        const errors = {};
        if (!username || !phoneNumber || !emailAddress || !password || !confirmPassword || !birthdayDate) {
            errors.allData = true;
        } else {
            if (!isValidEmail(emailAddress)) {
                errors.emailFormat = true;
            } else if (confirmPassword !== password) {
                errors.confirmPassword = true;
            } else if (!isStrongPassword(password)) {
                errors.passwordStrength = true;
            }
        }
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }
        try {
            const response = await register(username, phoneNumber, emailAddress, password, language, birthdayDate);
            if (response) navigation.navigate('EmailVerify', { res: formData, pre: 'reg' });
        } catch (error) {
            console.log('error register', error.message);
            if (error.message === 'the_email_has_already_been_taken') {
                setErrors({ emailInUse: true });
            } else if (error.message === 'the_phone_number_has_already_been_taken') {
                setErrors({ phoneInUse: true });
            } else if (error.message === 'the_phone_number_is_not_a_valid_mobile_number' || error.message === 'The phone number is not a valid mobile number.') {
                setErrors({ phoneInvalid: true });
            } else {
                console.error('Registration Error:', error);
            }
        }
    };

    const clearErrors = () => {
        setErrors({
            allData: false,
            emailInUse: false,
            passwordStrength: false,
            confirmPassword: false,
            phoneInUse: false,
            phoneInvalid: false,
        });
    };

    const renderError = (error) => {
        return error && <Text style={styles.error}>{t(error)}</Text>;
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const isStrongPassword = (password) => {
        return password.length >= 6 && /\d/.test(password) && /[!@#$%^&*(),.?":{}|<>]/.test(password);
    };

    useEffect(() => {
        const isFormDataValid = () => {
            const { username, phoneNumber, emailAddress, password, confirmPassword, birthdayDate } = formData;
            return username && phoneNumber && emailAddress && password && confirmPassword && birthdayDate && Object.values(errors).every(value => !value);
        };

        setClickable(isFormDataValid());
    }, [formData, errors]);

    const pageContent = () => {
        return (
            <KeyboardAvoidingView style={currentPlatform === 'web' ? {
                width: screenWidth / 2,
                alignSelf: 'center',
                marginTop: 100
            } :{}}>
            {currentPlatform !== 'web' && <Header title={t('Sign_Up')} />}
            <View style={styles.inputsContainer}>
                <Input
                    lable={t('Username')}
                    placeholder={t('Username')}
                    onChange={(text) => handleInputChange('username', text)}
                    value={formData.username}
                />
                {renderError(errors.usernameLength && 'Username_Requires_Min_4_Characters')}

                <Input
                    lable={t('date_of_birth')}
                    placeholder={t('format_date')}
                    onChange={(text) => handleInputChange('birthdayDate', text)}
                    keyboardType='decimal-pad'
                    value={formData.birthdayDate}
                />
                {renderError(errors.birthdayFormat && 'Invalid_Date_Format')}
                <Input
                    lable={t('Phone_Number')}
                    placeholder={t('Phone_Number_placeholder')}
                    onChange={(text) => handleInputChange('phoneNumber', text)}
                    keyboardType='phone-pad'
                    value={formData.phoneNumber}
                />
                {renderError(errors.phoneInUse && 'the_phone_number_has_already_been_taken')}
                {renderError(errors.phoneInvalid && 'the_phone_number_is_not_a_valid_mobile_number')} 
                <Input
                    lable={t('Email_Address')}
                    placeholder={t('Email_Address')}
                    onChange={(text) => handleInputChange('emailAddress', text)}
                    keyboardType='email-address'
                    value={formData.emailAddress}
                />
                {renderError(errors.emailInUse && 'Email_Already_In_Use')}
                {renderError(errors.emailFormat && invalidEmailFormatError)}                
                <Input
                    lable={t('Password')}
                    placeholder={t('Password')}
                    secureTextEntry
                    onChange={(text) => handleInputChange('password', text)}
                    type='password'
                    value={formData.password}
                />
                {renderError(errors.passwordStrength && 'Weak_Password')}

                <Input
                    lable={t('Confirm_Password')}
                    placeholder={t('Confirm_Password')}
                    secureTextEntry
                    onChange={(text) => handleInputChange('confirmPassword', text)}
                    type='password'
                    value={formData.confirmPassword}
                />
                <View style={{ ...styles.imgDiv }}>
                    <Text style={{ ...mainStyle.inputLabel, color: currentThemeColor.primaryTextColor, alignSelf: lang == 'ar' ? 'flex-end' : 'flex-start' }}>{t("App_Language")}</Text>
                    <SelectList 
                        placeholder={placeholderLangLabel}
                        data={Languages} 
                        setSelected={(selectedItem, index) =>{
                            const langValue = Languages.find(item => item.value === selectedItem)?.storedValue;
                            handleInputChange('language', langValue)}} 
                        boxStyles={styles.btnLang} 
                        dropdownTextStyles={styles.btnText} 
                        arrowicon={<Image source={arrowDown} style={{ width: 25, height: 25, left: currentPlatform !== 'web' ? '20%' : '0%' }} />}
                        closeicon={<Image source={arrowUp} style={{ width: 25, height: 25 }} />}
                        dropdownStyles={{ backgroundColor: '#D9D9D9', borderColor: '#D9D9D9', width: '100%' }} 
                        dropdownItemStyles={{ backgroundColor: '#D9D9D9', width: 110, maxWidth: 110 }} 
                        search={false} 
                    />
                </View>
                {renderError(errors.confirmPassword && 'Passwords_Doesnt_Match')}
                {renderError(errors.allData && 'Fill_All_Data')}
                <CustomButton
                    btnStyle={{ marginTop: 30, marginBottom: screenHeight > 585 ? 70 : 0 }}
                    text={t('Sign_Up')}
                    onPress={handleSubmit}
                    enable={clickable}
                />
            </View>
        </KeyboardAvoidingView>
        )
    }

    return currentPlatform === 'web' ? (
        <WebBackgroundTopColor elementOnImage={<Header title={t('Sign_Up')} />}>
            {pageContent()}
            <Image source={lang === 'ar' ? panda92 : panda9} style={lang === 'ar' ? mainStyle.panda92 : mainStyle.panda9} />
        </WebBackgroundTopColor>
    ) :  (
        <View style={mainStyle.mainContainer}>
            <ImageBackground
                source={currentTheme === 'dark' ? back2Dark : back2}
                style={{ width: screenWidth, height: screenHeight }}
                resizeMode='cover'>
                <ScrollView>
                    {pageContent()}
                </ScrollView>
            </ImageBackground>
            <Image source={lang === 'ar' ? panda92 : panda9} style={lang === 'ar' ? mainStyle.panda92 : mainStyle.panda9} />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.mainBackground,
    },
    inputsContainer: {
        width: '90%',
        alignSelf: 'center',
        paddingVertical: 20,
    },
    error: {
        color: 'red',
        fontFamily: FONTFAMILY,
        fontSize: 16,
        textAlign: 'center',
    },
    btnText: { color: '#000', fontSize: 15 },
    btnLang: {
        marginTop: 4,
        borderColor:'#D9D9D9',
        ...mainStyle.inputDiv

    },
});

export default SignupScreen;
