import { View, StyleSheet, ImageBackground, Image, Text, ScrollView } from 'react-native';
import { currentPlatform, screenHeight, screenWidth } from '../../constants/Colors';
import back2 from '../../assets/background/back2.png';
import panda1 from '../../assets/panda/panda1.png';
import panda2 from '../../assets/panda/panda2.png';
import back2Dark from '../../assets/background/back2Dark.png';
import { mainStyle } from '../../mainstyle';
import CustomButton from '../../components/customButton';
import { useAuth } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { customGet, get, post } from '../../WebService/RequestBuilder';
import globalConstants from '../../config/globalConstants';
import { Fragment, useEffect, useState } from 'react';
import CustomLoader from '../../components/CustomLoader';
import { WebBackgroundTopColor } from '../../components/WebBackground';
import { storeJsonData } from '../../helper/GeneralStorage';

const EnrollCourseScreen = ({ navigation, route }) => {
    const { t } = useTranslation();
    const { currentTheme, currentThemeColor, subUser, setCurrentSubUserCourse, setCurrentTutorials, currentCourseName } = useAuth();
    const { selected, newCourse, descriptionCourse, newTitle } = route?.params;
    const [title, setTitle] = useState(newTitle);
    const [description, setDescription] = useState(descriptionCourse);
    const [isLoading, setIsLoading] = useState(true);
    const [loadingText, setLoadingText] = useState('');

    console.log("descriptionCourse", descriptionCourse)

    useEffect(() => {
        const checkCourse = async () => {
            try {
                if (selected[0].id) {
                    const data = await customGet(globalConstants.userCourses.checkHavingCourse, {
                        user_info_id: subUser.id,
                        course_id: selected[0].id
                    });
                    if (newCourse && data.last_visit === 'introduction_tutorials') {
                        setTitle('level_tutorials');
                    } else if (!newCourse && data.user_course.reached_page === 'questions') {
                        setTitle('Level_Exam');
                    } else {
                        setTitle('Quick_Exam');
                    }
                }
            } catch (error) {
                console.log("error", error);
            } finally {
                setIsLoading(false)
            }
        }

        checkCourse();
    }, [selected, subUser]);

    const enterCourse = async () => {
        setIsLoading(true);
        try {
            setLoadingText('Getting_your_course')
            await post(globalConstants.userCourses.newCourse, {
                user_info_id: subUser.id,
                course_id: selected[0].id,
            }).then(async (courseData) => {
                setCurrentSubUserCourse(courseData.user_course);
                await storeJsonData(globalConstants.currentSubUserCourse, courseData.user_course);
                if (newCourse && courseData.user_course.reached_page === 'tutorials') {
                    setLoadingText('Wait_for_your_tutorials')
                    await post(globalConstants.chatGPT.userLevelTutorials, {
                        user_course_id: courseData.user_course.id,
                    }).then(({ data }) => {
                        setCurrentTutorials({ ...data, user_course_id: courseData.user_course.id })

                        navigation.navigate('UserTutorialsScreen', { user_course_id: courseData.user_course.id })
                    })
                    return
                } else {
                    setLoadingText('Quick_exam_is_being_prepared')
                    const { data } = await post(globalConstants.chatGPT.userQuickExam, {
                        user_course_id: courseData.user_course.id
                    });

                    navigation.navigate('QuickExamScreen', { userCourse: courseData.user_course, courseImage: selected[0].image, questions: data })
                    return
                }
            })
        } catch (error) {
            console.log("error in enterCourse: ", error);
        }
    }

    if (isLoading) {
        return (
            <CustomLoader text={t(loadingText)} />
        )
    }

    const pageContent = () => {
        return (
            <Fragment>
                <View style={mainStyle.qmainDiv}>
                    <View style={mainStyle.qDiv1}>
                        <Image source={{ uri: selected[0].image }} style={{ width: 150, height: 150, resizeMode: 'contain' }} />
                        < Text style={{ ...mainStyle.qDiv1Style, color: currentThemeColor.primaryTextColor }}>{t(title)}</Text>
                    </View>

                    <View style={mainStyle.qDiv2}>
                        <Text style={{ ...mainStyle.qDiv1Style, color: currentThemeColor.primaryTextColor }}>{t(description)}</Text>
                    </View>

                    <View style={mainStyle.qDiv3}>
                        <View style={{ ...mainStyle.qDiv3Sub, justifyContent: 'center', marginBottom: 10 }} >
                            <CustomButton isSubmit btnStyle={{ width: '80%', }} text={t('start')} onPress={enterCourse} />
                        </View>
                    </View>

                </View>

            </Fragment>
        )
    }

    return (
        currentPlatform === 'web' ? (
            <WebBackgroundTopColor>
                {pageContent()}
                <Image source={panda1} style={mainStyle.panda1} />
                <Image source={panda2} style={mainStyle.panda2} />
            </WebBackgroundTopColor>
        ) : <View style={mainStyle.mainContainer}>
            <ImageBackground source={currentTheme == 'dark' ? back2Dark : back2} style={{ width: screenWidth, height: screenHeight, }} resizeMode='cover' >
                <ScrollView>
                    {pageContent()}
                </ScrollView>
                <Image source={panda1} style={mainStyle.panda1} />
                <Image source={panda2} style={mainStyle.panda2} />
            </ImageBackground >
        </View >
    );
}

const styles = StyleSheet.create({

})

export default EnrollCourseScreen