import React, { useEffect, useRef, useState } from "react";
import { Image, ImageBackground, Modal, SafeAreaView, ScrollView, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { useHover } from 'react-native-web-hooks';
import { CommonActions } from '@react-navigation/native';

import { isIos, isWeb } from "../../../constants/Colors";
import lettuce from '../../../assets/game/lettuce.png';
import garden4 from '../../../assets/game/garden4.jpg';

import shovel from '../../../assets/game/shovel.png';
import tomatoes1 from '../../../assets/game/tomatoes1.png';

import vegetableBox from '../../../assets/game/vegetableBox.png';
import lettuceGarden from '../../../assets/game/lettuceGarden.png';
import tomato from '../../../assets/game/tomato.png';

import { sharingGameStyle } from "../../../sharingGameStyle";
import GameModalView from "../../../components/GameModalView";
import { useTranslation } from "react-i18next";
import frontGirl from '../../../assets/game/characters/frontGirl.png';
import frontPanda from '../../../assets/game/characters/frontPanda.png';
import frontBoy from '../../../assets/game/characters/frontBoy.png';
import { post } from "../../../WebService/RequestBuilder";
import globalConstants from "../../../config/globalConstants";

const GardenLevelScreen = ({navigation, route}) => {
    const { t } = useTranslation();

    const {chosenCharacter, userCourseId, lang, game, isChickenBurger, currentSandwichNumber, facts } = route?.params;

    const [isCloseHovered, setIsCloseHovered] = useState(false);
   
    const [tomatoInBoxNum, setTomatoInBoxNum] = useState(0)
    const [lettuceInBoxNum, setLettuceInBoxNum] = useState(0)
    const [lettuceNum, setLettuceNum] = useState(4)
    const [tomatoNum, setTomatoNum] = useState(4)
    const [goToKitchen, setGoToKitchen] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentFact, setCurrentFact] = useState(facts[0]);
    const [modalVisible, setModalVisible] = useState(false);  
    const [gameData, setGameData] = useState(game);
    const [currentImage, setCurrentImage] = useState(null);

    const ref = useRef(null);

    const isHovered = useHover(ref);

    useEffect(() => {
        if(tomatoNum === 0 && lettuceNum === 0) setGoToKitchen(true);
        else setGoToKitchen(false);
    }, [tomatoNum, lettuceNum])

    const handleCloseHover = () => {
        setIsCloseHovered(true);
    };

    const handleLeaveCloseHover = () => {
        setIsCloseHovered(false);
    };

    const addTomatoToBox = () => {
        setTomatoInBoxNum(tomatoInBoxNum + 3);
        setTomatoNum(tomatoNum - 1);
    }

    const addLettuceToBox = () => {
        setLettuceInBoxNum(lettuceInBoxNum + 2);
        setLettuceNum(lettuceNum - 1);
    }

    const actionOnClick = async () => {
        try {
            const response = await post(globalConstants.userCourses.setFactAsRead, {
                game_fact_id: currentFact?.id,
            });
            setGameData({...gameData, ...response });
            setCurrentIndex(currentIndex + 1);
        } catch (error) {
            console.log('error actionOnClick', error);
        }
    }

    useEffect(() => {
        if(facts.length > 0) {
            setModalVisible(true);
        }
    }, [facts])

    useEffect(() => {
        switch (chosenCharacter) {
            case 'boy':
                setCurrentImage(frontBoy)
                break;
            case 'girl':
                setCurrentImage(frontGirl)
                break;
            default:
                setCurrentImage(frontPanda)
                break;
        }
    }, [chosenCharacter]);

    useEffect(() => {
        if (facts.length <= currentIndex) {
            setModalVisible(false);
        } else {
            setCurrentFact(facts[currentIndex]);
        }
    }, [currentIndex, facts]);

    return (
        <SafeAreaView style={{overflow: 'hidden'}}>
            <ImageBackground
                source={garden4}
                style={sharingGameStyle.backgroundImage}
                resizeMode='cover'
            >
                <Text style={sharingGameStyle.levelText}>
                {t('Lv')} {gameData.level} - {t('Pt')} {gameData.points}
                </Text>
               
                <TouchableOpacity
                    onPress={() => {
                        navigation.dispatch(
                            CommonActions.reset({
                              index: 0,
                              routes: [{ name: 'MainStack', state: { routes: [{ name: 'BottomTabNavigator' }] } }],
                            })
                          );
                    }}
                    onPressIn={handleCloseHover}
                    onPressOut={handleLeaveCloseHover}
                    ref={ref}
                    style={sharingGameStyle.closeTouchable}
                >
                    <Text style={[sharingGameStyle.close, (isHovered || isCloseHovered) && { ...sharingGameStyle.closeHover }, isWeb && {cursor: 'pointer'} ]}>
                    {t('CLOSE')}
                    </Text>
                </TouchableOpacity>                
                <Image source={currentImage} style={[sharingGameStyle.playerCharacter, styles.boyCharacterGarden]} />

                <Image source={shovel} style={styles.shovel} />

                <View style={styles.mainItemGardenView}>
                    <View style={styles.subItemGardenView}>
                        {[...Array(tomatoNum)].map((_, index) => (
                            <TouchableOpacity onPress={addTomatoToBox} key={`t-${index}`}>
                                <Image source={tomatoes1} key={`ti-${index}`} style={styles.tomatoes} />
                            </TouchableOpacity>
                        ))}
                    </View>
                    <View style={[styles.subItemGardenView, {marginBottom: -20}]}>
                        {[...Array(lettuceNum)].map((_, index) => (
                            <TouchableOpacity onPress={addLettuceToBox} key={`l-${index}`}>
                                <Image source={lettuceGarden} key={`li-${index}`} style={[styles.tomatoes, , {top: 20}]} />
                            </TouchableOpacity>
                        ))}
                    </View>
                </View>

                <View style={styles.itemInBoxView}>
                    <View>
                        <View style={styles.itemInBox}>
                            {[...Array(tomatoInBoxNum)].map((_, index) => (
                                <Image source={tomato} key={`TB-${index}`} style={styles.tomato} />
                            ))}
                        </View>
                        <Image source={vegetableBox} style={styles.vegetableBox} />
                    </View>
                    <View>
                        <View style={styles.itemInBox}>
                            {[...Array(lettuceInBoxNum)].map((_, index) => (
                                <Image source={lettuce} key={`LB-${index}`} style={styles.lettuceInB} />
                            ))}
                        </View>
                        <Image source={vegetableBox} style={styles.vegetableBox} />
                    </View>                    
                </View>
                <GameModalView title={t('Great_job')} body={t('Lets_go_to_kitchen_to_prepare_your_sandwich')} action={() => {
                    navigation.navigate('KitchenGameScreen', {chosenCharacter, userCourseId, lang, game: gameData, isChickenBurger, currentSandwichNumber, facts: [] });
                    setGoToKitchen(false)
                }} isOpen={goToKitchen} lang={lang}
                style={{height: isWeb ? 500 : 200, maxHeight: isWeb ? 500 : 200, marginTop: 20}} />

                <GameModalView
                    title={currentFact?.title}
                    body={currentFact?.text}
                    isOpen={modalVisible}
                    action={actionOnClick}
                    lang={lang}
                />
            </ImageBackground>
        </SafeAreaView>
    );
}


const styles = StyleSheet.create({
    boyCharacterGarden: {
        left: isWeb ? '8%' : '3%',
        bottom: isWeb ? '5%' : '10%',
        zIndex: 10,
    },
    shovel: {
        position: 'absolute',
        width: isWeb ? 120 : 90,
        height: isWeb ? 120 : 90,
        resizeMode: 'contain',
        left: isWeb ? '1%' : '1%',
        bottom: isWeb ? '2%' : '10%',
    },
    mainItemGardenView: {
        position: 'absolute',
        left: isWeb ? '25%' : '17%',
        bottom: isWeb ? '5%' : '10%',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    subItemGardenView: {
        width: isWeb ? 260 : 170,
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
    },
    tomatoes: {
        width: isWeb ? 120 : 80,
        height: isWeb ? 120 : 80,
        resizeMode: 'contain',
    },
    vegetableBox: {
        width: isWeb ? 120 : 90,
        height: isWeb ? 120 : 90,
        resizeMode: 'contain',
        marginRight: 10,
    },
    itemInBoxView: {
        position: 'absolute',
        right: isIos ? '15%' : isWeb ? '14%' : '6%',
        bottom: isWeb ? '5%' : '8%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    itemInBox: {
        width: isWeb ? 120 : 90,
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        left: 10,
        top: isWeb ? 70 : 60, 
    },
    tomato: {
        width: isWeb ? 35 : 25,
        height: isWeb ?  35 : 30,
        resizeMode: 'contain',
        marginBottom: -10,
    },
    lettuceInB:{
        width: isWeb ? 55 : 45,
        height: isWeb ?  50 : 40,
        resizeMode: 'contain',
        marginBottom: isWeb ?  -25 : -24,
        marginLeft: isWeb ? 0 : -10,
    },
});

export default GardenLevelScreen;