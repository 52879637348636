import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { View, StyleSheet, ImageBackground, Image, Text, Modal, ScrollView, TouchableOpacity, Dimensions } from 'react-native';
import { FONTFAMILY, currentPlatform, screenHeight, screenWidth } from '../../constants/Colors';
import back2 from '../../assets/background/back2.png';
import back2Dark from '../../assets/background/back2Dark.png';
import panda1 from '../../assets/panda/panda1.png';
import panda2 from '../../assets/panda/panda2.png';
import { mainStyle } from '../../mainstyle';
import CustomButton from '../../components/customButton';
import branch from '../../assets/panda/branch.png';
import { useAuth } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import globalConstants from '../../config/globalConstants';
import { post } from '../../WebService/RequestBuilder';
import CustomLoader from '../../components/CustomLoader';
import { WebBackgroundTopColor } from '../../components/WebBackground';
import AskMrCode from '../../components/askMrCode';
import ExplanationModal from '../../components/ExplanationModal';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const UserTutorialsScreen = ({ navigation, route }) => {
    const { t } = useTranslation();
    const { currentTheme, currentThemeColor, currentTutorials, currentSubUserCourseImage, currentCourseName, lang } = useAuth();
    const javascriptPlaceHolder = require('../../assets/background/JavaScript-logo.png');

    const [modalVisible, setModalVisible] = useState(false);
    const [item, setItem] = useState(currentTutorials.tutorials[currentTutorials.current_tutorial - 1]);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('Getting_tutorial');
    const [explanationModalVisible, setExplanationModalVisible] = useState(false);
    const [explanation, setExplanation] = useState(null);
    const [explanationLoader, setExplanationLoader] = useState(false);


    const getExplanation = async () => {
        try {
            setExplanationLoader(true)
            const { data } = await post(globalConstants.chatGPT.getExplanation, {
                tutorial: item.title
            });
            setExplanation(data?.explanation);
            setExplanationLoader(false)
        } catch (error) {
            console.log('Error occurred:', error);
            setExplanationLoader(false)
        }
    };

    const goToQuestions = async () => {
        try {
            setIsLoading(true);
            setLoadingText(t('Hold tight! Our AI is crafting some cool questions just for you'))
            await post(globalConstants.chatGPT.userTutorialQuestions, {
                user_level_tutorial_id: item.id
            }).then(({ data }) => {
                navigation.navigate('ExamScreen', { ...data, user_course_id: currentTutorials.user_course_id })
            })
        } catch (error) {
            console.log('error in userTutorialQuestions: ', error);
        }
    };

    useEffect(() => {
        setItem(currentTutorials.tutorials[currentTutorials.current_tutorial - 1])
        setIsLoading(false)
        getExplanation()
    }, [currentTutorials.current_tutorial])

    const randomNumber = useMemo(() => {
        const getRandomNumber = (min, max) => {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        };
        return getRandomNumber(1, 16);
    }, []);

    const nextScreen = useCallback(() => {
        setModalVisible(false);
        setTimeout(() => {
            navigation.navigate('FirstGameScreen', { randomNumber });
        }, 300);
    }, [navigation, randomNumber]);

    if (isLoading) {
        return (
            <CustomLoader text={loadingText} />
        )
    }
    const pageContent = () => {
        return (
            <Fragment>
                <View style={mainStyle.qmainDiv}>
                    <ScrollView>
                        {currentSubUserCourseImage && <Image source={
                            currentSubUserCourseImage
                                ? { uri: currentSubUserCourseImage }
                                : javascriptPlaceHolder
                        } style={{ width: 150, height: 150, resizeMode: 'contain', top: 40, alignSelf: 'center' }} />}

                        <View style={{ ...mainStyle.qDiv1, justifyContent: 'space-around' }}>
                            <Text style={{ ...mainStyle.qDiv1Style, color: currentThemeColor.primaryTextColor }}>{item.title}</Text>
                        </View>
                        <View style={{ ...mainStyle.qDiv2, justifyContent: 'space-around' }}>
                            <Text style={{ ...mainStyle.qDiv2Style, color: currentThemeColor.primaryTextColor }}>{item.tutorial}</Text>
                        </View>
                        <View style={{ ...mainStyle.qDiv2, justifyContent: 'space-around' }}>
                            <Text style={{ ...mainStyle.qDiv2Style, color: currentThemeColor.primaryTextColor }}>{item.code_example}</Text>
                        </View>
                        <View style={{ ...mainStyle.qDiv2, justifyContent: 'space-around' }}>
                            <Text style={{ ...mainStyle.qDiv2Style, color: currentThemeColor.primaryTextColor }}>{item.improved_code_example}</Text>
                        </View>
                        <View style={{ ...mainStyle.qDiv3, marginVertical: 50 }}>
                            <CustomButton isSubmit btnStyle={{ width: '96%', }} text={t('Questions')} onPress={goToQuestions} />
                        </View>
                    </ScrollView>
                </View>
                <View style={{ flex: 1 }}>
                    <Image source={panda1} style={mainStyle.panda1} />
                    <Image source={panda2} style={mainStyle.panda2} />
                </View>
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={modalVisible}
                    coverScreen={false}
                    onSwipeComplete={() => setModalVisible(false)}
                >
                    <View style={styles.ModalContainer}>
                        <View style={styles.ModalView}>
                            <View>
                                <Text style={styles.hText}>{t("Lets_Explore_Programming_Through_Play")}</Text>
                                <Text style={styles.pText}>{t("Now_that_weve_learned_a_bit_about_programming")}</Text>
                            </View>
                            <CustomButton isSubmit btnStyle={{ width: currentPlatform === 'web' ? '75%' : '96%', marginTop: currentPlatform === 'web' ? 5 : 50 }} text={t("LetsStart")} onPress={nextScreen} />
                            <Image source={branch} style={mainStyle.branch} />
                        </View>
                    </View>
                </Modal>
            </Fragment>
        )
    }

    return (
        currentPlatform === 'web' ? (
            <WebBackgroundTopColor>
                {pageContent()}
                <AskMrCode currentCourseName={currentCourseName} lang={lang} />
                <TouchableOpacity onPress={() => setExplanationModalVisible(true)} style={[styles.questionMark, { right: windowWidth * 0.05, bottom: windowHeight * 0.1 }]}>
                    <Text style={styles.questionMarkText}>?</Text>
                </TouchableOpacity>
                <ExplanationModal
                    visible={explanationModalVisible}
                    onClose={() => setExplanationModalVisible(false)}
                    explanation={explanation}
                    explanationLoader={explanationLoader}
                />
            </WebBackgroundTopColor>
        ) : (
            <View style={mainStyle.mainContainer}>
                <ImageBackground source={currentTheme == 'dark' ? back2Dark : back2} style={{ width: screenWidth, height: screenHeight }} resizeMode='cover'>
                    {pageContent()}
                    <AskMrCode currentCourseName={currentCourseName} lang={lang} />
                    <TouchableOpacity onPress={() => setExplanationModalVisible(true)} style={[styles.questionMark, { right: windowWidth * 0.04, bottom: windowHeight * 0.12 }]}>
                        <Text style={styles.questionMarkText}>?</Text>
                    </TouchableOpacity>
                    <ExplanationModal
                        visible={explanationModalVisible}
                        onClose={() => setExplanationModalVisible(false)}
                        explanation={explanation}
                        explanationLoader={explanationLoader}
                    />
                </ImageBackground>
            </View>
        )
    );

}

const styles = StyleSheet.create({
    ModalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#0707078c',
    },
    absolutePosition: {
        position: 'absolute',
        bottom: 100,
        right: 20,
        zIndex: 10,
        elevation: 10,
    },
    ModalView: {
        backgroundColor: "white",
        borderRadius: 20,
        width: '90%',
        paddingVertical: 40,
        paddingHorizontal: '6%',
        alignItems: "center",
        justifyContent: 'center',
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5
    },
    check: {
        backgroundColor: "#3da2f5",
        width: 70,
        height: 70,
        marginBottom: 30,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 99,
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 11, },
        shadowOpacity: 0.57,
        shadowRadius: 15.19,
        elevation: 19,
    },
    hText: {
        fontFamily: FONTFAMILY,
        color: '#000',
        textAlign: 'center',
        fontSize: 22,
        marginBottom: 20,
    },
    pText: {
        fontFamily: FONTFAMILY,
        color: '#000',
        textAlign: 'center',
        fontSize: 16,
    },
    questionMark: {
        position: 'absolute',
        backgroundColor: '#3da2f5',
        width: 30,
        height: 30,
        borderRadius: 25,
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 10,
        elevation: 10,
    },
    questionMarkText: {
        color: '#fff',
        fontSize: 24,
        fontWeight: 'bold',
    },
})


export default UserTutorialsScreen