import { View, StyleSheet, ImageBackground, Image, Text, SafeAreaView, ScrollView } from 'react-native';
import { currentPlatform, screenHeight, screenWidth } from '../../constants/Colors';
import back2 from '../../assets/background/back2.png';
import back2Dark from '../../assets/background/back2Dark.png';
import panda1 from '../../assets/panda/panda1.png';
import panda2 from '../../assets/panda/panda2.png';
import { mainStyle } from '../../mainstyle';
import CustomButton from '../../components/customButton';
import { useAuth } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { post } from '../../WebService/RequestBuilder';
import globalConstants from '../../config/globalConstants';
import { Fragment, useEffect, useState } from 'react';
import CustomLoader from '../../components/CustomLoader';
import { WebBackgroundTopColor } from '../../components/WebBackground';

const StartCodingIntroductionScreen = ({ navigation, route }) => {

    const { t } = useTranslation();
    const { currentTheme, currentThemeColor, subUser, lang } = useAuth();
    const [unreadTutorials, setUnreadTutorials] = useState([]);
    const [allTutorials, setAllTutorials] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [started, setStarted] = useState(false);

    const getAllTutorials = async () => {
        navigation.navigate('CodingIntroductionScreen', { introTutorials: allTutorials })
    }

    const getUnreadTutorials = async () => {
        navigation.navigate('CodingIntroductionScreen', { introTutorials: unreadTutorials })
    }

    useEffect(() => {
        const getTutorials = async () => {
            try {
                const body = {
                    user_info_id: subUser?.id,
                };
    
                const {data} = await post(globalConstants.chatGPT.introductionTutorials, body);

                setAllTutorials(data);
    
                const unreadTutorials = data.filter(tutorial => !tutorial.is_tutorial_read);
                setUnreadTutorials(unreadTutorials);

                if(unreadTutorials.length === 0) navigation.navigate('SetCourseScreen')

                setStarted(data.length !== unreadTutorials.length)
            } catch (error) {
                console.log("error in StartCodingIntroductionScreen getTutorials: ", error);
            } finally {
                setIsLoading(false)
            }
        }

        getTutorials();
    }, []);

    if(isLoading) {
        return (
            <CustomLoader text={t('Your_tutorials_is_being_prepared')} />
        )
    }

    const pageContent = () => {
        return (
            <Fragment>
                <View style={mainStyle.qmainDiv}>
                    <View style={mainStyle.qDiv1}>
                        < Text style={{ ...mainStyle.qDiv1Style, width: '60%', color: currentThemeColor.primaryTextColor }}>{t("Lets_Dive_into_Programming")}</Text>
                    </View>

                    <View style={mainStyle.qDiv2}>
                        <Text style={{ ...mainStyle.qDiv2Style, color: currentThemeColor.primaryTextColor }}>{t("Lets_learn_the_APCs")}</Text>
                    </View>

                    {!started&&<View style={mainStyle.qDiv3}>
                        <View style={{ ...mainStyle.qDiv3Sub, justifyContent: 'center' }} >
                            <CustomButton btnStyle={{ width: '80%' }} text={t('start')} onPress={getAllTutorials} />
                        </View>
                    </View>}

                    {started&&<View style={mainStyle.qDiv3}>
                        <View style={{ ...mainStyle.qDiv3, flexDirection: lang == 'ar' ? 'row-reverse' : 'row', justifyContent: 'center' }} >
                            <CustomButton isSubmit btnStyle={{ width: currentPlatform == 'web' ? '48%' : 150, marginRight: 5 }} text={t('Retake')} onPress={getAllTutorials} />
                            <CustomButton isSubmit btnStyle={{ width: currentPlatform == 'web' ? '48%' : 150, marginLeft: 5 }} text={t('Continue')} onPress={getUnreadTutorials} />
                        </View>
                    </View>}
                </View>
            </Fragment>
        )
    }

    return (
        currentPlatform === 'web' ? (
                <WebBackgroundTopColor>
                    {pageContent()}
                    <View style={{ flex: 1 }}>
                        <Image source={panda1} style={mainStyle.panda1} />
                        <Image source={panda2} style={mainStyle.panda2} />
                    </View>
                </WebBackgroundTopColor>
            ) :<View style={mainStyle.mainContainer}>
                <ImageBackground source={currentTheme == 'dark' ? back2Dark : back2} style={{ width: screenWidth, height: screenHeight, }} resizeMode='cover' >
                    <ScrollView>
                        {pageContent()}
                    </ScrollView>
                    <View style={{ flex: 1 }}>
                        <Image source={panda1} style={mainStyle.panda1} />
                        <Image source={panda2} style={mainStyle.panda2} />
                    </View>
                </ImageBackground >
            </View >
    );
}

const styles = StyleSheet.create({

})

export default StartCodingIntroductionScreen