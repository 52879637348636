import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { View, StyleSheet, ImageBackground, Image, Text, TouchableOpacity, ScrollView, Modal, TextInput } from 'react-native';
import { Colors, FONTFAMILY, HeaderHeight, currentPlatform, isWeb, screenHeight, screenWidth } from '../../constants/Colors';
import { mainStyle, preventOutlineOnWeb } from '../../mainstyle';
import CustomButton from '../../components/customButton';
import tree2 from '../../assets/panda/tree2.png';
import back2 from '../../assets/background/back2.png';
import back2Dark from '../../assets/background/back2Dark.png';
import panda1 from '../../assets/panda/panda1.png';
import { useAuth } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { get, post } from '../../WebService/RequestBuilder';
import globalConstants from '../../config/globalConstants';
import DraggableGrid from 'react-native-draggable-grid';
import { WebBackgroundTopColor } from '../../components/WebBackground';
import drag from '../../assets/drag.png';
import circleFull from '../../assets/circleFull.png';
import circleEmpty from '../../assets/circleEmpty.png';
import { storeJsonData } from '../../helper/GeneralStorage';


const QuickExamScreen = ({ navigation, route }) => {
    const { t } = useTranslation();
    const { currentTheme, currentThemeColor, setCurrentSubUserCourse, currentSubUserCourse, currentSubUserCourseImage, lang } = useAuth();
    const {userCourse, questions} = route?.params

    const [qIndex, setQIndex] = useState(userCourse.current_question - 1);
    const [currentQuestion, setCurrentQuestion] = useState(questions[userCourse.current_question - 1]);
    // const [answers, setAnswers] = useState([]);
    const [userAnswer, setUserAnswer] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [buttonClickable, setButtonClickable] = useState(false);
    const [gridData, setGridData] = useState([]);
    const [enableScroll, setEnableScroll] = useState(true);

    useEffect(() => {
        if(userAnswer.length > 0) {
            setButtonClickable(true);
        } else {
            setButtonClickable(false);
        }
    }, [userAnswer, currentQuestion]);

    useEffect(() => {
        if(currentQuestion && currentQuestion?.question_type === 'drag_drop') {
            const choicesArray = currentQuestion?.choices?.map((item, index) => {
                return { item, key: `${index}` };
            })
            setGridData(choicesArray);
        }
    }, [currentQuestion?.choices])

    const nextQ = async () => {
        await saveAnswer();

        let qCount = questions.length;
        if (qIndex + 1 == qCount) {
            const nextLevelData = await post(globalConstants.userCourses.nextLevel, {
                user_course_id: userCourse.id
            }).then((data) => {
                setCurrentSubUserCourse((prev) => {return {...prev, current_level: data.current_level, reached_page: 'tutorials'}});
                return data;
            })

            await storeJsonData(globalConstants.currentSubUserCourse, {...currentSubUserCourse, current_level: nextLevelData.current_level, reached_page: 'tutorials' });
            // setModalVisible(true)
            navigation.navigate('QuestionsResultsScreen', {tutorialId: currentQuestion?.user_quick_exam_id, action: 'GameStack', isQuickExam: true})
            return
        }
        if (userAnswer && qIndex + 1 < qCount) {
            // const newData = {
            //     question: currentQuestion.id,
            //     answer: userAnswer
            // };
            // const checkQ = pushQuestion(newData);
            // if (checkQ) {
            setQIndex(qIndex + 1);
            await post(globalConstants.userCourses.nextQuestion, {
                user_course_id: userCourse.id
            })
            // }
        }
    };

    useEffect(() => {
        let qCount = questions.length;
        if (qIndex < qCount) {
            let Question = questions[qIndex];
            setCurrentQuestion(Question)
        }
    }, [qIndex])

    const goToHome = () => {
        setModalVisible(false)
        navigation.navigate('MainStack')
    }

    const onClickCheckAllCorrect = (input) => {
        setUserAnswer((previous) => {
            const updatedAnswers = [...previous];
            const index = updatedAnswers.indexOf(input);
            if (index !== -1) {
                updatedAnswers.splice(index, 1);
            } else {
                updatedAnswers.push(input);
            }
            return updatedAnswers;
        });
    }

    const saveAnswer = async () => {
        try {
            await post(globalConstants.userCourses.saveAnswer, {
                user_tutorial_question_id: currentQuestion.id,
                answers: userAnswer,
                is_quick_exam: true
            });
        } catch (error) {
            console.log("saveAnswer error: ", error);
        } finally {
            setUserAnswer([]);
        }
    }

    const renderItem = (item) => {
        const imageSize = isWeb ? 30 : 40;
        const paddingLeftRight = lang === 'ar' ? {paddingRight: imageSize  + 10} : {paddingLeft: imageSize + 10};
    
        return (
            <View style={{position: 'relative'}} key={`${item.key}-v`}>
                <View style={{
                    flexDirection: 'row',
                    ...mainStyle.CustomButtonSubmit, 
                    borderRadius: 5, 
                    backgroundColor: Colors.btnColor,  
                    width: isWeb ? (screenWidth / 2) : screenWidth - 60, 
                    minHeight: isWeb ? 70 : 100
                }} key={item.key}>
                    <Text style={{...mainStyle.CustomButtonText, fontSize: 20, padding: 20, ...paddingLeftRight}}>{item.item}</Text>
                </View>
                <Image
                    source={drag} 
                    style={[{
                        position: 'absolute',
                        top: '25%',
                        height: imageSize,
                        width: imageSize,
                        zIndex: 10,
                        margin: 0,
                    }, lang === 'ar' ? {right: 10} : {left: 10}]} 
                />
            </View>
        );
    };
    
    

    const onDragRelease = (data) => {
        setGridData(data);
        const result = data?.map((item) => item.item);
        setUserAnswer(result);
        setEnableScroll(true);
    };

    const pageContent = () => {
        return (
            <Fragment>
                <View style={mainStyle.qmainDiv}>
                    <View style={{ ...mainStyle.questionsDiv1 }}>
                        {currentSubUserCourseImage&&<Image source={{uri: currentSubUserCourseImage}} style={{ width: 150, height: 150, resizeMode: 'contain', marginBottom: 10, }} />}
                        < Text style={{ ...mainStyle.qDiv1Style, color: currentThemeColor.primaryTextColor }}>{t("Question")} {qIndex + 1}</Text>
                        <Text style={{ ...mainStyle.qDiv1Style, color: currentThemeColor.primaryTextColor }}>{t(currentQuestion.question_type)}</Text>
                        <Text style={[mainStyle.qDiv1Style, {color: currentThemeColor.primaryTextColor, fontSize: 18} ]}>{t(`${currentQuestion.question_type}_description`)}</Text>
                    </View>

                    <View style={{ ...mainStyle.questionsDiv2 }}>
                        <Text style={{ ...mainStyle.qDiv2Style, color: currentThemeColor.primaryTextColor }}>{currentQuestion.question ? currentQuestion.question : null}</Text>
                    </View>

                    <View style={{ ...mainStyle.questionsDiv3, height: '40%' }}>
                        {currentQuestion.question_type == 'true_false' && <View style={mainStyle.qDiv3Sub} >
                            <CustomButton btnStyle={{ width: '48%', backgroundColor: userAnswer == currentQuestion.choices[0] ? Colors.secondary : Colors.btnColor }} text={t(currentQuestion?.choices[0]?.toLowerCase())} onPress={() => { setUserAnswer([currentQuestion.choices[0]]) }} />
                            <CustomButton btnStyle={{ width: '48%', backgroundColor: userAnswer == currentQuestion.choices[1] ? Colors.secondary : Colors.btnColor }} text={t(currentQuestion?.choices[1]?.toLowerCase())} onPress={() => { setUserAnswer([currentQuestion.choices[1]]) }} />
                        </View>}

                        {currentQuestion.question_type == 'numeric'&&<TextInput 
                            style={{ 
                                color: '#000',
                                fontSize: 30,                                
                                backgroundColor: '#d9dddf',
                                ...mainStyle.CustomButtonSubmit,
                                borderRadius: 5,
                                width: 150,
                                fontSize: 20,
                                textAlign: 'center',
                                ...preventOutlineOnWeb
                            }}
                            onChangeText={(text) => { setUserAnswer([text]) }}
                            placeholder={t("Number")}
                            placeholderTextColor="black"
                            keyboardType='numeric'
                        />}

                        {currentQuestion.question_type === 'multiple_choice' && (
                            <View style={mainStyle.choices}>
                                {currentQuestion?.choices?.map((choice, index) => {
                                    const isSelected = userAnswer[0] === choice;

                                    return (
                                        <CustomButton
                                            textStyle={{fontSize: 20, padding: 20}}
                                            key={`${index}`}
                                            btnStyle={{
                                                width: currentPlatform === 'web' ? screenWidth / 2 : '98%',
                                                marginBottom: 20,
                                                backgroundColor: isSelected ? Colors.secondary : Colors.btnColor
                                            }}
                                            text={choice}
                                            onPress={() => { setUserAnswer([choice]) }}
                                        />
                                    );
                                })}
                            </View>
                        )}

                        {currentQuestion.question_type == 'check_all_correct' && (
                            <View style={mainStyle.choices}>
                                {currentQuestion?.choices?.map((choice, index) => {
                                    const isSelected = userAnswer.includes(choice);
                                    
                                    return (
                                        <View style={{position: 'relative'}} key={`${index}-v`}>
                                            <CustomButton
                                                textStyle={{fontSize: 20, paddingVertical: 20, paddingHorizontal: 40}}
                                                key={`${index}`}
                                                btnStyle={{
                                                    width: currentPlatform === 'web' ? screenWidth / 2 : '98%',
                                                    marginBottom: 20,
                                                    backgroundColor: isSelected ? Colors.secondary : Colors.btnColor
                                                }}
                                                text={choice}
                                                onPress={() => { onClickCheckAllCorrect(choice) }}
                                            />
                                            <Image
                                                source={isSelected ? circleFull : circleEmpty} 
                                                style={[{
                                                    position: 'absolute',
                                                    top: '30%',
                                                    right: 20,
                                                    height: 10,
                                                    width: 10,
                                                    zIndex: 10,
                                                    margin: 0,
                                                }, lang === 'ar' ? {right: isWeb ? '22%' : 30} : {left: isWeb ? '22%' : 30}]} 
                                            />
                                        </View>
                                    );
                                })}
                            </View>
                        )}

                        {gridData?.length > 0 && currentQuestion.question_type == 'drag_drop' && <View style={[{minHeight: currentQuestion.choices.length * (currentPlatform === 'web' ? 120 : 150)}]}><DraggableGrid
                            numColumns={1}
                            renderItem={renderItem}
                            data={gridData}
                            onDragRelease={onDragRelease}
                            itemHeight={currentPlatform === 'web' ?  120: 150}
                            onDragItemActive={() => {setEnableScroll(false)}}
                            style={mainStyle.choices}
                        /></View>}
                        
                        <CustomButton isSubmit enable={buttonClickable} btnStyle={{                                                 width: currentPlatform === 'web' ? screenWidth / 2 : '96%', marginTop: 50,marginBottom: 50,padding: 20 }} text={t('Submit')} onPress={nextQ} />
                    </View>

                </View>

            <View style={{ flex: 1 }}>
                <Image source={panda1} style={mainStyle.panda1} />
            </View>
            {/* <Image source={panda2} style={mainStyle.panda2} /> */}
            <Modal
                visible={modalVisible}
                animationType="slide"
                transparent={true}
                onRequestClose={() => setModalVisible(false)}
            >
                <View style={{ flex: 2, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <View style={{ backgroundColor: 'white', borderRadius: 10, width: '80%', height: '50%', }}>
                        <View style={{ padding: 20, borderRadius: 10, width: '100%', height: '100%', }}>
                            <Text style={styles.ModalHeader}>{t("Now_you_finished_the_exam_you_can")}</Text>
                            <View style={{ width: '97%', paddingVertical: 10, alignSelf: 'center', marginTop: 50, flexDirection: 'row', justifyContent: 'space-between' }}>
                                <CustomButton isSubmit btnStyle={{ width: '100%', }} text={t('start')} onPress={() => { goToHome() }} />
                            </View>
                        </View>
                        <Image source={panda1} style={styles.panda1} />
                        <Image source={tree2} style={mainStyle.tree2} />
                    </View>
                </View>
            </Modal>
            </Fragment>
        )
    }
    
    return (
        currentPlatform === 'web' ? (
            <WebBackgroundTopColor>
                {pageContent()}
            </WebBackgroundTopColor>
        ) : <View style={mainStyle.mainContainer}>
            <ImageBackground source={currentTheme == 'dark' ? back2Dark : back2} style={{ width: screenWidth, height: screenHeight, }} resizeMode='cover' >
                <ScrollView scrollEnabled={enableScroll}>
                    {pageContent()}
                </ScrollView>
            </ImageBackground >
        </View >
    );
}

const styles = StyleSheet.create({
    ModalHeader: {
        textAlign: 'center',
        fontFamily: FONTFAMILY,
        width: '90%',
        color: '#000',
        fontSize: 20
    },
    panda1: {
        position: 'absolute',
        bottom: 0,
        left: '5%',
        width: 100,
        height: 100,
        resizeMode: 'contain'
    },
})

export default QuickExamScreen