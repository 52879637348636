import { useState } from 'react';
import { View, StyleSheet, ImageBackground, Image, TouchableOpacity, ScrollView, KeyboardAvoidingView } from 'react-native';
import { Colors, isWeb, screenHeight, screenWidth } from '../../../constants/Colors';
import Input from '../../../components/input';
import CustomButton from '../../../components/customButton';
import { mainStyle } from '../../../mainstyle';
import Header from '../../../components/header';

import back4 from '../../../assets/background/back4.png';
import back4Dark from '../../../assets/background/back4Dark.png';
import branch from '../../../assets/panda/branch.png';
import starF from '../../../assets/icons/starF.png';
import starO from '../../../assets/icons/starO.png';

import { useAuth } from '../../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { post } from '../../../WebService/RequestBuilder';
import globalConstants from '../../../config/globalConstants';
import CustomAlert from '../../../components/customAlert';
import { WebBackgroundTopColor } from '../../../components/WebBackground';

const FeedBackScreen = ({ navigation }) => {

    const { currentTheme, lang, subUser, currentThemeColor } = useAuth();
    const { t } = useTranslation();

    const [message, setMessage] = useState('');
    const [rate, setrate] = useState(5);
    const [showAlert, setShowAlert] = useState(false);
    const [showAlerterror, setshowAlerterror] = useState(false);

    const submit = () => {
        if (rate != '' || message != '') {
            const body = { stars: rate, message: message, user_info_id: subUser.id };
            post(globalConstants.user.createFeedback, body).then((res) => {
                setShowAlert(true)
                setrate(0)
                setMessage('')
            }).catch((error) => {
                console.log('error', error);
                setshowAlerterror(true)
            });
        }
    }

    const handleHideAlert = () => {
        setShowAlert(false);
        setshowAlerterror(false)
    };

    const pageContent = () => {
        return (
            <KeyboardAvoidingView>
                {!isWeb&&<Header title={t('Send_Feedback')} />}
                    <View style={styles.inputsContainer}>
                        <View style={{ height: 200, width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                            <TouchableOpacity onPress={() => { setrate(1) }} style={styles.imgDiv} >
                                <Image source={rate < 1 ? starO : starF} style={styles.imgBG} />
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => { setrate(2) }} style={styles.imgDiv} >
                                <Image source={rate < 2 ? starO : starF} style={styles.imgBG} />
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => { setrate(3) }} style={styles.imgDiv}>
                                <Image source={rate < 3 ? starO : starF} style={styles.imgBG} />
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => { setrate(4) }} style={styles.imgDiv} >
                                <Image source={rate < 4 ? starO : starF} style={styles.imgBG} />
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => { setrate(5) }} style={styles.imgDiv} >
                                <Image source={rate < 5 ? starO : starF} style={styles.imgBG} />
                            </TouchableOpacity>
                        </View>
                        <Input lable={t('Feedback_Message')} placeholder={t('Feedback_Message')} value={message} onChange={(text) => { setMessage(text); }} multiline />
                        <CustomButton btnStyle={{ marginTop: 15, backgroundColor: Colors.secondary }} text={t('Submit')} onPress={submit} />
                    </View></KeyboardAvoidingView>
        )
    }

    return (
        isWeb ? <WebBackgroundTopColor >
        <Header title={t('Send_Feedback')} />

        {pageContent()}
    </WebBackgroundTopColor> :<View style={mainStyle.mainContainer}>
            <ImageBackground source={currentTheme == 'dark' ? back4Dark : back4} style={{ width: screenWidth, height: screenHeight, }} resizeMode='cover' >
                <ScrollView>
                {pageContent()}
                </ScrollView>
            </ImageBackground >
            <Image source={branch} style={mainStyle.branch} />

            <CustomAlert title={t("Feedback_Sent_Successfully")} isVisible={showAlert} onHide={handleHideAlert} />
            <CustomAlert title={t("Something_Went_Wrong")} type='error' isVisible={showAlerterror} onHide={handleHideAlert} />

        </View >
    );
}

const styles = StyleSheet.create({
    inputsContainer: {
        width: isWeb ? screenWidth / 2 : '90%',
        alignSelf: 'center',
        paddingVertical: 20,
    },
    imgBG: {
        width: '100%',
        height: '100%',
        resizeMode: 'contain'
    },
    imgDiv: { width: 60, height: 60, marginHorizontal: 5 }
})

export default FeedBackScreen