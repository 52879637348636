import "react-native-reanimated";
import React, { useState, useMemo, useEffect, Fragment, useRef, useCallback } from "react";
import { Canvas, Path, Shadow, Skia } from "@shopify/react-native-skia";
import { line, scaleLinear, curveCatmullRom } from "d3";
import { View, StyleSheet, ScrollView, TouchableOpacity, Image, Text, Pressable } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { isIos, screenWidth } from "../../../constants/Colors";

import activeLevel from '../../../assets/home/activeLevel.png';
import endLevel from '../../../assets/home/endLevel.png';
import stars from '../../../assets/home/stars.png';
import panda8 from '../../../assets/panda/panda8.png';
import bigEllipse from '../../../assets/home/bigEllipse.png';
import groundEllipse from '../../../assets/home/groundEllipse.png';
import redGift from '../../../assets/home/redGift.png';
import orangeGift from '../../../assets/home/orangeGift.png';
import yellowGift from '../../../assets/home/yellowGift.png';
import heightTriangle from '../../../assets/home/heightTriangle.png';
import widthTriangle from '../../../assets/home/widthTriangle.png';
import widthTriangleDark from '../../../assets/home/widthTriangleDark.png';
import panda8left from '../../../assets/panda/panda8left.png';
import { useAuth } from '../../../contexts/AuthContext';
import { post } from "../../../WebService/RequestBuilder";
import globalConstants from "../../../config/globalConstants";
import CustomLoader from "../../../components/CustomLoader";
import { useTranslation } from "react-i18next";

const values = [1000, 200, 1500, 83, 1000, 1500, 600, 1200, 200, 300, 1500, 50, 500, 0, 260, 1400, 150, 800, 200, 150, 1000, 200, 1500, 83, 100, 1000, 600, 1500, 200, 1000, 1000, 50, 800, 500, 0, 260, 1400, 150, 800, 200, 150, 1000, 200];

const valuesIos = [200, 300, 1500, 50, 500, 0, 260, 1400, 150, 800, 200, 150, 1000, 200, 1500, 83, 100, 1000, 600, 1500, 200, 1000, 1000, 50, 800, 500, 0, 260, 1400, 150, 800, 200, 150, 1000, 200];


const originalData = isIos ? valuesIos.map((value, index) => ({ key: index, value })) : values.map((value, index) => ({ key: index, value }));

const ellipseDimensions = [
  { width: 22, height: 13 },
  { width: 32, height: 19 },
  { width: 97, height: 76 },
  { width: 165, height: 129 }
];

const triangleWidthDimensions = [
  { width: 149, height: 90 },
  { width: 452, height: 90 },
  { width: 152, height: 90 },
  { width: 102, height: 61 },
  { width: 82, height: 90 },
  { width: 91, height: 85 },
];

const giftSize = [
  { widthGift: 98, heightGift: 122 },
  { widthGift: 70, heightGift: 86 },
]

const ellipsePositions = [
  {x: 20, y: 20, ...ellipseDimensions[1]}, 
  {x: 60, y: 100, ...ellipseDimensions[0]}, 
  {x: 320, y: 40, ...ellipseDimensions[0]}, 
  {x: 320, y: 170, ...ellipseDimensions[0]}, 
  {x: 260, y: 190, ...ellipseDimensions[0]}, 
  {x: 300, y: 120, ...ellipseDimensions[1]}, 
  {x: isIos ? 270 : 180, y: isIos ? 230 : 270, ...ellipseDimensions[3], gift: 'red', ...giftSize[0], isSmall: false, relatedLevel: isIos ? 4 : 2}, 
  {x: isIos ? -20 : 0, y: isIos ? 420 : 520, ...ellipseDimensions[2], gift: 'yellow', ...giftSize[1], isSmall: true, relatedLevel: isIos ? 7 : 4},
  {x: 280, y: 400, ...ellipseDimensions[1]},
  {x: 300, y: 500, ...ellipseDimensions[0]},
  {x: 250, y: 700, ...ellipseDimensions[0]},
  {x: 0, y: 680, ...ellipseDimensions[1]},
  {x: isIos ? -100 : -20, y: 810, ...ellipseDimensions[3], gift: 'orange', ...giftSize[0], isSmall: false, relatedLevel: isIos ? 12 : 6},
  {x: 0, y: 940, ...ellipseDimensions[0]},
  {x: 300, y: 950, ...ellipseDimensions[1]},
  {x: isIos ? -30 : 260, y: isIos ? 1100 : 1000, ...ellipseDimensions[3], gift: 'red', ...giftSize[0], isSmall: false, relatedLevel: isIos ? 15 : 7},
  {x: 220, y: 1200, ...ellipseDimensions[1]},
  {x: 30, y: 1300, ...ellipseDimensions[0]},
  {x: 50, y: 1350, ...ellipseDimensions[1]},
  {x: 50, y: 1350, ...ellipseDimensions[1]},
  {x: 280, y: 1350, ...ellipseDimensions[0]},
  {x: 300, y: 1400, ...ellipseDimensions[1]},
  {x: 320, y: 1420, ...ellipseDimensions[0]},
  {x: isIos ? 300 : 270, y: isIos ? 1600 : 1540, ...ellipseDimensions[2], gift: 'yellow', ...giftSize[1], isSmall: true, relatedLevel: isIos ? 21 : 11},
  {x: 220, y: 1580, ...ellipseDimensions[1]},
  {x: 300, y: 1670, ...ellipseDimensions[0]},
  {x: 10, y: 1700, ...ellipseDimensions[1]},
  {x: isIos ? 300 : 0, y: 1770, ...ellipseDimensions[3], gift: 'orange', ...giftSize[0], isSmall: false, relatedLevel: isIos ? 24 : 12},
  {x: 0, y: 1900, ...ellipseDimensions[0]},
  {x: 20, y: 2000, ...ellipseDimensions[1]},
  {x: 270, y: isIos ? 2100 : 2000, ...ellipseDimensions[2], gift: 'red', ...giftSize[1], isSmall: true, relatedLevel: isIos? 27 : 14},
  {x: 300, y: 1900, ...ellipseDimensions[0]},
  {x: isIos ? 300 : 0, y: isIos ? 2200 : 2150, ...ellipseDimensions[3], gift: 'yellow', ...giftSize[0], isSmall: false, relatedLevel: isIos ? 29 : 15},
  {x: 10, y: 2300, ...ellipseDimensions[0]},
  {x: 350, y: 2300, ...ellipseDimensions[1]},
  !isIos ? {x: 300, y: 2360, ...ellipseDimensions[2], gift: 'orange', ...giftSize[1], isSmall: true, relatedLevel: 16} : {},
  !isIos ? {x: 200, y: 2180, ...ellipseDimensions[1]} : {},
  !isIos ? {x: 20, y: 2460, ...ellipseDimensions[2], gift: 'red', ...giftSize[1], isSmall: true, relatedLevel: 17} : {},
  {x: 20, y: 2390, ...ellipseDimensions[1]},
  !isIos ? {x: 220, y: 2650, ...ellipseDimensions[3], gift: 'yellow', ...giftSize[0], isSmall: false, relatedLevel: 18} : {},
  {x: 280, y: 2560, ...ellipseDimensions[0]},
  {x: 310, y: 2800, ...ellipseDimensions[1]},
  {x: 190, y: 2920, ...ellipseDimensions[0]},
  {x: 10, y: 3000, ...ellipseDimensions[1]},
  !isIos ? {x: 280, y: 3100, ...ellipseDimensions[2], gift: 'orange', ...giftSize[1], isSmall: true, relatedLevel: 21} : {},
  {x: 290, y: 3050, ...ellipseDimensions[0]},
  {x: 20, y: 3110, ...ellipseDimensions[1]},
  {x: 10, y: 3130, ...ellipseDimensions[0]},
  !isIos ? {x: -30, y: 3180, ...ellipseDimensions[3], gift: 'red', ...giftSize[0], isSmall: false, relatedLevel: 22} : {},
  {x: 320, y: 3300, ...ellipseDimensions[0]},
  {x: 200, y: 3370, ...ellipseDimensions[1]},
  !isIos ? {x: 200, y: 3440, ...ellipseDimensions[3], gift: 'yellow', ...giftSize[0], isSmall: false, relatedLevel: 23} : {},
  {x: 0, y: 3470, ...ellipseDimensions[0]},
  !isIos ? {x: 0, y: 3670, ...ellipseDimensions[2], gift: 'orange', ...giftSize[1], isSmall: true, relatedLevel: 24} : {},
  {x: 265, y: 3600, ...ellipseDimensions[1]},
  {x: 240, y: 3750, ...ellipseDimensions[0]},
  !isIos ? {x: 230, y: 3760, ...ellipseDimensions[3], gift: 'red', ...giftSize[0], isSmall: false, relatedLevel: 25} : {},
  !isIos ? {x: 40, y: 3970, ...ellipseDimensions[2], gift: 'yellow', ...giftSize[1], isSmall: true, relatedLevel: 26} : {},
  {x: 0, y: 3900, ...ellipseDimensions[0]},
  {x: 20, y: 3960, ...ellipseDimensions[1]},
  {x: 0, y: 4100, ...ellipseDimensions[0]},
  {x: 210, y: 4150, ...ellipseDimensions[1]},
  {x: 180, y: 4230, ...ellipseDimensions[0]},
  !isIos ? {x: 200, y: 4260, ...ellipseDimensions[3], gift: 'orange', ...giftSize[0], isSmall: false, relatedLevel: 28} : {},
  !isIos ? {x: -25, y: 4400, ...ellipseDimensions[2], gift: 'red', ...giftSize[1], isSmall: true, relatedLevel: 29} : {},
  !isIos ? {x: 220, y: 4535, ...ellipseDimensions[2], gift: 'yellow', ...giftSize[1], isSmall: true, relatedLevel: 30} : {},
  {x: 230, y: 4410, ...ellipseDimensions[0]},
  {x: 0, y: 4500, ...ellipseDimensions[1]},
  {x: 310, y: 4630, ...ellipseDimensions[1]},
  {x: 270, y: 4620, ...ellipseDimensions[0]},
];


const triangleWidthPositions = [
  {x: isIos ? -50 : 20, y: 25, ...triangleWidthDimensions[3], isDark: false}, 
  {x: 310, y: 30, ...triangleWidthDimensions[0], isDark: false}, 
  {x: isIos ? 300 : 220, y: 580, ...triangleWidthDimensions[2], isDark: false}, 
  {x: isIos ? 300 : 175, y: isIos ? 1200 : 1080, ...triangleWidthDimensions[0]},
  {x: 310, y: 1700, ...triangleWidthDimensions[5]},
  {x: -30, y: 1900, ...triangleWidthDimensions[5]},
  {x: 280, y: 2490, ...triangleWidthDimensions[3], isDark: true},
  {x: -10, y: 2870, ...triangleWidthDimensions[0], isDark: true},
  {x: 265, y: 3600, ...triangleWidthDimensions[4], isDark: true},
  {x: 265, y: 4100, ...triangleWidthDimensions[0], isDark: true},
  {x: 264, y: 4420, ...triangleWidthDimensions[3], isDark: true},
];

const triangleHeightPositions = [
  {x: 280, y: 630, ...triangleWidthDimensions[0]},
  {x: -10, y: isIos ? 1430 : 1370, ...triangleWidthDimensions[5]},
  {x: 150, y: 2650, ...triangleWidthDimensions[4]},
  {x: -40, y: 3730, ...triangleWidthDimensions[4]},
  {x: 230, y: 4150, ...triangleWidthDimensions[5]},
  {x: -40, y: 4590, ...triangleWidthDimensions[5]},
];

const SkiaHomeScreen = ({ navigation }) => {
  const { t } = useTranslation();

  const { currentSubUserCourse, setCurrentTutorials, currentTheme, currentThemeColor } = useAuth();
  const [transition, setTransition] = useState(1);
  const [state, setState] = useState({
    current: 0,
    next: 1,
  });
  const [points, setPoints] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentLevel, setCurrentLevel] = useState(currentSubUserCourse.current_level);
  const scrollViewRef = useRef(null);
  const [loadingNextPage, setLoadingNextPage] = useState(false);
  const [loadingText, setLoadingText] = useState('');

  const GRAPH_WIDTH = screenWidth;
  const GRAPH_HEIGHT = isIos ? originalData.length * 70 : originalData.length * 110;


  const makeGraph = (data) => {
    const max = Math.max(...data.map((val) => val.value));
    const min = Math.min(...data.map((val) => val.value));

    const x = scaleLinear().domain([0, max]).range([70, GRAPH_WIDTH - 50]);

    const y = scaleLinear()
      .domain(isIos ? [1, originalData.length - 2.4] : [10, originalData.length - 1.8])
      .range([GRAPH_HEIGHT, 80]);

    const tension = 0.6;
    const curvedLine = line()
      .x((d) => x(d.value))
      .y((d) => y(d.key))
      .curve(curveCatmullRom.alpha(tension))(data);
    //   curveCardinal, curveBasis, curveBundle
    const skPath = curvedLine ? Skia.Path.MakeFromSVGString(curvedLine) : null;

    useMemo(() => {
        if(points.length == 0) {
            const positions = data.map((d) => {
              const point = { x: x(d.value), y: y(d.key) };

              return point;
            });

            const indexesToRemove = new Set(isIos ? [0, 1, 2, 18, positions.length - 1] : [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 18, positions.length - 1])
            const filteredPoints = positions.filter((_, index) => !indexesToRemove.has(index));

            setPoints(filteredPoints);
        }
      }, []);

    return {
      max,
      min,
      curve: skPath || null,
    };
  };

  useEffect(() => {
    if (scrollViewRef?.current && currentLevel && points.length > 0) {
      const yPosition = points[points.length - currentLevel].y - 100;
      scrollViewRef.current.scrollTo({ y: yPosition, animated: true });
    }
  }, [currentLevel, points]);

  useEffect(() => {
    if(points.length > 0 && !loadingNextPage) {
        setLoading(false)
    } else {
      setLoading(true)
    }
  }, [points, loadingNextPage])

  const graphData = [makeGraph(originalData), makeGraph(originalData)];

  const path = useMemo(() => {
    const start = graphData[state.current].curve;
    const end = graphData[state.next].curve;

    const result = start.interpolate(end, transition);

    return result?.toSVGString() ?? "0";
  }, [state, transition, graphData]);


    const panda = () => {
      const data = points[points.length - currentLevel];
    
      if (!loading && data?.x && data?.y) {
        const halfScreenWidth = screenWidth / 2;
        const isInLeftHalf = data.x < halfScreenWidth;
        const xPosition = isInLeftHalf ? data.x + 50 : data.x - 120;
    
        return (<Fragment>
            <Image
              source={bigEllipse}
              style={{
                position: 'absolute',
                width: 50,
                height: 40,
                resizeMode: 'contain',
                left: xPosition + 7,
                top: data.y - 20
              }}
            />
            <Image
              source={isInLeftHalf ? panda8left : panda8}
              style={{
                position: 'absolute',
                width: 100,
                height: 100,
                resizeMode: 'contain',
                left: isInLeftHalf ? xPosition - 35 : xPosition,
                top: data.y - 90
              }}
            />
        </Fragment>);
      }
      return null;
  };

  const scrollToLevel = useCallback(() => {
    if (scrollViewRef.current && points.length > 0) {
      const yPosition = points[points.length - currentLevel]?.y - 100;
      if (yPosition) {
        scrollViewRef.current.scrollTo({ y: yPosition, animated: true });
      }
    }
  }, [currentLevel, points]);
  
  useEffect(() => {
    if (points.length > 0) {
      setLoading(false);
      setTimeout(() => {
        scrollToLevel();
      }, 0);
    } else {
      setLoading(true);
    }
  }, [points, scrollToLevel]);
  
  const onClickLevel = async () => {
    try {
      setLoadingNextPage(true);
      setLoadingText(t("Wait_for_your_tutorials"))
      const {data} = await post(globalConstants.chatGPT.userLevelTutorials, {
        user_course_id: currentSubUserCourse.id,
      });
      setCurrentTutorials({ ...data, user_course_id: currentSubUserCourse.id})
      if(currentSubUserCourse.reached_page === 'tutorials') {
        navigation.navigate('UserTutorialsScreen', {userCourse: currentSubUserCourse});
      } else if(currentSubUserCourse.reached_page === 'questions') {
        const response = (await post(globalConstants.chatGPT.userTutorialQuestions, {
          user_level_tutorial_id: data.tutorials[currentSubUserCourse.current_tutorial - 1].id
        }))?.data;
  
        navigation.navigate('ExamScreen', {...response, user_course_id: currentSubUserCourse.id})
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoadingNextPage(false);
    }
  };
  
  const images = points.length === 0 ? null : points.map((data, index) => {
    if (!loading && data?.x && data?.y) {
      const indexValue = `${points.length - index}`;

      const isFinished = +indexValue < currentLevel;

      return (
        <Fragment key={index}>
          {indexValue == currentLevel ? <TouchableOpacity onPress={onClickLevel} style={{
            position: 'absolute',
            left: data.x - 50,
            top: data.y - 55,
          }}>
            <Image
              source={isFinished ? endLevel : activeLevel}
              style={{
                width: 100,
                height: 100,
                resizeMode: 'contain',
              }}
            />
            </TouchableOpacity>: <Image
              source={isFinished ? endLevel : activeLevel}
              style={{
                width: 100,
                height: 100,
                resizeMode: 'contain',
                position: 'absolute',
                left: data.x - 50,
                top: data.y - 55,
              }}
            />}
          
          {!isFinished&&<Image
            source={stars}
            style={{
              position: 'absolute',
              width: 100,
              height: 100,
              resizeMode: 'contain',
              left: data.x - 50,
              top: data.y - 95
            }}
          />}
  
          {/* {!isFinished&&<Text
            style={{
              position: 'absolute',
              left: data.x - (8 * indexValue.length),
              top: data.y - 28,
              color: '#fff',
              fontSize: 28,
            }}
          >{indexValue}</Text>} */}

          {indexValue == currentLevel ? !isFinished&&<TouchableOpacity onPress={onClickLevel} style={{
            position: 'absolute',
            left: data.x - (8 * indexValue.length),
            top: data.y - 28,
          }}>
            <Text
              style={{
                color: '#fff',
                fontSize: 28,
              }}
            >{indexValue}</Text>
            </TouchableOpacity>: !isFinished&&<Text
              style={{
                position: 'absolute',
                left: data.x - (8 * indexValue.length),
                top: data.y - 28,
                color: '#fff',
                fontSize: 28,
              }}
            >{indexValue}</Text>}
        </Fragment>
      )  
    }
    return null;
  });
  
  const ellipseImages = ellipsePositions.map((data, index) => {
    if (!loading && data?.width && data?.height) {
      const imageGift = data?.gift && data.relatedLevel >= currentLevel ? (<Image
        source={data.gift == 'yellow' ? yellowGift : data.gift == 'red' ? redGift : orangeGift}
        style={{
          position: 'absolute',
          width: data.widthGift,
          height: data.heightGift,
          resizeMode: 'contain',
          left: data.isSmall ? data.x + 15.5 : data.x + 32,
          top: data.isSmall ? data.y - 32 : data.y - 30,
        }}
      />) : null;

      return (
        <Fragment key={index}>
          <Image
            source={bigEllipse}
            style={{
              position: 'absolute',
              width: data.width,
              height: data.height,
              resizeMode: 'contain',
              left: data.x,
              top: data.y,
            }}
          />
          {imageGift}
        </Fragment>
      );
    }
    return null;
  });

  const triangleWidthImages = triangleWidthPositions.map((data, index) => {
    if (!loading && data?.width && data?.height) {
      return (
        <Fragment key={index}>
          <Image
            source={data.isDark ? widthTriangleDark : widthTriangle}
            style={{
              position: 'absolute',
              width: data.width,
              height: data.height,
              resizeMode: 'contain',
              left: data.x,
              top: data.y
            }}
          />
        </Fragment>
      );
    }
    return null;
  });

  const triangleHeightImages = triangleHeightPositions.map((data, index) => {
    if (!loading && data?.width && data?.height) {
      return (
        <Fragment key={index}>
          <Image
            source={heightTriangle}
            style={{
              position: 'absolute',
              width: data.width,
              height: data.height,
              resizeMode: 'contain',
              left: data.x,
              top: data.y,
            }}
          />
        </Fragment>
      );
    }
    return null;
  });

  if(loading || loadingNextPage) {
    return(
      <CustomLoader text={loadingText} />
    )
  }

  return (
    <View style={styles.container}>
      <ScrollView ref={scrollViewRef} scrollEnabled={true}>
      <LinearGradient
        colors={['#3796FF', '#6FB2FC']}
        style={styles.container}
        start={{ x: 0, y: 1 }}
        end={{ x: 0, y: 0 }}
      >
          <Canvas
            style={{
              width: GRAPH_WIDTH,
              height: GRAPH_HEIGHT,
            }}
          >
            <Path style="stroke" path={path} strokeWidth={84} color="#D9D9D9" >
              <Shadow dx={2} dy={1} blur={20} color="#4b6b8d" />
            </Path>
          </Canvas>
          {!loading ? images: null}
          {ellipseImages}
          {triangleWidthImages}
          {triangleHeightImages}
          {panda()}
      </LinearGradient>
        </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    backgroundColor: "white",
    flex: 1,
  },
});

export default SkiaHomeScreen;