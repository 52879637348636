import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React, { useCallback, useState, useEffect } from 'react';
import SetCourseScreen from '../screens/setCourse/setCourseScreen';
import CheckUserExperienceScreen from '../screens/setCourse/CheckUserExperienceScreen';
import EnrollCourseScreen from '../screens/setCourse/EnrollCourseScreen';
import QuickExamScreen from '../screens/setCourse/QuickExamScreen';
import StartCodingIntroductionScreen from '../screens/setCourse/StartCodingIntroductionScreen';
import CodingIntroductionScreen from '../screens/setCourse/CodingIntroductionScreen';
import { Colors } from '../constants/Colors';
import { StatusBar, Text, TouchableOpacity, View } from 'react-native';
import FirstGameScreen from '../screens/setCourse/FirstGameScreen';
import { useAuth } from '../contexts/AuthContext';
import UserTutorialsScreen from '../screens/setCourse/UserTutorialsScreen';
import ExamScreen from '../screens/setCourse/ExamScreen';
import QuestionsResultsScreen from '../screens/setCourse/QuestionsResultsScreen';
import CustomLoader from '../components/CustomLoader';
import { useTranslation } from 'react-i18next';
import AlgorithmsScreen from '../screens/setCourse/AlogirthmsScreen';

const SetCourseStack = ({ route }) => {
    const { t } = useTranslation();
    const { checkRoute } = route.params;
    const { subUser, havingExperience } = useAuth();
    const [initialRouteName, setInitialRouteName] = useState('CheckUserExperienceScreen');
    const [isLoading, setIsLoading] = useState(checkRoute ? checkRoute : true);
    const Stack = createNativeStackNavigator();

    useEffect(() => {
        const setRoot = () => {
            if (subUser) {
                if (havingExperience === null) {
                    setInitialRouteName('CheckUserExperienceScreen');
                } else if (havingExperience === false && subUser.last_visit == 'introduction_tutorials') {
                    setInitialRouteName('AlgorithmsScreen');
                } else {
                    setInitialRouteName('SetCourseScreen');
                }
            }
            setIsLoading(false);
        };
        setRoot();
    }, []);

    if (isLoading) {
        return (
            <CustomLoader text={t('Loading')} />
        )
    }

    return (
        <>
            <StatusBar barStyle="default" backgroundColor={Colors.primary2} />

            <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName={initialRouteName}>
                <Stack.Screen name="CheckUserExperienceScreen" component={CheckUserExperienceScreen} />
                <Stack.Screen name="SetCourseScreen" component={SetCourseScreen} />
                <Stack.Screen name="EnrollCourseScreen" component={EnrollCourseScreen} />
                <Stack.Screen name="QuickExamScreen" component={QuickExamScreen} />
                <Stack.Screen name="StartCodingIntroductionScreen" component={StartCodingIntroductionScreen} />
                <Stack.Screen name="AlgorithmsScreen" component={AlgorithmsScreen} />
                <Stack.Screen name="CodingIntroductionScreen" component={CodingIntroductionScreen} />
                <Stack.Screen name="FirstGameScreen" component={FirstGameScreen} />
                <Stack.Screen name="UserTutorialsScreen" component={UserTutorialsScreen} />
                <Stack.Screen name="ExamScreen" component={ExamScreen} />
                <Stack.Screen name="QuestionsResultsScreen" component={QuestionsResultsScreen} />
            </Stack.Navigator>
        </>
    );
};

export default SetCourseStack;
