import React from 'react';
import { View, StyleSheet, ActivityIndicator, Image, Text } from 'react-native';
import { screenWidth, Colors } from '../constants/Colors';
import logo from '../assets/logo.jpg'

const CustomLoader = ({ text = '' }) => {

    return (
        <View style={styles.container}>
            <Image source={logo} style={styles.logo} />
            <Text style={styles.text}>{text ? text : 'Loading...'}</Text>
            <ActivityIndicator
                style={styles.loader}
                size='large'
                color={Colors.btnColor}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.ellipseColor,
        justifyContent: 'center',
        alignItems: 'center',
    },
    loader: {
        height: 150
    },
    logo: {
        width: 200,
        height: 200,
        borderRadius: 20,
    },
    text: {
        padding: 10,
        fontSize: 20,
        color: Colors.black,
        marginTop: 25,
    }
});

export default CustomLoader;
